/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './sbgbackup.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'sbgbackup',
  companyId: 'sbgbackup',
  companyName: 'sbgbackup',
  rootDomain: 'sbganalytics.com',
  parentAgency: 'none',
  domains: {
    production: 'https://backup.sbganalytics.com',
    staging: 'https://backup.staging.sbganalytics.com',
    dev: 'https://sbgbackup.dev.sbganalytics.com',
    local: 'https://sbgbackup.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'sbgbackup', emailLogo: '' },
  config: {
    chartColors: ['#2b94fb', '#3f51b5', '#fb8c00', '#ec407a', '#29b6f6', '#ffc107'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
