import commonPkg from '@point/classes';
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import cachios from 'cachios';
import hash from 'object-hash';
import crypto from 'crypto';
import _clone from 'lodash.clonedeep';

const axiosInstance = axios.create({});
const traceIntercepts = false;
const traceCacheHits = false;

const apiUser = 'CONFERENCE';
const apiKey = 'D7CFF1AC42834DDE8E2A71D4445DA7BD'; // prettier-ignore

let useMemoryCache = false;

// caching on the client:
if (typeof window !== 'undefined') {
  useMemoryCache = false;
}

axiosInstance.interceptors.request.use(
  config => {
    if (traceIntercepts) {
      // eslint-disable-next-line no-console
      console.log('\n\nAxios Intercept: new request', config.method, config.url);
    }

    return config;
  },
  error => {
    if (traceIntercepts) {
      // eslint-disable-next-line no-console
      console.log('\n\nAxios Intercept: catch request error', error);
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (response: AxiosResponse<any>) => {
    if (!response || !response.status) {
      throw new Error('no response');
    }
    if (response.status === 417 && response.data === 'api offline') {
      throw new Error('api offline');
    }
    if (traceIntercepts) {
      // eslint-disable-next-line no-console
      console.log('\n\nAxios Intercept: new response', response.statusText, response.config.url);
    }
    return response;
  },
  (error: AxiosError) => {
    if (traceIntercepts) {
      // eslint-disable-next-line no-console
      console.log('\n\nAxios Intercept: catch response error', error.message, error.config.url);
    }
    return Promise.reject(error);
  },
);

let wrapped: AxiosInstance | null = null;
const memCache = {};
const memKeys = {};

if (useMemoryCache) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cachiosInstance = cachios.create(axiosInstance as any);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cachiosInstance.getCacheIdentifier = (config: any): any => {
    // to make cache keys work accross backends, remove the server/proxy
    const { url } = config;

    const identifier = {
      method: config.method,
      url,
      params: config.params,
      data: config.data,
    };
    const key = hash(identifier);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cached: any = memKeys[key];
    if (!cached) {
      memKeys[key] = identifier;
    }
    // console.log('getCacheIdentifier', key, config.url);
    return identifier;
  };

  cachiosInstance.cache = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get: (key: string): any => {
      if (traceCacheHits) {
        // eslint-disable-next-line no-console
        console.log('\nfilecache.get', key);
      }
      const val = memCache[key];
      if (!val) {
        if (traceCacheHits) {
          // eslint-disable-next-line no-console
          console.log('no cache', key);
        }
      }
      if (val && val.cached) {
        if (val.hits) {
          val.hits += 1;
        }
        return _clone(val.cached);
      }
      return val;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set: (key: string, val: any, ttl?: number): void => {
      if (traceCacheHits) {
        // eslint-disable-next-line no-console
        console.log('\nfilecache.set', key);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cached: any = memCache[key];
      if (!cached) {
        if (val && val.data && val.data.RealStatusCode && val.data.RealStatusCode !== 200) {
          // for now, do not cache to disk error responses
          return;
        }
        memCache[key] = { cached: val, ttl, identifier: memKeys[key], hits: 1 };
      }
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapped = cachiosInstance as any;
} else {
  wrapped = axiosInstance;
  if (traceCacheHits) {
    // eslint-disable-next-line no-console
    console.log('not using cache');
  }
}

const Hash = (key: string, str: string, extra: string): string => {
  let max = decodeURI(str);
  let check = 100;
  while (str !== max && check > 0) {
    check -= 1;
    str = max;
    max = decodeURI(str.replace('+', '%2B'));
  }
  let toSign = key + str; // .toLowerCase();
  if (extra) toSign += extra;
  const md5 = crypto.createHash('md5');
  const hashed = md5.update(Buffer.from(toSign, 'ascii')).digest('hex');
  return hashed.toUpperCase();
};

const SignURL = (urlToSign: string, content: string = null): string => {
  const toISO = (D: Date): string => {
    const A = [
      D.getUTCFullYear(),
      D.getUTCMonth() + 1,
      D.getUTCDate(),
      D.getUTCHours(),
      D.getUTCMinutes(),
      D.getUTCSeconds(),
    ];
    const B = [];
    let i = 0;
    while (i < 6) {
      B[i] = `${A[i] < 10 ? '0' : ''}${A[i]}`;
      i += 1;
    }
    return `${B.splice(0, 3).join('-')} ${B.join(':')}Z`;
  };
  let ret = urlToSign;
  if (ret.indexOf('?') === -1) {
    ret += '?';
  } else {
    ret += '&';
  }
  ret += 'date=';
  ret += toISO(new Date());
  const signed = Hash(apiKey, ret, content);
  ret += '&user=';
  ret += encodeURIComponent(apiUser);
  ret += '&sign=';
  ret += signed;
  // console.log('\n\nsigning', urlToSign, content, apiUser, apiKey, 'into', ret + '\n\n');
  return ret;
};

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, no-unused-vars
  init: (config?: any): AxiosInstance | null => {
    commonPkg.init();
    if (!commonPkg.config) {
      // eslint-disable-next-line no-console
      console.log('Could not load env file');
    } else if (commonPkg.config && commonPkg.config.error) {
      // eslint-disable-next-line no-console
      console.log('Could not load env file', commonPkg.config.error);
    }
    return wrapped;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCacheEntries: (): any => memCache,
  SignURL,
  apiKey,
  apiUser,
};
