/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './marketing360.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'marketing360',
  companyId: 'marketing360',
  companyName: 'Marketing 360',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://marketing360.myanalyticshub.com',
    staging: 'https://marketing360.staging.myanalyticshub.com',
    dev: 'https://marketing360.dev.myanalyticshub.com',
    local: 'https://marketing360.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'marketing360_white.png', emailLogo: 'marketing360_color.png' },
  config: {
    chartColors: ['#006dd0', '#ffbe10', '#98bcdc', '#1bc962', '#5c6163', '#ff6f3d'],

    maps: {
      themes: ['marketing360'],
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
