export default {
  FAILED_AUTH: 'Failed to login. If this is a valid account, please confirm your password.',
  FAILED_LOGIN: 'Your login attempt wasn&apos;t successful. Please check your credentials and try again.',
  FAILED_AH_AUTH: 'Failed to login. Please try again.',
  NO_RESPONSE: 'No response.',
  BAD_RESPONSE: 'Unexpected response.',
  MISSING_LAYOUTS: 'Missing layouts for this user',
  NO_POLYGONS: 'No polygons available to show.',
  NO_POLYGON_RESPONSE: 'No polygon response',
  NOT_AUTHORIZED: 'Sorry, you do not have access.',
};
