/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './ar.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig, getDMAGradient } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'ar',
  companyId: 'A-R_Marketing',
  companyName: 'AR Marketing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://a-rmarketing.myanalyticshub.com',
    staging: 'https://staging.a-rmarketing.myanalyticshub.com',
    dev: 'https://dev.a-rmarketing.myanalyticshub.com',
    local: 'https://ar.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'armarketing_white.png', large: true, emailLogo: 'armarketing_color.png' },
  config: {
    chartBorder: 'line',
    chartColors: ['#05527d', '#6597b3', '#becfda', '#f7c27c', '#f78507', '#ad381b'],

    maps: {
      themes: ['arMarketing'],
    },
  },
  polygons: {
    light: {
      dmaZip: { fillOpacity: 0.4, color: '#999999', fillColor: '#05527D', weight: 1 },
      zip: { fillOpacity: 0.6, color: '#999999', fillColor: '#05527D', weight: 0.5 },
      dmaGradient: getDMAGradient('#05527D'),
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
