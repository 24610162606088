/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './digitallymarket.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';

const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'digitallymarket',
  companyId: 'Digitally_Market_Plus',
  companyName: 'Digitally Market +',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://digitallymarket.myanalyticshub.com',
    staging: 'https://digitallymarket.staging.myanalyticshub.com',
    dev: 'https://digitallymarket.dev.myanalyticshub.com',
    local: 'https://digitallymarket.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'digitallymarket.png', emailLogo: 'digitallymarket_black.png' },
  config: {
    chartColors: ['#41ead4', '#473198', '#fb8b24', '#f71735', '#29b6f6', '#f0f600'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
