/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './nenidigital.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'nenidigital',
  companyId: 'NENI_Digital',
  companyName: 'NENI Digital',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://nenidigital.myanalyticshub.com',
    staging: 'https://nenidigital.staging.myanalyticshub.com',
    dev: 'https://nenidigital.dev.myanalyticshub.com',
    local: 'https://nenidigital.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'nenidigital.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#f99d3e', '#3f51b5', '#76ccff', '#2a9d8f', '#96999a', '#525252'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
