import { logError } from '../../index';
import CONSTANTS from './constants';
import { EditComponentProps } from './types';
import { Customization } from '../../../types/layout';

export default {
  editComponent({ commit }, payload: EditComponentProps): void {
    try {
      commit(CONSTANTS.SET_COMPONENT, payload.componentId);
      commit(CONSTANTS.SET_SECTION, payload.sectionId);
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.layoutEditor.editComponent');
    }
  },
  setCurrCustomizations({ commit }, customizations: Array<Customization>): void {
    try {
      commit(CONSTANTS.SET_CURR_CUSTOMIZATIONS, customizations);
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.layoutEditor.setCurrCustomizations');
    }
  },
  closeEditComponent({ commit }): void {
    commit(CONSTANTS.SET_COMPONENT, null);
    commit(CONSTANTS.SET_SECTION, null);
  },
  setEnableSave({ commit }, enableSave: boolean): void {
    commit(CONSTANTS.ENABLE_SAVE, enableSave);
  },
  toggleEditMode({ commit }, editMode: boolean): void {
    commit(CONSTANTS.EDIT_MODE, editMode);
    if (!editMode) {
      commit(CONSTANTS.ENABLE_SAVE, false);
    }
  },
  save(/* { commit } */): void {
    // console.log('save');
  },
};
