/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './sasshoes.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'sasshoes',
  companyId: 'San_Antonio_Shoes',
  companyName: 'San Antonio Shoemakers',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://sasshoes.myanalyticshub.com',
    staging: 'https://sasshoes.staging.myanalyticshub.com',
    dev: 'https://sasshoes.dev.myanalyticshub.com',
    local: 'https://sasshoes.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'sasshoes_white.png', emailLogo: 'sasshoes_color.png' },
  config: {
    chartColors: ['#003057', '#157d7f', '#94d2bd', '#ee9b00', '#ff5a0c', '#9b2226'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
