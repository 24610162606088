import shared from './requests/shared';
import facebook from './requests/facebook';
import google from './requests/google';
import siteimapct from './requests/siteimpact';

export default {
  ...shared,
  ...facebook,
  ...google,
  ...siteimapct,
};
