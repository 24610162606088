/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './minedice.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'minedice',
  companyId: 'minedice',
  companyName: 'Mined Ice',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://minedice.myanalyticshub.com',
    staging: 'https://minedice.staging.myanalyticshub.com',
    dev: 'https://minedice.dev.myanalyticshub.com',
    local: 'https://minedice.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'minedice_white.png', emailLogo: 'minedice_color.png' },
  config: {
    chartColors: ['#f14048', '#902e2e', '#80b8b8', '#ec407a', '#0285aa', '#939393'],
    pieCharts: { firstItem: 'legend' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
