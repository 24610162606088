import Vue from 'vue';
import { VNode } from 'vue/types/umd';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import mixpanel from './plugins/mixpanel';
import apollo from './plugins/apollo';
import router from './router';
import store from './store/index';
import { setup } from './helpers/interceptors';
import './registerServiceWorker';
import C360, { getTheme } from '@c360/ui';
import utils from './util';
import mixpanelMeasurePerformance from './mixpanelMeasurePerformance';

const agencyProvider = () => {
  const search = new URLSearchParams(window.location.search);
  const domainOverride = search.get('domainOverride');
  if (domainOverride) {
    return utils.getAgencyFromURL(domainOverride);
  }
  return utils.getAgencyFromURL();
};

export const eventBus = new Vue(); // cross component events
export const session_id = `${Math.random().toString(36).slice(2, 12)}`;
export const apolloClient = apollo(`${window.location.origin}/graph`, store);

Vue.config.productionTip = false;
Vue.use(mixpanel);

Vue.prototype.$apollo = apolloClient;

setup();
mixpanelMeasurePerformance();

getTheme({ agencyProvider }).then(theme => {
  Vue.use(C360, {
    product: 'Dashboard',
    theme,
  });

  new Vue({
    router,
    store,
    vuetify,
    render: (h): VNode => h(App),
  }).$mount('#app');
});
