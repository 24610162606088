import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './lpp.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'lpp',
  companyId: 'LPP',
  companyName: 'Local Page Pop',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://lpp.myanalyticshub.com',
    staging: 'https://staging.lpp.myanalyticshub.com',
    dev: 'https://dev.lpp.myanalyticshub.com',
    local: 'https://lpp.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'lpp_white.png', large: true, emailLogo: 'lpp_color.png' },
  config: {
    chartColors: ['#f58220', '#fabf7f', '#5c96ff', '#484a60', '#56a96f', '#969696'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
