/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './cliquestudios.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'cliquestudios',
  companyId: ' Clique_Studios',
  companyName: ' Clique Studios',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://cliquestudios.myanalyticshub.com',
    staging: 'https://cliquestudios.staging.myanalyticshub.com',
    dev: 'https://cliquestudios.dev.myanalyticshub.com',
    local: 'https://cliquestudios.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'cliquestudios.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#3340ee', '#202478', '#f08f35', '#9a69d0', '#73e0e0', '#ffc107'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
