import config from './config';
import root from './rootFolder';
import logging from './logging';
import businessHelpers from './businessHelpers';
import currency from './currency';
import dataTransforms from './dataTransforms';
import datesHelpers from './datesHelpers';
import images from './images';
import mappings from './mappings';
import regex from './regex';
import rep from './rep';
import shareHelpers from './shareHelpers';
import taxonomyHelpers from './taxonomyHelpers';
import roles from './roles';
import agencies from './agencies';
import tooltips from './tooltips';

const SharedModule: any = {
  name: 'Point Shared Module',
  version: '0.1',
  agencies,
  rootFolder: root,
  logging,
  businessHelpers,
  currency,
  dataTransforms,
  datesHelpers,
  images,
  mappings,
  regex,
  rep,
  roles,
  shareHelpers,
  taxonomyHelpers,
  tooltips,
  init: function (productName) {
    if (this.config) {
      return; // for now, assume that the first caller into init() wins
    }
    // we may not want to send ALL the config down to the client through this lib
    this.config = config.init(root || './', productName);
  },
};

export default SharedModule;
export type { AuthUser } from './roles';
