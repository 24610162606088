import Vue from 'vue';
import CONSTANTS from './constants';
// import { StateType } from './types';

export default {
  [CONSTANTS.SET_ORDERLIST_PARAMS](_state: any, orderListParams: object): void {
    Vue.set(_state, 'orderListParams', orderListParams);
  },
  [CONSTANTS.SET_SINGLE_ORDER_PARAMS](_state: any, orderParams: object): void {
    Vue.set(_state, 'singleOrderParams', orderParams);
  },
  [CONSTANTS.SET_HIDING_ORDER_MODULES](_state: any, hidingOrderModulesWhenNoImps: object): void {
    Vue.set(_state, 'hidingOrderModulesWhenNoImps', hidingOrderModulesWhenNoImps);
  },
};
