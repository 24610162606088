import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  [CONSTANTS.SET_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'loading', loading);
  },
  [CONSTANTS.SET_CREDENTIALS_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'credentialError', error);
  },
  [CONSTANTS.SET_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'error', error);
  },
};
