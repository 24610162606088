/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './digthrive.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'digthrive',
  companyId: 'Digital_Thrive',
  companyName: 'Digital Thrive',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://digthrive.myanalyticshub.com',
    staging: 'https://digthrive.staging.myanalyticshub.com',
    dev: 'https://digthrive.dev.myanalyticshub.com',
    local: 'https://digthrive.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'digthrive.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#00a1b4', '#ff6161', '#fbdb00', '#a0c7f2', '#979a9b', '#4a4a4a'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
