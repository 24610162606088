import { StateType } from './types';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const debug: boolean = process.env.NODE_ENV !== 'production';

const state: StateType = {
  user: null,
  error: null,
  networkActivityCounter: 0,
  fetchingComponents: new Set(),
  fetching: [],
  clickedTacticFromSidebar: null,
  currentNavTab: null,
  currentSection: null,
  waitingForAuth: true,
  defaultPeriod: 'last30days', // this is a fallback in case we don't get a period from the API, but we shouldn't use it
  sharedSelection: null,
  sharedDateRanges: null,
  selection: {
    SFLT: [],
    advertiserName: null,
    advertiserId: null,
    daterange: null,
    campaigndaterange: null,
    creative: [],
    startdate: null,
    enddate: null,
    campaignstartdate: null,
    campaignenddate: null,
    layout: null,
    tab: null,
    campaigns: null,
    dmas: null,
    types: null,
    viewCampaigns: null,
  },
  // filters: null,
  loadingLayouts: false,
  loadingHasRight: false,
  loadingAllAdvertisers: false,
  loadingSummaryPerformance: false,
  loadingTacticSummaryPerformance: false,
  loadingAdPerformance: false,
  loadingProducts: false,
  loadingCampaignAdPerformance: false,
  loadingAllTimeAdPerformance: false,
  exportingData: null,
  loadingExportContent: false,
  loadingShareContent: false,
  // context of current dashboard
  currentDashboard: {
    products: null, // list of available tactics/products
    validatedProductSections: null,
  },
  sharedDashboard: false,
  summaryPerformance: {
    loaded: false,
  },
  tacticSummaryPerformance: {
    loaded: false,
  },
  adPerformance: {
    loaded: false,
  },
  allTimeAdPerformance: {
    loaded: false,
  },
  campaignAdPerformance: {
    loaded: false,
  },
  allTimeCampaigns: {
    loaded: false,
  },
  loadingAdvertiserPolygons: false,
  loadingAdvertiserGeocodes: false,
  advertiserPolygons: {
    loaded: false,
  },
  loadingDMAPolygons: false,
  dmaPolygons: {
    loaded: false,
  },
  zipPolygons: {
    loaded: false,
  },
  dateRanges: {
    loaded: false,
    list: [],
  },
  advertisers: {
    loaded: false,
    list: [],
  },
  allAdvertisers: {
    loaded: false,
    list: [],
  },
  loadingZipPolygons: false,
  adBlockDetected: false,
  debugFlags: {},
  layouts: [],
  dynamicLayout: null,
  dynamicLayoutSource: null,
  defaultLayout: null,
  dashboard: null,
  theme: null,
  campaigns: null,
  editTheme: null,
  staticLastModifiedDate: null,
  exportState: null,
  processing: [],
  orderListState: null,
  summaryDateRanges: null,
};

export default {
  strict: debug,
  mutations,
  actions,
  getters,
  state,
};
