import Vue from 'vue';
import mixpanel from 'mixpanel-browser';
import utils from '../util';

// Mixpanel production key and dev/staging key
const MIXPANEL_ID = utils.isProduction() ? '17eb7d2d6cd0983e859174d3d3770156' : 'b7bda5ebdde76e59c9fd2b8bcc4e58ee';

mixpanel.init(MIXPANEL_ID, {
  // debug: true,
});

Vue.prototype.$mixpanel = mixpanel;

export default mixpanel;
