import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { logError, apollo } from '../../..';
import CONSTANTS from '../constants';
import ERRORS from '../../../../errors';
import {
  CampaignNoteParams,
  GetCampaignNotesResponse,
  CampaignNoteUpdateParams,
  DeleteCampaignNoteResponse,
} from '../../../../../../../apis/dashboardGraphqlApi/dataSources/dataAPI/types/campaigns';

const getCampaignNotes = async ({ commit }, params: CampaignNoteParams): Promise<GetCampaignNotesResponse> => {
  try {
    const response = await apollo().query({
      query: gql`
        query GetCampaignNotes($params: CampaignNoteParams) {
          getCampaignNotes(params: $params) {
            advertiserId
            campaignId
            notes {
              body
              createdAt
              createdBy
              lastModifiedAt
              lastModifiedBy
              noteId
              title
            }
            total
            offset
            limit
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data?.getCampaignNotes;
  } catch (error) {
    if (error?.toString()?.includes('403')) commit(CONSTANTS.SET_NOTE_ERROR, ERRORS.NOT_AUTHORIZED);
    else commit(CONSTANTS.SET_NOTE_ERROR, ERRORS.BAD_RESPONSE);
    logError('getCampaignNotes', error);
  }
};

const addCampaignNote = async ({ commit }, params: CampaignNoteParams): Promise<void> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: ApolloQueryResult<any> = await apollo().mutate({
      mutation: gql`
        mutation AddCampaignNote($params: CampaignNoteParams) {
          addCampaignNote(params: $params) {
            body
            createdAt
            createdBy
            lastModifiedAt
            lastModifiedBy
            title
            noteId
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data;
  } catch (error) {
    commit(CONSTANTS.SET_ERROR, error);
    logError('addCampaignNote', error);
  }
};

const updateCampaignNote = async ({ commit }, params: CampaignNoteUpdateParams): Promise<void> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: ApolloQueryResult<any> = await apollo().mutate({
      mutation: gql`
        mutation UpdateCampaignNote($params: CampaignNoteUpdateParams) {
          updateCampaignNote(params: $params) {
            body
            createdAt
            createdBy
            lastModifiedAt
            lastModifiedBy
            title
            noteId
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data;
  } catch (error) {
    commit(CONSTANTS.SET_ERROR, error);
    logError('addCampaignNote', error);
  }
};

const deleteCampaignNote = async (
  { commit },
  params: CampaignNoteUpdateParams,
): Promise<DeleteCampaignNoteResponse> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: ApolloQueryResult<any> = await apollo().mutate({
      mutation: gql`
        mutation DeleteCampaignNote($params: CampaignNoteUpdateParams) {
          deleteCampaignNote(params: $params) {
            noteId
            deletedAt
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data;
  } catch (error) {
    commit(CONSTANTS.SET_ERROR, error);
    logError('addCampaignNote', error);
  }
};

export default {
  getCampaignNotes,
  addCampaignNote,
  updateCampaignNote,
  deleteCampaignNote,
};
