/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './floyds_99_barbershop.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'floyds99barbershop',
  companyId: 'Floyds_99_Barbershop',
  companyName: 'Floyd’s 99 Barbershop',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://floyds99barbershop.myanalyticshub.com',
    staging: 'https://floyds99barbershop.staging.myanalyticshub.com',
    dev: 'https://floyds99barbershop.dev.myanalyticshub.com',
    local: 'https://floyds99barbershop.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'floyd_99_barbershop.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#8ee3ef', '#254e70', '#f58f29', '#f97068', '#04e762', '#eaf27c'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
