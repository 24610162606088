import Vue from 'vue';
import { GroupedTopTacticTable, TopTacticsWithMetrics } from '../../../pages/components/summary/types';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  [CONSTANTS.SET_TOP_TACTICS](state: StateType, tactics: TopTacticsWithMetrics): void {
    Vue.set(state, 'topTactics', tactics);
  },
  [CONSTANTS.SET_GROUPED_TOP_TACTICS](state: StateType, groupedTactics: GroupedTopTacticTable): void {
    Vue.set(state, 'groupedTopTactics', groupedTactics);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CONSTANTS.SET_SUMMARY_DATA](state: StateType, summaryData: any): void {
    Vue.set(state, 'summaryData', summaryData);
  },
  [CONSTANTS.SET_MAP_HEIGHT](state: StateType, height: string): void {
    Vue.set(state.dimensions, 'mapHeight', height);
  },
  [CONSTANTS.SET_SUMMARY_HEIGHT](state: StateType, height: string): void {
    Vue.set(state.dimensions, 'summaryHeight', height);
  },
  [CONSTANTS.SET_SUMMARY_TOP_POSITION](state: StateType, position: string): void {
    Vue.set(state.dimensions, 'summaryTopPosition', position);
  },
  [CONSTANTS.SET_IS_MAP_READY](state: StateType, isMapReady: boolean): void {
    Vue.set(state.map, 'ready', isMapReady);
  },
  [CONSTANTS.SET_MAP_HAS_DATA](state: StateType, mapHasData: boolean): void {
    Vue.set(state.map, 'hasData', mapHasData);
  },
};
