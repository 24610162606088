/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './lamarkmedia.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'lamarkmedia',
  companyId: 'Lamark_Media',
  companyName: 'Lamark Media',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://lamarkmedia.myanalyticshub.com',
    staging: 'https://lamarkmedia.staging.myanalyticshub.com',
    dev: 'https://lamarkmedia.dev.myanalyticshub.com',
    local: 'https://lamarkmedia.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'lamarkmedia_white.png', emailLogo: 'lamarkmedia_color.png' },
  config: {
    chartColors: ['#1b4fc8', '#ffc107', '#61ce70', '#999999', '#6ec1e4', '#121212'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
