/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './myanalyticshub.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'myanalyticshub',
  companyId: 'myanalyticshub',
  companyName: 'MyAnalyticsHub',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://myanalyticshub.com',
    staging: 'https://staging.myanalyticshub.com',
    dev: 'https://dev.myanalyticshub.com',
    local: 'https://myanalyticshub.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'myanalyticshub_white.png', emailLogo: 'myanalyticshub.png' },
  config: {
    chartColors: ['#2b94fb', '#3f51b5', '#fb8c00', '#ec407a', '#29b6f6', '#ffc107'],

    maps: {
      themes: ['myAnalyticsHub'],
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
