const fs = require('fs');

// deprecated
enum types { // eslint-disable-line no-unused-vars
  Info = 'INFO', // eslint-disable-line no-unused-vars
  Warning = 'WARNING', // eslint-disable-line no-unused-vars
  Error = 'ERROR', // eslint-disable-line no-unused-vars
}

// deprecated
const log = (level: types, src: string, message?: any) => {
  try {
    const timestamp = new Date();
    let details = '';
    if (message) {
      if (typeof message === 'string') {
        details = message;
      } else if (message instanceof Error) {
        if (message.message && typeof message.message === 'string') {
          details = message.message;
        } else {
          const obj = {};
          Object.getOwnPropertyNames(message).forEach((key: string) => {
            obj[key] = message[key];
          });
          details = JSON.stringify(obj);
        }
      } else if (message.Error && typeof message.Error === 'string') {
        details = message.Error;
      } else {
        details = JSON.stringify(message);
      }
      if (details.length > 300) {
        details = `${details.substr(0, 300)}...`;
      }
    }
    const msg = `\r\n[${(level || types.Error).toUpperCase()}] ${timestamp.toUTCString()} - ${src} ${details}`;

    fs.appendFile('log.txt', msg, (err: any) => {
      if (err) throw err;
    });
    if (level === types.Error) {
      // eslint-disable-next-line no-console
      console.error(msg);
    } else {
      // eslint-disable-next-line no-console
      console.log(msg);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('log error', err);
  }
};

export const formattedLog = ({
  level,
  message,
  context,
  payload,
}: {
  level: 'debug' | 'info' | 'warn' | 'error';
  message: string;
  context?: string;
  payload?: string | Record<string, any>;
}) => {
  const data = typeof payload === 'string' ?  { message: payload } : payload;
  console.log(
    JSON.stringify({
      date: new Date(),
      level: level,
      message: message,
      service: context,
      ...(data && {
        payload: data,
      }),
    }),
  );
};

// TODO: integrate it into bff
// process.on('uncaughtException', error => {
//   formattedLog({
//     level: 'error',
//     message: 'uncaughtException',
//     payload: error,
//   });
//   throw error;
// });

export class Logger {
  isLocal: boolean = false;
  context: string;

  constructor(context: string = 'global') {
    this.context = context;

    // place to add support of any other logger
    if (process.env.NODE_ENV === 'local') {
      this.isLocal = true;
    }
  }

  _log(level, message, payload) {
    if (this.isLocal) {
      // eslint-disable-next-line no-console
      console[level](message, ...(payload ? [payload] : [])); // Simplified local logging
    } else {
      formattedLog({
        level: level,
        message,
        context: this.context,
        payload,
      });
    }
  }

  log(message, payload = undefined) {
    this._log('info', message, payload);
  }

  info(message, payload = undefined) {
    this._log('info', message, payload);
  }

  warn(message, payload = undefined) {
    this._log('warn', message, payload);
  }

  error(message, payload = undefined) {
    this._log('error', message, payload);
  }

  debug(message, payload = undefined) {
    this._log('debug', message, payload);
  }
}

export const logger = new Logger('global');

export default {
  log,
  types,
  logger,
  Logger,
};
