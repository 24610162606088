/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './proventuresinc.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'proventuresinc',
  companyId: 'Proventures_Inc',
  companyName: 'Proventures Inc',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://proventuresinc.myanalyticshub.com',
    staging: 'https://proventuresinc.staging.myanalyticshub.com',
    dev: 'https://proventuresinc.dev.myanalyticshub.com',
    local: 'https://proventuresinc.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'proventuresinc.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#0a4499', '#689ff0', '#f08700', '#ffe51b', '#b0b0b0', '#616161'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
