/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './anewmedia.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'anewmedia',
  companyId: 'Anew_Media',
  companyName: 'Anew Media',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://anewmedia.myanalyticshub.com',
    staging: 'https://anewmedia.staging.myanalyticshub.com',
    dev: 'https://anewmedia.dev.myanalyticshub.com',
    local: 'https://anewmedia.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'anewmedia.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#004aad', '#9185f0', '#80b6ff', '#cacaca', '#8a8a8a', '#1c1c1c'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
