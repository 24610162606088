/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './solsource.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'solsource',
  companyId: 'SolSource_Marketing_Solutions',
  companyName: 'SolSource Marketing Solutions',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://solsource.myanalyticshub.com',
    staging: 'https://solsource.staging.myanalyticshub.com',
    dev: 'https://solsource.dev.myanalyticshub.com',
    local: 'https://solsource.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'solsource.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#098896', '#4e4187', '#b38a58', '#ec407a', '#7dde92', '#ffc107'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
