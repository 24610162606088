import gam from './actions/gam';
import googleAnalytics from './actions/googleAnalytics';
import shared from './actions/shared';
import facebook from './actions/facebook';
import googleAds from './actions/googleAds';
import googleSearch from './actions/googleSearch';
import siteimpact from './actions/siteimpact';

export default {
  ...gam,
  ...googleSearch,
  ...facebook,
  ...googleAnalytics,
  ...shared,
  ...googleAds,
  ...siteimpact,
};
