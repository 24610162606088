/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './eldoradobroadcasters.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'eldoradobroadcasters',
  companyId: 'El_Dorado_Broadcasters',
  companyName: 'El Dorado Broadcasters',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://eldoradobroadcasters.myanalyticshub.com',
    staging: 'https://eldoradobroadcasters.staging.myanalyticshub.com',
    dev: 'https://eldoradobroadcasters.dev.myanalyticshub.com',
    local: 'https://eldoradobroadcasters.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'edbroadcasters.png', emailLogo: '' },
  config: {
    chartColors: ['#50357b', '#a288cc', '#9db8f6', '#ff9140', '#f2e704', '#acd0af'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
