import _clone from 'lodash.clonedeep';
import { CampaignType, Campaign } from '../../../types/filters';
import CONSTANTS from './constants';
import { CurrentFilterSelection } from './types';

export default {
  resetFilters({ commit, dispatch }): void {
    commit(CONSTANTS.SET_FILTERS, null);
    dispatch('setCampaigns', null); // to customer store
  },
  setFilters({ commit }, payload): void {
    commit(CONSTANTS.SET_FILTERS, {
      CampTypes: payload.CampTypes,
      StateList: payload.StateList,
      StartDate: payload.StartDate,
      EndDate: payload.EndDate,
    });
  },
  resetCampaignFilters({ commit }): void {
    commit(CONSTANTS.SET_CAMPAIGNS_HASFILTERED, false);
    commit(CONSTANTS.SET_CAMPAIGNS_HASTYPEFILTERED, false);
    commit(CONSTANTS.SET_SELECTED_CAMPAIGNS, null);
  },
  readCampaigns({ commit }, payload): void {
    // working on replacing this filter object by querystring based attributes
    const filters: CurrentFilterSelection = {
      CampTypes: payload.CampTypes,
      StateList: payload.StateList,
      StartDate: payload.StartDate,
      EndDate: payload.EndDate,
    };

    commit(CONSTANTS.SET_FILTERS, filters);

    commit(CONSTANTS.SET_CAMPAIGNS_HASFILTERED, false);
    commit(CONSTANTS.SET_CAMPAIGNS_HASTYPEFILTERED, false);
    const allIds = payload.Campaign?.map((campaign: Campaign) => campaign.id);
    commit(CONSTANTS.SET_CAMPAIGNS_SELECTION, allIds);
    const allTypes = payload.Campaign?.map((campaign: Campaign) => campaign.CampaignType);
    commit(CONSTANTS.SET_CAMPAIGNTYPE_SELECTION, Array.from(new Set(allTypes)));
  },
  setSelectedCampaigns({ commit }, campaigns): void {
    commit(CONSTANTS.SET_SELECTED_CAMPAIGNS, campaigns);
  },
  reloadCampaigns({ commit }, reload: boolean): void {
    commit(CONSTANTS.RELOAD_CAMPAIGNS, reload);
  },
  setSelectedCampaignIDs({ commit }, list): void {
    commit(CONSTANTS.SET_CAMPAIGNS_SELECTION, list);
  },
  setCampaignFiltered({ commit }, status: boolean): void {
    commit(CONSTANTS.SET_CAMPAIGNS_HASFILTERED, status);
  },
  setSelectedCampaignTypes({ commit }, list): void {
    const unique = Array.from(new Set(list));
    commit(CONSTANTS.SET_CAMPAIGNTYPE_SELECTION, unique);
  },
  setCampaignTypesFiltered({ commit }, status: boolean): void {
    commit(CONSTANTS.SET_CAMPAIGNS_HASTYPEFILTERED, status);
  },
  setSelectedCampaignTypesAndIDs({ commit }, payload): void {
    commit(CONSTANTS.SET_CAMPAIGNS_HASFILTERED, payload.hasCampaignFiltered);
    commit(CONSTANTS.SET_CAMPAIGNS_HASTYPEFILTERED, payload.hasTypeFiltered);
    commit(CONSTANTS.SET_CAMPAIGNS_SELECTION, payload.ids);
    const unique = Array.from(new Set(payload.types));
    commit(CONSTANTS.SET_CAMPAIGNTYPE_SELECTION, unique);
  },
  setTacticPageView({ commit }, view): void {
    commit(CONSTANTS.SET_TACTIC_PAGE__VIEW, view);
  },
  applyCampaignSelection({ commit, state, dispatch, rootState }): void {
    // read selected campaign types and IDs, update store campaign tree
    const campaigns = _clone(rootState.customer.campaigns);
    const selectedIDs = state.selectedCampaignIDs;
    const selectedTypes = state.selectedCampaignTypes;
    const hasCampaignFiltered = state.hasCampaignFiltered;
    const hasTypeFiltered = state.hasTypeFiltered;
    const hasTypeFilter = selectedTypes.length > 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const byType: any = {};
    if (campaigns && campaigns.campaignlist && Array.isArray(campaigns.campaignlist)) {
      campaigns.campaignlist.forEach((campaign: Campaign) => {
        campaign.checked = true;
        if (hasTypeFilter && hasTypeFiltered && !selectedTypes.includes(campaign.CampaignType)) {
          campaign.checked = false;
        }
        if (campaign.checked && hasCampaignFiltered && !selectedIDs.includes(campaign.id)) {
          campaign.checked = false;
        }
        if (campaign.checked) {
          byType[campaign.CampaignType] = true;
        }
      });
    }

    dispatch('updateCampaigns', campaigns); // to customer store module

    const filters = _clone(state.current);
    filters?.CampTypes?.forEach((ct: CampaignType) => {
      ct.checked = !!byType[ct.label];
    });
    commit(CONSTANTS.UPDATE_FILTERS, filters);
  },
};
