/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './consultv.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'consultv',
  companyId: 'ConsulTV',
  companyName: 'ConsulTV',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://consultv.myanalyticshub.com',
    staging: 'https://consultv.staging.myanalyticshub.com',
    dev: 'https://consultv.dev.myanalyticshub.com',
    local: 'https://consultv.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'consultv.png', large: true, emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#f58220', '#fabf7f', '#5c96ff', '#484a60', '#56a96f', '#969696'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
