import { logError } from '../../index';
import CONSTANTS from './constants';
import axios, { AxiosResponse, AxiosError } from 'axios';

export default {
  setGroupedTopTactics({ commit }, tactics): void {
    try {
      commit(CONSTANTS.SET_GROUPED_TOP_TACTICS, tactics);
    } catch (exp) {
      throw logError(exp, 'store.summary.setGroupedTopTactics');
    }
  },
  resetTopTactics({ commit }): void {
    commit(CONSTANTS.SET_TOP_TACTICS, null);
  },
  setTopTactics({ commit }, tactics): void {
    try {
      commit(CONSTANTS.SET_TOP_TACTICS, tactics);
    } catch (exp) {
      throw logError(exp, 'store.summary.setTopTactics');
    }
  },
  setSummaryData({ commit }, summaryData): void {
    try {
      // resetting these dimensions seems to help when toggling between different amounts of tactics in different time ranges
      commit(CONSTANTS.SET_MAP_HEIGHT, null);
      commit(CONSTANTS.SET_SUMMARY_HEIGHT, null);
      commit(CONSTANTS.SET_SUMMARY_TOP_POSITION, null);
      commit(CONSTANTS.SET_SUMMARY_DATA, summaryData);
    } catch (exp) {
      throw logError(exp, 'store.summary.setSummaryData');
    }
  },
  setSummaryHeight({ commit }, height): void {
    try {
      commit(CONSTANTS.SET_SUMMARY_HEIGHT, height);
    } catch (exp) {
      throw logError(exp, 'store.summary.setSummaryHeight');
    }
  },
  setMapHeight({ commit }, height): void {
    try {
      commit(CONSTANTS.SET_MAP_HEIGHT, height);
    } catch (exp) {
      throw logError(exp, 'store.summary.setMapHeight');
    }
  },
  setSummaryTopPosition({ commit }, top): void {
    try {
      commit(CONSTANTS.SET_SUMMARY_TOP_POSITION, top);
    } catch (exp) {
      throw logError(exp, 'store.summary.setSummaryTopPosition');
    }
  },
  setIsMapReady({ commit }, isMapReady): void {
    try {
      commit(CONSTANTS.SET_IS_MAP_READY, isMapReady);
    } catch (exp) {
      throw logError(exp, 'store.summary.setIsMapReady');
    }
  },
  setMapHasData({ commit }, hasData): void {
    try {
      commit(CONSTANTS.SET_MAP_HAS_DATA, hasData);
    } catch (exp) {
      throw logError(exp, 'store.summary.setMapHasData');
    }
  },
};
