/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './cbslocal.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'cbslocal',
  companyId: 'CBS',
  companyName: 'CBS Local',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://cbslocal.myanalyticshub.com',
    staging: 'https://cbslocal.staging.myanalyticshub.com',
    dev: 'https://cbslocal.dev.myanalyticshub.com',
    local: 'https://cbslocal.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'cbslocal_white.png', emailLogo: 'cbslocal.png' },
  config: {
    chartColors: ['#0084c9', '#fb8c00', '#26a46f', '#ffc107', '#e50909', '#0c4c6e'],
    pieCharts: { firstItem: 'legend' },

    maps: {
      themes: ['cbslocal'],
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
