/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './bulldogcreativeserves.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'bulldogcreativeserves',
  companyId: 'Bulldog_Creative_Services',
  companyName: 'Bulldog Creative Services',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://bulldogcreativeservices.myanalyticshub.com',
    staging: 'https://bulldogcreativeservices.staging.myanalyticshub.com',
    dev: 'https://bulldogcreativeservices.dev.myanalyticshub.com',
    local: 'https://bulldogcreativeservices.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'bulldogcreative.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#ae0b25', '#046293', '#ffa03f', '#ffdf7e', '#c5c5c5', '#484847'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
