import { StateType, AuthUser, Campaigns } from './types';

const currentTabCampaignTypes = (state: StateType): string[] => {
  // if summary tab, only show  OTT, PREROLL, DISPLAY.
  // if tab selected, only show that campaign type
  let validCampaignTypes: string[] = [];
  if (!state.currentNavTab) {
    return validCampaignTypes;
  }
  switch (state.currentNavTab) {
    case 'summary':
      validCampaignTypes = ['OTT', 'PREROLL', 'DISPLAY'];
      break;
    case 'preroll':
      validCampaignTypes = ['PREROLL'];
      break;
    case 'ott':
      validCampaignTypes = ['OTT'];
      break;
    case 'digitalvideo':
      validCampaignTypes = ['VIDEO'];
      break;
    case 'display':
      validCampaignTypes = ['DISPLAY'];
      break;
    case 'linear':
      validCampaignTypes = ['LINEAR'];
      break;
    case 'sem':
      validCampaignTypes = ['SEM'];
      break;
    case 'social':
      validCampaignTypes = ['SOCIAL'];
      break;
    case 'broadcast':
      validCampaignTypes = ['BROADCAST'];
      break;
    case 'simpgeofence':
      validCampaignTypes = ['SIMPGEOFENCE'];
      break;
    case 'simpott':
      validCampaignTypes = ['SIMPOTT'];
      break;
    case 'simppreroll':
      validCampaignTypes = ['SIMPPREROLL'];
      break;
    case 'simpdisplay':
      validCampaignTypes = ['SIMPDISPLAY'];
      break;
    case 'googlesearch':
      validCampaignTypes = ['GOOGLESEARCH'];
      break;
    case 'emailsi':
      validCampaignTypes = ['SITEIMPACT'];
      break;
    case 'gtdisplay':
      validCampaignTypes = ['GTDISPLAY'];
      break;
    case 'gtvideo':
      validCampaignTypes = ['GTVIDEO'];
      break;
    case 'ga':
      validCampaignTypes = ['GA'];
      break;
    case 'googlevideo':
      validCampaignTypes = ['GOOGLEVIDEO'];
      break;
    case 'calltracking':
      validCampaignTypes = ['CALLTRACKING'];
      break;
    case 'trugeofence':
      validCampaignTypes = ['TRUGEOFENCE'];
      break;
    case 'gamdisplay':
      validCampaignTypes = ['GAMDISPLAY'];
      break;
    case 'gamvideo':
      validCampaignTypes = ['GAMVIDEO'];
      break;
    case 'fbinsights':
      validCampaignTypes = ['FBINSIGHTS'];
      break;
    case 'audio':
      validCampaignTypes = ['AUDIO'];
      break;
    case 'tv2ott':
      validCampaignTypes = ['TV2OTT'];
      break;
    case 'video':
      validCampaignTypes = ['VIDEO'];
      break;
    case 'broadstreet':
      validCampaignTypes = ['BROADSTREET'];
      break;
    default:
      // eslint-disable-next-line no-console
      console.log('unhandled campaign type', state.currentNavTab);
      validCampaignTypes = [state.currentNavTab];
      break;
  }
  // make sure to always return as uppercase
  return validCampaignTypes.map(type => type.toUpperCase());
};

export default {
  user(state: StateType): AuthUser {
    const { user } = state;
    if (user && user.auth) {
      return user;
    }
    return { auth: false };
  },
  useDebugFileLayout(state: StateType): boolean {
    const { debugFlags } = state;
    return !!(debugFlags && debugFlags.fileLayout);
  },
  networkActivity(state: StateType): boolean {
    return state.networkActivityCounter > 0;
  },
  currentTabCampaignTypes,
  currentTabCampaignList(state: StateType): object[] {
    if (!Array.isArray(state.campaigns?.campaignlist)) {
      return [];
    }
    const types = currentTabCampaignTypes(state);
    return state.campaigns.campaignlist.filter(c => types.includes(c.CampaignType));
  },
  sharedSelection(state: StateType): object {
    return state.sharedSelection;
  },
  exportState(state: StateType): object | null {
    return state.exportState;
  },
  campaigns(state: StateType): Campaigns {
    return state.campaigns;
  },
  fetchingList(state: StateType): string[] {
    return state.fetching;
  },
};
