export default {
  compareState(compare) {
    // check if the passed in state is the same as what's current on this browser
    return compare.currentPath === window.location.hash.substr(1);
  },
  speed(connection) {
    if (connection <= 50) return { text: 'Great connection', color: 'green', fill: 'five' };
    if (connection <= 100) return { text: 'Good connection', color: 'light-green', fill: 'four' };
    if (connection <= 150) return { text: 'Fair connection', color: 'light-green', fill: 'three' };
    if (connection <= 200) return { text: 'Poor connection', color: 'yellow', fill: 'two' };
    if (connection > 300) return { text: 'Bad connection', color: 'red', fill: 'one' };
    return { text: 'No Connection', color: 'gray' };
  },
  getLatency(user, index, compare) {
    const inSync = this.compareState(compare) ? 'inSync' : 'not inSync';
    if (index - user.checkReceived > 10) {
      return { connection: { text: 'Lost Connection', color: 'red' }, inSync };
    }
    if (index - user.checkReceived > 4) {
      return { connection: { text: 'Loosing connection', color: 'yellow' }, inSync };
    }
    if (index - user.checkReceived > 1) {
      return { connection: { text: 'Waiting', color: 'gray' }, inSync };
    }
    return { connection: this.speed(user.latency), inSync };
  },
};
