/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './marquee.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'marquee',
  companyId: 'Marquee_Sports_Network',
  companyName: 'Marquee Sports Network',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://marqueesportsnetwork.myanalyticshub.com',
    staging: 'https://marqueesportsnetwork.staging.myanalyticshub.com',
    dev: 'https://marqueesportsnetwork.dev.myanalyticshub.com',
    local: 'https://marqueesportsnetwork.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'marquee_white.png', emailLogo: 'marquee_color.png' },
  config: {
    chartColors: ['#b1172d', '#171fae', '#5d9bd2', '#c5c5c5', '#5c627d', '#eda390'],
    pieCharts: { firstItem: 'legend' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
