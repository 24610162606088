/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './getthevantage.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'getthevantage',
  companyId: 'GetTheVantage',
  companyName: 'Vantage Consultants',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'Sinclair',
  domains: {
    production: 'https://getthevantage.myanalyticshub.com',
    staging: 'https://getthevantage.staging.myanalyticshub.com',
    dev: 'https://getthevantage.dev.myanalyticshub.com',
    local: 'https://getthevantage.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { invert: false, file: 'getthevantage.png', emailLogo: 'getthevantage_color.png' },
  config: {
    chartColors: ['#cc3ec9', '#662d91', '#9cf629', '#797c7d', '#d1d3d4', '#2fd9f9'],

    maps: {
      themes: ['getthevantage'],
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
