import mutations from './mutations';
import actions from './actions';
import { StateType } from './types';

const state: StateType = {
  advertiserInfo: null,
  contacts: null,

  advertiserInfoError: null,
  contactsError: null,
  updateAdvertiserError: null,
  updateContactsError: null,

  advertiserInfoCache: null,
  isAdvertiserInfoCached: false,

  isAdvertiserInfoLoading: false,
  isContactsLoading: false,
  isUpdateAdvertiserLoading: false,
  isUpdateContactsLoading: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
