/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './optimum.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'optimum',
  companyId: 'Optimum_Sports',
  companyName: 'Optimum Sports',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://optimum.myanalyticshub.com',
    staging: 'https://optimum.staging.myanalyticshub.com',
    dev: 'https://optimum.dev.myanalyticshub.com',
    local: 'https://optimum.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'optimumsports_white.png', emailLogo: 'optimumsports.png' },
  config: {
    chartColors: ['#26366a', '#598ff2', '#fb8c00', '#25bdb5', '#76736f', '#ffc107'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
