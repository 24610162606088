/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './requiredmarketing.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'requiredmarketing',
  companyId: 'Required_Marketing',
  companyName: 'Required Marketing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://requiredmarketing.myanalyticshub.com',
    staging: 'https://requiredmarketing.staging.myanalyticshub.com',
    dev: 'https://requiredmarketing.dev.myanalyticshub.com',
    local: 'https://requiredmarketing.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'requiredmarketing.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#3579bc', '#2c3848', '#f1bd44', '#b57ba6', '#4bc6b9', '#d8e1e9'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
