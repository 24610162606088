/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './twooneninegroup.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'twooneninegroup',
  companyId: '219Group',
  companyName: '219 Group',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://219group.myanalyticshub.com',
    staging: 'https://219group.staging.myanalyticshub.com',
    dev: 'https://219group.dev.myanalyticshub.com',
    local: 'https://219group.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: '219group.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#f1650d', '#3fbcec', '#e12f91', '#f2f208', '#b7b7b7', '#222222'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
