/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './denovo.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'denovo',
  companyId: 'de_Novo_Marketing',
  companyName: 'de Novo Marketing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://denovo.myanalyticshub.com',
    staging: 'https://denovo.staging.myanalyticshub.com',
    dev: 'https://denovo.dev.myanalyticshub.com',
    local: 'https://denovo.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'denovo.png', emailLogo: '' },
  config: {
    chartColors: ['#c41f3e', '#fbaa19', '#71c7ad', '#294fb9', '#29b6f6', '#8a8a8a'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
