import logging from './logging';

const Roles = [
  { text: 'TEST', value: 'test' },
  { text: 'Dashboard Admin', value: 'dashadmin' }, // internal dashboard user admin
  { text: 'User Admin', value: 'useradmin' },
  { text: 'Marketing Consultant', value: 'mc' },
  { text: 'Client', value: 'client' },
  { text: 'Client Admin', value: 'linearadmin' },
  { text: 'Image Admin', value: 'imgadmin' },
];

const PermissionsToRoles = {
  SU: 'Super User',
  AGENCY_ADMIN: 'Agency Admin',
  ADVERTISER_EDIT_BASIC: 'MC',
  ADVERTISER_READ: 'Client',
  DEFAULT: 'Not a client',
};

function getUserRole(user: AuthUser) {
  if (!user) {
    logging.log(logging.types.Error, 'missing user object');
    return PermissionsToRoles.DEFAULT;
  }

  // the SU role allows access anywhere
  if (user.is_superuser) return PermissionsToRoles.SU;

  // get flat list of all permissions
  const userPermissions: Array<string> = [];

  if (Array.isArray(user.permissions)) userPermissions.push(...user.permissions);
  if (Array.isArray(user.AgencyRight)) userPermissions.push(...user.AgencyRight);

  if (userPermissions && userPermissions.length !== 0) {
    const normalizedUserPermissions = userPermissions.map(permission => permission.toUpperCase());

    for (const permission in PermissionsToRoles) {
      if (normalizedUserPermissions.includes(permission)) {
        return PermissionsToRoles[permission];
      }
    }
  }

  return PermissionsToRoles.DEFAULT;
}

function HasAccess(user: AuthUser, requiredRoles: Array<string>) {
  if (!user) {
    logging.log(logging.types.Error, 'missing user object');
    return false;
  }

  // the SU role allows access anywhere
  if (user.is_superuser) return true;

  // get flat list of all rights
  const flatRights: Array<string> = [];
  // add AH permissions
  if (Array.isArray(user.permissions)) flatRights.push(...user.permissions);
  // if (Array.isArray(user.IndividualRight)) flatRights.push(...user.IndividualRight);
  if (Array.isArray(user.AgencyRight)) flatRights.push(...user.AgencyRight);

  if (flatRights.length === 0) {
    // if we don't have any rights
    return false;
  }

  // make lookup case-insensitive
  const lowerRights = flatRights.map(right => right.toLowerCase());

  // the SU role allows access anywhere
  if (lowerRights.includes('su')) return true;

  if (!requiredRoles) {
    logging.log(logging.types.Error, 'missing requiredRoles');
    return false;
  }

  // requiredRoles is a list of roles, any of which are valid. they don't all have to be

  // check all other roles
  return requiredRoles.some((role: string) => lowerRights.includes(role.toLowerCase()));
}

function CanEditUser(userToEdit: AuthUser, authenticatedUser: AuthUser): boolean {
  /* summary of current roles
  // { text: 'Dashboard Admin', value: 'dashadmin' }, // internal dashboard user admin
  // { text: 'User Admin', value: 'useradmin' }, // can manage MCs and Clients
  // { text: 'Marketing Consultant', value: 'mc' }, // can manage Clients
  // { text: 'Client', value: 'client' },
  // { text: 'Linear Admin', value: 'linearadmin' }, // not sure yet
  // { text: 'Image Admin', value: 'imgadmin' }, // not sure yet
  */

  if (userToEdit.email === authenticatedUser.email) return true; // edit yourself
  if (authenticatedUser.roles.includes('dashadmin')) return true; // if god mode allow editing of anyone
  if (!userToEdit.roles) return false; // if user doesn't have any roles yet, only dashadmins can edit them
  if (userToEdit.roles.includes('dashadmin') || userToEdit.roles.includes('useradmin')) return false; // can't view dashadmins or useradmins unless you are a dashadmin

  if (authenticatedUser.roles.includes('useradmin')) {
    if (userToEdit.roles.includes('mc') || userToEdit.roles.includes('client')) return true; // useradmins can edit dashmcs and clients
  }

  return true;
}

export interface AuthUser {
  // [key: string]: any;
  accounts?: Array<any>;
  adfs: boolean;
  isAH?: boolean;
  AdvertiserRight: Array<string>;
  Agency: string;
  AvailableAgencies: Array<any>;
  AgencyRight: Array<string>;
  auth: boolean;
  DateEntered: string;
  email?: string;
  forcePWChange: boolean;
  IndividualRight: Array<string>;
  lastModifed: string;
  LayoutRight: Array<string>;
  layouts?: Array<any>;
  name: string;
  password?: string;
  pstate: string;
  roles?: Array<string>;
  version: string;
  products?: Array<string>;
  permissions?: Array<string>;
  advertiser_ids?: Array<string>;
  exp?: number;
  accessToken?: string;
  refreshToken?: string;
  is_superuser: boolean;
  is_agency_admin: boolean;
  active_agency_id: string;
  active_sub_agency_id: string;
  active_station_id: string;
}

export interface AHToken {
  accessToken?: string;
  refreshToken?: string;
  error?: string;
}

export default {
  CanEditUser,
  Roles,
  HasAccess,
  getUserRole,
};
