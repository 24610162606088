/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './alphagraphics.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'alphagraphics',
  companyId: 'alphagraphics',
  companyName: 'Alpha Graphics',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://alphagraphics.myanalyticshub.com',
    staging: 'https://alphagraphics.staging.myanalyticshub.com',
    dev: 'https://alphagraphics.dev.myanalyticshub.com',
    local: 'https://alphagraphics.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'alphagraphics.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#da291c', '#569197', '#ffc107', '#d1d1d1', '#696667', '#a8dadc'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
