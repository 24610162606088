const pstates = [
  {
    value: '1',
    label: 'Active',
  },
  {
    value: '0',
    label: 'Deleted',
  },
  {
    value: '-1',
    label: 'Deactivated',
  },
];

const pstateHash = {
  active: 1,
  deleted: 0,
  deactivated: -1,
};

const salesChannels = [
  {
    name: 'DataSphere',
    value: 'DS',
  },
  {
    name: 'Station',
    value: 'STATION',
  },
  {
    name: 'Shapiro Conference',
    value: 'SHAPIRO',
  },
  {
    name: 'OM',
    value: 'OM',
  },
];

const mainTrackSlides = [
  { title: 'Ad Formats', key: 'adformats', name: 'Ads' },
  { title: 'Map', key: 'localcustomers', name: 'Ads' },
  { title: 'Effective promotions', key: 'effectivepromotions', name: 'Promotions' },
  { title: 'Tell your story', key: 'videooverview', name: 'Videos' },
  { title: 'Examples', key: 'websitedemos', name: 'Website' },
  { title: 'Analytics', key: 'analytics', name: 'Dashboard' },
  { title: 'What happens next', key: 'whathappensnext', name: 'Review' },
];

const allSlides = [
  {
    name: 'Ads',
    slides: [
      { title: 'Ad Formats', key: 'adformats' },
      { title: 'Ad Network', key: 'adnetwork' },
      { title: 'Local Ads', key: 'localads' },
      // { title: 'Search Engine Marketing', key: 'sem' },
      {
        title: 'Geotargeting',
        key: 'localcustomers',
        link: { group: 'Promotions', name: 'effectivepromotions', text: 'Learn about promotions' },
      },
    ],
  },
  {
    name: 'Promotions',
    slides: [
      { title: 'Effective promotions', key: 'effectivepromotions' },
      { title: 'Increasing customers', key: 'increasingcustomers' },
      {
        title: 'Distribution',
        key: 'coupondistribution',
        link: { group: 'Videos', name: 'videooverview', text: 'Learn about videos' },
      },
      { title: 'Machine learning', key: 'machinelearning' },
    ],
  },
  {
    name: 'Videos',
    slides: [
      { title: 'Tell your story', key: 'videooverview' },
      { title: 'Script', key: 'videostory' },
      { title: 'Assets', key: 'videoassets' },
      { title: 'Promote', key: 'videopromote' },
      {
        title: 'Examples',
        key: 'videosamples',
        link: { group: 'Website', name: 'websiteoverview', text: 'Continue' },
      },
    ],
  },
  {
    name: 'Website',
    slides: [
      { title: 'Your website solution', key: 'websiteoverview' },
      { title: 'Easy to build', key: 'easytobuild' },
      { title: 'Mobile', key: 'mobilesite' },
      { title: 'Features', key: 'websitefeatures' },
      { title: 'Landing page', key: 'websitelandingpage' },
      {
        title: 'Examples',
        key: 'websitedemos',
        link: { group: 'Dashboard', name: 'dashboardintro', text: 'Learn about dashboards' },
      },
    ],
  },
  // {
  //   name: 'Why',
  //   slides: [{ title: 'Domain knowledge', key: 'domainknowledge' }],
  // },
  {
    name: 'Dashboard',
    slides: [
      { title: 'Intro', key: 'dashboardintro' },
      { title: 'Analytics', key: 'analytics' },
      {
        title: 'Lead identification',
        key: 'leadidentification',
        link: { group: 'Review', name: 'solution', text: 'Review' },
      },
      { title: 'Actions', key: 'actions' },
    ],
  },
  {
    name: 'Review',
    slides: [
      {
        title: 'Advertising solution',
        key: 'solution',
      },
      {
        title: 'Putting it all together',
        key: 'puttingitalltogether',
      },
      {
        title: 'Trust/Reviews',
        key: 'trustreviews',
      },
      {
        title: 'What happens next',
        key: 'whathappensnext',
      },
    ],
  },
];

const targetMarkets = {
  multipliers: {
    hyperlocal: 1,
    local: 2,
    regional: 3,
  },
};

const friendlyEventNames = {
  sh: 'Default Shapiro',
  ss: 'Default Station Sales',
  ds: 'Default DataSphere Sales',
};

const packageNames = {
  advanced: 'Partner',
  intermediate: 'Executive',
  starter: 'Starter',
};

const exposure = {
  starter: 'Standard',
  intermediate: 'Increased',
  advanced: 'Optimal',
};

const priceLabels = {
  low: 200,
  medium: 400,
};

const imagePresets = {
  // MyDS image presets
  mediumSquare: '135_square_cropped',
};

const contactTypes = ['Work', 'Home', 'Mobile', 'Other'];

const cloneReasons = [
  {
    name: 'One Location Multiple Markets',
    value: 'OneLocationMultipleMarkets',
  },
  {
    name: 'Multiple Location Single Market',
    value: 'MultipleLocationSingleMarket',
  },
];

const howDidTheCallGo = [
  {
    name: 'Scheduled Demo',
    value: 'ScheduledDemo',
    color: 'purple',
    selectable: true,
  },
  {
    name: 'Waiting On Decision Maker',
    value: 'WaitingOnDecisionMaker',
    color: 'purple',
    selectable: true,
  },
  {
    name: 'Spoke With Gatekeeper',
    value: 'SpokeWithGatekeeper',
    color: 'purple',
    selectable: true,
  },
  {
    name: 'Gave Demo',
    value: 'GaveDemo',
    color: 'blue',
    selectable: false,
  },
  {
    name: 'Gave Blind Demo',
    value: 'GaveBlindDemo',
    color: 'blue',
    selectable: true,
  },
  {
    name: 'Left Message',
    value: 'LeftMessage',
    color: 'smoke',
    selectable: true,
  },
  {
    name: 'Sent Checkout',
    value: 'sentcheckout',
    color: 'green',
    selectable: false,
  },
  {
    name: 'Opened Proposal',
    value: 'OpenedProposal',
    color: 'green',
    selectable: false,
  },
  {
    name: 'Opened Checkout',
    value: 'OpenedCheckout',
    color: 'green',
    selectable: false,
  },
  {
    name: 'Demo Opened',
    value: 'OpenedDemo',
    color: 'green',
    selectable: false,
  },
  {
    name: 'Sent Proposal',
    value: 'SentProposal',
    color: 'blue',
    selectable: false,
  },
  {
    name: 'Cart Loaded',
    value: 'CartLoaded',
    color: 'blue',
    selectable: false,
  },
  {
    name: 'Sent Demo',
    value: 'SentDemo',
    color: 'blue',
    selectable: false,
  },
  {
    name: 'Demo Shared',
    value: 'DemoShare',
    color: 'blue',
    selectable: false,
  },
  {
    name: 'Screen Shared',
    value: 'ScreenShare',
    color: 'blue',
    selectable: false,
  },
  {
    name: 'Incoming Call',
    value: 'IncomingCall',
    color: 'blue',
    selectable: false,
  },
  {
    name: 'Outgoing Call',
    value: 'OutgoingCall',
    color: 'blue',
    selectable: false,
  },
  {
    name: 'Opened Detail Page',
    value: 'OpenedDetailPage',
    color: 'smoke',
    selectable: false,
  },
  {
    name: 'Unknown',
    value: 'Unknown',
    color: 'smoke',
    selectable: false,
  },
  {
    name: 'General Note',
    value: 'GeneralNote',
    color: 'smoke',
    selectable: false,
  },
  {
    name: 'Removed Reservation',
    value: 'RemovedReservation',
    color: 'smoke',
    selectable: false,
  },
  {
    name: 'Removed Station Lock',
    value: 'RemovedStationLock',
    color: 'smoke',
    selectable: false,
  },
  {
    name: 'Out Of Business',
    value: 'OutOfBusiness',
    color: 'red',
    selectable: false,
  },
  {
    name: 'Out of Business / Wrong Number',
    value: 'OutOfBusiness_WrongNumber',
    color: 'red',
    selectable: false,
  },
  {
    name: 'Not A Fit',
    value: 'NotAFit',
    color: 'red',
    selectable: false,
  },
  {
    name: 'Placed Order',
    value: 'PlacedOrder',
    color: 'gold',
    selectable: false,
  },
  {
    name: 'Customer Sold',
    value: 'CustomerSold',
    color: 'gold',
    selectable: false,
  },
  {
    name: 'Prospect Added',
    value: 'ProspectAdded',
    color: 'smoke',
    selectable: false,
  },
  {
    name: 'No Answer',
    value: 'NoAnswer',
    color: 'smoke',
    selectable: false,
  },
  {
    name: 'Business Added',
    value: 'BusinessAdded',
    color: 'smoke',
    selectable: false,
  },
  {
    name: 'Do Not Call Me Again',
    value: 'DoNotCallMeAgain',
    color: 'red',
    selectable: false,
  },
  {
    name: 'Station DNC',
    value: 'StationDNC',
    color: 'red',
    selectable: false,
  },
];

const dncList = [
  {
    name: 'Do Not Call Me Again',
    value: 'DoNotCallMeAgain',
  },
  {
    name: 'Not A Fit',
    value: 'NotAFit',
  },
  {
    name: 'Out of Business / Wrong Number',
    value: 'OutOfBusiness_WrongNumber',
  },
  {
    name: 'Station DNC',
    value: 'StationDNC',
  },
];

export default {
  allSlides,
  contactTypes,
  dncList,
  cloneReasons,
  exposure,
  friendlyEventNames,
  howDidTheCallGo,
  imagePresets,
  mainTrackSlides,
  packageNames,
  priceLabels,
  pstateHash,
  pstates,
  salesChannels,
  targetMarkets,
};
