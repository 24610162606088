import mutations from './mutations';
import actions from './actions';

const state = {
  status: null,
  error: null,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
