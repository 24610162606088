import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';
import { LoadedAllAdvertisers } from '../customer/types';

export default {
  [CONSTANTS.SET_LOADING](state: StateType, loading): void {
    Vue.set(state, 'loading', loading);
  },
  [CONSTANTS.SET_ERROR](state: StateType, error): void {
    Vue.set(state, 'error', error);
  },
  [CONSTANTS.SET_ALL_ADVERTISERS_LOADING](_state, status): void {
    Vue.set(_state, 'loadingAllAdvertisers', status);
  },
  [CONSTANTS.SET_ALL_ADVERTISERS](_state, _data: LoadedAllAdvertisers): void {
    if (_data) {
      Vue.set(_state, 'allAdvertisers', _data);
    }
  },
  [CONSTANTS.SET_NETWORK_ACTIVITY](_state, status): void {
    let counter = _state.networkActivityCounter;
    if (status) {
      counter += 1;
    } else {
      counter -= 1;
    }
    if (counter < 0) {
      counter = 0;
    }
    Vue.set(_state, 'networkActivityCounter', counter);
  },
};
