/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './brkmarketing.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'brkmarketing',
  companyId: 'BRK_Global_Marketing',
  companyName: ' BRK Global Marketing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://brkmarketing.myanalyticshub.com',
    staging: 'https://brkmarketing.staging.myanalyticshub.com',
    dev: 'https://brkmarketing.dev.myanalyticshub.com',
    local: 'https://brkmarketing.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'brkmarketing_color.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#0b326c', '#219ebc', '#8ecae6', '#ffd103', '#fb8500', '#9d9d9d'],

    header: { borderColor: '#FF0000FF' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
