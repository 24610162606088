/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './wellroundedstudios.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'wellroundedstudios',
  companyId: 'Well_Rounded_Studios',
  companyName: 'Well Rounded Studios',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://wellroundedstudios.myanalyticshub.com',
    staging: 'https://wellroundedstudios.staging.myanalyticshub.com',
    dev: 'https://wellroundedstudios.dev.myanalyticshub.com',
    local: 'https://wellroundedstudios.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'wellroundedstudios.png', emailLogo: '' },
  config: {
    chartColors: ['#ba3521', '#ee9b00', '#eae0bc', '#94d2bd', '#005f73', '#1e1f22'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
