import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      return;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registered(sw: any) {
      // console.log("rsw registered", sw);
      setInterval(() => {
        sw.update()
          .then((/* registration: any */) => {
            // console.log("SW update", registration);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.log('SW update error', error);
          });
      }, 20 * 1000); // checks for a new version every 20 seconds
    },
    cached() {
      return;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updatefound(sw: any) {
      // console.log('New content is downloading.')
      document.dispatchEvent(new CustomEvent('swUpdateAvailable', { detail: sw }));
      sw.update();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updated(sw: any) {
      // console.log('New content is available; please refresh.')
      document.dispatchEvent(new CustomEvent('swUpdated', { detail: sw }));
    },
    offline() {
      // eslint-disable-next-line no-console
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error('Error during service worker registration:', error);
    },
  });
}
