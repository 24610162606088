/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import { Variables, ThemeConfig } from './variables';
import defaultValues from './default.scss';
import { setVariableColors, getDMAGradient } from './utils'; // TODO: would be nice to maked this typed in a proper way so we don't have to create a new variable
const variables: Variables = defaultValues;
export const defaultColors = {
  chartColors: ['#4DB6AC', '#3F51B5', '#FB8C00', '#EC407A', '#29B6F6', '#FFC107'],
  genders: { female: '#f48fb1', male: '#4fc3f7', other: '#e0e0e0' },
};
const theme: ThemeConfig = {
  name: 'default',
  companyId: 'dev',
  companyName: 'Analytics',
  rootDomain: 'sbganalytics.com',
  parentAgency: 'none',
  domains: {
    production: 'https://sbganalytics.com',
    staging: 'https://staging.sbganalytics.com',
    dev: 'https://dev.sbganalytics.com',
    local: 'https://localhost:8080',
  },
  ...setVariableColors(variables),
  logo: {},
  config: {
    filters: {
      size: 'subtitle-2',
      weight: 'bold',
      case: 'uppercase',
    },
    genders: { female: '#f48fb1', male: '#4fc3f7', other: '#e0e0e0' },
    chartBorder: 'card',
    chartColors: ['#4db6ac', '#3f51b5', '#fb8c00', '#ec407a', '#29b6f6', '#ffc107'],
    pieCharts: {
      stacked: true,
      firstItem: 'chart',
      thickness: 'thin',
    },
    summary: {
      layout: 'mapSummaryOverlay',
    },
    header: { borderSize: 0 },
    shareDate: true,
  },
  polygons: {
    light: {
      zip: { fillOpacity: 0.6, color: '#999', fillColor: '#29B6F6', weight: 0.5 },
      zipHover: { fillOpacity: 0.9, color: '#999', fillColor: '#29B6F6', weight: 1 },
      dma: { fillOpacity: 0.3, color: '#999', fillColor: '#29B6F6', weight: 1 },
      dmaGradient: getDMAGradient('#29B6F6'),
      dmaHover: { fillOpacity: 0.8, color: '#999', fillColor: '#29B6F6', weight: 1 },
      dmaSelected: { fillOpacity: 0.0, color: '#999', fillColor: '#29B6F6', weight: 1 },
      dmaSelectedHover: { fillOpacity: 0.1, color: '#999', fillColor: '#29B6F6', weight: 1 },
      dmaDeselected: { fillOpacity: 0.1, color: '#999', fillColor: '#29B6F6', weight: 1 },
      dmaDeselectedHover: { fillOpacity: 0.4, color: '#999', fillColor: '#29B6F6', weight: 1 },
      dmaZip: { fillOpacity: 0.4, color: '#999', fillColor: '#29B6F6', weight: 1 },
      dmaZipGradient: getDMAGradient('#29B6F6'),
      dmaZipHover: { fillOpacity: 0.8, color: '#999', fillColor: '#29B6F6', weight: 1 },
      rsn: { fillOpacity: 0.6, color: '#999', fillColor: '#29B6F6', weight: 1 },
      rsnHover: { fillOpacity: 0.8, color: '#999', fillColor: '#29B6F6', weight: 3 },
      outerMarket: { fillOpacity: 0.4, color: '#CCC', fillColor: '#29B6F6', weight: 1 },
      outerMarketHover: { fillOpacity: 0.8, color: '#CCC', fillColor: '#29B6F6', weight: 2 },
    },
  },
};
export default theme;
