export default [
  {
    id: 'Sinclair',
    subAgencies: [
      { name: 'Compulse', value: 'Compulse' },
      { name: 'Drive Auto', value: 'DriveAuto' },
      { name: 'Vantage Consultants', value: 'GetTheVantage' },
    ],
  },
  {
    id: 'Win_Big',
    subAgencies: [{ name: 'The Media Beast', value: 'themediabeast' }],
  },
  {
    id: 'LPP',
    subAgencies: [{ name: 'The Xcite Group', value: 'thexcitegroup' }],
  },
];
