import { StateType } from './types';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const debug: boolean = process.env.NODE_ENV !== 'production';

const state: StateType = {
  current: null,
  selectedCampaigns: null,
  selectedCampaignTypes: [],
  selectedCampaignIDs: [],
  hasCampaignFiltered: false,
  hasTypeFiltered: false,
  loading: false,
};

export default {
  strict: debug,
  mutations,
  actions,
  state,
  getters,
};
