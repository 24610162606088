import trackingValues from '../plugins/trackingValues';
import jwtDecode from 'jwt-decode';
import commonPkg from '@point/classes';

const { roles } = commonPkg;

export default {
  data: (): {
    trackValue: Record<string, string>;
  } => ({
    trackValue: trackingValues,
  }),
  methods: {
    registerMixpanelSuperprops(data) {
      this.$mixpanel.register({
        UserId: data?.user_id || 'anonymous',
        UserName: data?.full_name || this.$store.state.customer?.user?.name || 'anonymous',
        AgencyId: data?.active_agency_id || this.$store.state.customer?.user?.agency_id || null,
        Agency: this.$store.state.customer?.user?.Agency || null,
        StationId: data?.active_station_id || null,
        StationName: data?.active_station_name || null,
      });
    },
    ahLoginTrack(event: string, token: string, status: string) {
      const decodedData = jwtDecode(token);
      const email = decodedData?.email || this.$store.state.customer?.user?.email || 'N/A';
      const emailDomain = email && email !== 'N/A' ? email.split('@')[1] : null;
      // This is adding extra mixpanel event, though it is highly recommended to use this method by their docs
      this.$mixpanel.identify(email);
      // Now we are updating profile with the recommended way by mixpanel
      this.$mixpanel.people.set({
        $email: email,
        emailDomain: emailDomain
      });

      this.$mixpanel.track(event, {
        Status: status || 'N/A',
        distinct_id: email,
        UserName: decodedData?.full_name || this.$store.state.customer?.user?.name || 'N/A',
        Agency: decodedData?.active_agency_id || this.$store.state.customer?.user?.Agency || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(decodedData),
      });
    },
    analyticTrack(event: string, action: string, payload?: Record<string,any>): void {
      this.$mixpanel.track(event, {
        distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
        UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
        Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
        Action: action,
        Advertiser: this.$route.query?.id || 'N/A',
        Tab: this.$route.query?.tab || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(this.$store.state.customer?.user),
        ...payload,
      });
    },
    analyticExportTrack(event: string, action: string, fileType: string, tactic: string, componentId: string) {
      if (componentId) {
        this.$mixpanel.track(event, {
          distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
          UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
          Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
          Action: action,
          FileType: fileType || 'N/A',
          Tactic: tactic || 'N/A',
          SingleModuleId: componentId || 'N/A',
          Campaign: this.$route.query?.viewCampaigns || 'N/A',
          Advertiser: this.$route.query?.id || 'N/A',
          Tab: this.$route.query?.tab || 'N/A',
          Domain: window.location?.hostname || 'N/A',
          UserRole: roles.getUserRole(this.$store.state.customer?.user),
        });
      } else {
        this.$mixpanel.track(event, {
          distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
          UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
          Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
          Action: action,
          FileType: fileType || 'N/A',
          Tactic: tactic || 'N/A',
          Campaign: this.$route.query?.viewCampaigns || 'N/A',
          Advertiser: this.$route.query?.id || 'N/A',
          Tab: this.$route.query?.tab || 'N/A',
          Domain: window.location?.hostname || 'N/A',
          UserRole: roles.getUserRole(this.$store.state.customer?.user),
        });
      }
    },
    analyticCustomizationsTrack(event: string, action: string, tactic: string) {
      this.$mixpanel.track(event, {
        distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
        UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
        Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
        Action: action,
        Tactic: tactic || 'N/A',
        Advertiser: this.$route.query?.id || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(this.$store.state.customer?.user),
      });
    },
    analyticSaveReportTrack(
      event: string,
      action: string,
      fileType: string,
      frequency: string,
      timeofday: string,
      parentName: string,
    ) {
      this.$mixpanel.track(event, {
        distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
        UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
        Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
        Action: action,
        FileType: fileType,
        Frequency: frequency,
        TimeOfDay: timeofday,
        Advertiser: this.$route.query?.id || parentName || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(this.$store.state.customer?.user),
      });
    },
    analyticDeleteReportTrack(
      event: string,
      action: string,
      reportId: string,
      requestedBy: string,
      reportName: string,
      advertiser: string,
    ) {
      this.$mixpanel.track(event, {
        distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
        UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
        Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
        Action: action,
        ReportID: reportId,
        RequestedBy: requestedBy,
        ReportName: reportName || 'N/A',
        Advertiser: advertiser || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(this.$store.state.customer?.user),
      });
    },
    analyticMRSelectCampaignsTrack(
      event: string,
      campaigns: string,
      parentName: string,
    ) {
      this.$mixpanel.track(event, {
        distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
        UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
        Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
        Campaigns: campaigns,
        Advertiser: this.$route.query?.id || parentName || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(this.$store.state.customer?.user),
      });
    },
    analyticMRSelectFrequencyTrack(
      event: string,
      frequency: string,
      parentName: string,
    ) {
      this.$mixpanel.track(event, {
        distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
        UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
        Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
        Frequency: frequency,
        Advertiser: this.$route.query?.id || parentName || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(this.$store.state.customer?.user),
      });
    },
    analyticMRSelectFileTypeTrack(
      event: string,
      fileType: string,
      parentName: string,
    ) {
      this.$mixpanel.track(event, {
        distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
        UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
        Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
        FileType: fileType,
        Advertiser: this.$route.query?.id || parentName || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(this.$store.state.customer?.user),
      });
    },
    analyticShareTrack(
      event: string,
      action: string,
      shareId: string,
      shareToken: string,
      advertiserSelected,
      sharedTab,
    ) {
      this.$mixpanel.track(event, {
        distinct_id: this.$store.state.customer?.user?.email ? this.$store.state.customer.user.email : 'Shared Guest',
        UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
        Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
        Action: action,
        ShareID: shareId || 'N/A',
        ShareToken: shareToken || 'N/A',
        Advertiser: this.$route.query?.id || advertiserSelected || 'N/A',
        Tab: this.$route.query?.tab || sharedTab || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(this.$store.state.customer?.user),
      });
    },
    analyticsLogoChangeTrack(event: string, action: string, newlogo: string, oldlogo: string) {
      this.$mixpanel.track(event, {
        distinct_id: this.$store.state.customer?.user?.email || 'Shared Guest',
        UserName: this.$store.state.customer?.user?.name || 'Shared Guest',
        Agency: this.$store.state.customer?.user?.Agency || 'Shared Guest',
        Action: action,
        NewLogo: newlogo || 'N/A',
        OldLogo: oldlogo || 'N/A',
        Advertiser: this.$route.query?.ppid || 'N/A',
        Domain: window.location?.hostname || 'N/A',
        UserRole: roles.getUserRole(this.$store.state.customer?.user),
      });
    },
    analyticChangePasswordTrack(event: string, action: string, success: string, email: string) {
      this.$mixpanel.track(event, {
        Action: action,
        Status: success,
        Domain: window.location?.hostname || 'N/A',
        Email: email,
      });
    },
  },
};
