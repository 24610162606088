/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './fms.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'fms',
  companyId: 'First_Media_Services',
  companyName: 'First Media Services',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://fms.myanalyticshub.com',
    staging: 'https://fms.staging.myanalyticshub.com',
    dev: 'https://fms.dev.myanalyticshub.com',
    local: 'https://fms.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'fms_white.png', emailLogo: 'fms_color.png' },
  config: {
    chartColors: ['#074b7c', '#8cc63e', '#29b6f6', '#ffc107', '#666662', '#9ea8af'],

    maps: {
      themes: ['fms'],
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
