/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './spmarketingexperts.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'spmarketingexperts',
  companyId: 'SP_Marketing_by_FireDrum',
  companyName: 'SP Marketing by FireDrum',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://spmarketingexperts.myanalyticshub.com',
    staging: 'https://spmarketingexperts.staging.myanalyticshub.com',
    dev: 'https://spmarketingexperts.dev.myanalyticshub.com',
    local: 'https://spmarketingexperts.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'spmarketingexperts.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#fd7829', '#0e8494', '#73c83d', '#a39a9d', '#5a5a5a', '#ffc107'],

    shareDate: true,
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
