import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { apollo } from '../../../index';
import {
  GetGoogleSearchConsoleResponse,
  GoogleDataParams,
  GoogleAdManagerParams,
  GoogleAdMangerRefreshDataParams,
  GetGoogleAnaltyicsStatusResponse,
  GetGoogleAdDataResponse,
  GetGoogleAdManagerResultsResponse,
  GetGoogleAdManagerSearchResultsResponse,
  PostSaveGoogleAdManagerResponse,
  SaveGoogleAdManagerParams,
  GetGoogleAnalyticsAvailablePropertiesResponse,
  GetGoogleAnalyticsAvailableProfilesResponse,
} from './../types/google';
import { AccountLinkingResponse } from './../types';

export default {
  async getGoogleSearchConsole(props: GoogleDataParams) {
    try {
      const response: ApolloQueryResult<GetGoogleSearchConsoleResponse> = await apollo().query({
        query: gql`
          query GetGoogleSearchConsole($params: GoogleDataParams) {
            getGoogleSearchConsole(params: $params) {
              accounts {
                Email
                FirstName
                LastName
                PictureUrl
                Properties {
                  id
                  url
                  urlType
                }
                Source
                UserId
                UserSource
                name
                pstate
              }
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
          },
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getGoogleAnalyticsStatus(props: GoogleDataParams) {
    try {
      const response: ApolloQueryResult<GetGoogleAnaltyicsStatusResponse> = await apollo().query({
        query: gql`
          query GetGoogleAnalyticsStatus($params: GoogleDataParams) {
            getGoogleAnalyticsStatus(params: $params) {
              status {
                Accounts {
                  Id
                  Name
                  Permissions
                }
                Connected
                Email
                FirstName
                GrantedScopes
                Healthy
                LastName
                PictureUrl
                RequiredScopes
                RerequestScope
                Scopes {
                  Granted
                  Required
                  Scope
                }
                Selection {
                  Account {
                    Id
                    Name
                  }
                  Profile {
                    Id
                    Name
                  }
                  WebProperty {
                    Id
                    Name
                  }
                  GA4Property {
                    Id
                    Name
                  }
                }
                ViewStatus
                Name
                pstate
              }
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
            client: props.client,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getGoogleAdData(props: GoogleDataParams) {
    try {
      const response: ApolloQueryResult<GetGoogleAdDataResponse> = await apollo().query({
        query: gql`
          query GetGoogleAdData($params: GoogleDataParams) {
            getGoogleAdData(params: $params) {
              accounts {
                AccountChildren {
                  AccountChildren {
                    AccountId
                    CanManageClients
                    CustomerId
                    DataSourceCode
                    ITYPE
                    IsLeaf
                    IsLinkable
                    IsRootOn
                    IsTestAccount
                    ParentChains
                    ParentIds
                    PropertyId
                    name
                    pstate
                  }
                  AccountId
                  CanManageClients
                  CustomerId
                  DataSourceCode
                  ITYPE
                  IsLeaf
                  IsLinkable
                  IsRootOn
                  IsTestAccount
                  PropertyId
                  name
                  pstate
                }
                Email
                FirstName
                LastName
                PictureUrl
                Source
                UserId
                UserSource
                name
                pstate
              }
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
          },
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async updateGoogleAdsAccountsForAdvertiser(props: GoogleAdMangerRefreshDataParams) {
    try {
      const response: ApolloQueryResult<AccountLinkingResponse> = await apollo().query({
        query: gql`
          query UpdateGoogleAdsAccountsForAdvertiser($params: GoogleAdMangerRefreshDataParams) {
            updateGoogleAdsAccountsForAdvertiser(params: $params) {
              id
              CallRail {
                BackfillComplete
                Enabled
              }
              FacebookAds {
                Enabled
                BackfillComplete
              }
              FacebookInsights {
                Enabled
                BackfillComplete
              }
              GoogleAdManager {
                BackfillComplete
                Enabled
              }
              GoogleAds {
                Enabled
                BackfillComplete
              }
              GoogleAdsVideo {
                Enabled
                BackfillComplete
              }
              GoogleAnalytics {
                Enabled
                BackfillComplete
              }
              GoogleSearchConsole {
                BackfillComplete
                Enabled
              }
              SiteImpact {
                Enabled
                BackfillComplete
              }
              GAM {
                Enabled
              }
            }
          }
        `,
        variables: {
          params: {
            advertiserId: props.advertiserId,
            accountIds: props.accountIds,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getGoogleAdManagerLinked(props: GoogleAdManagerParams) {
    try {
      const response: ApolloQueryResult<GetGoogleAdManagerResultsResponse> = await apollo().query({
        query: gql`
          query GetGoogleAdManagerLinked($params: GoogleAdManagerParams) {
            getGoogleAdManagerLinked(params: $params) {
              Enabled
              OrdersList {
                OrderId
                OrderName
                CampaignsList {
                  CampaignId
                  CampaignName
                }
              }
            }
          }
        `,
        variables: {
          params: {
            advertiserId: props.advertiserId,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getGoogleAdManagerResults(props: GoogleAdManagerParams) {
    try {
      const response: ApolloQueryResult<GetGoogleAdManagerSearchResultsResponse> = await apollo().query({
        query: gql`
          query GetGoogleAdManagerResults($params: GoogleAdManagerParams) {
            getGoogleAdManagerResults(params: $params) {
              OrdersList {
                CampaignsList {
                  CampaignId
                  CampaignName
                }
                OrderId
                OrderName
              }
              Total
            }
          }
        `,
        variables: {
          params: {
            advertiserId: props.advertiserId,
            search: props.search,
          },
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async saveGoogleAdManager(props: SaveGoogleAdManagerParams) {
    try {
      const response: ApolloQueryResult<PostSaveGoogleAdManagerResponse> = await apollo().mutate({
        mutation: gql`
          mutation SaveGoogleAdManager($params: SaveGoogleAdManagerParams) {
            saveGoogleAdManager(params: $params) {
              OrdersList {
                CampaignsList {
                  CampaignId
                  CampaignName
                }
                OrderId
                OrderName
              }
            }
          }
        `,
        variables: {
          params: {
            CampaignsList: props.CampaignsList,
            advertiserId: props.advertiserId,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getGoogleAnalyticsAvailableProperties(props: GoogleDataParams) {
    try {
      const response: ApolloQueryResult<GetGoogleAnalyticsAvailablePropertiesResponse> = await apollo().query({
        query: gql`
          query GetGoogleAnalyticsAvailableProperties($params: GoogleDataParams) {
            getGoogleAnalyticsAvailableProperties(params: $params) {
              WebProperties {
                AccountId
                Id
                Kind
                Name
                Permissions
                Vertical
                WebsiteUrl
                IsGA4
              }
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
            accountId: props.accountId,
            client: props.client,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getGoogleAnalyticsAvailableProfiles(props: GoogleDataParams) {
    try {
      const response: ApolloQueryResult<GetGoogleAnalyticsAvailableProfilesResponse> = await apollo().query({
        query: gql`
          query GetGoogleAnalyticsAvailableProfiles($params: GoogleDataParams) {
            getGoogleAnalyticsAvailableProfiles(params: $params) {
              Profiles {
                AccountId
                BotFilteringEnabled
                Id
                Kind
                Name
                Permissions
                Type
                VertiWebPropertyIdcal
                Vertical
                WebsiteUrl
              }
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
            accountId: props.accountId,
            profile: props.profile,
            client: props.client,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
};
