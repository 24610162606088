/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './bespokemarketingsolutions.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'bespokemarketingsolutions',
  companyId: 'bespokemarketingsolutions',
  companyName: 'Bespoke Marketing Solutions',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://bespokemarketingsolutions.myanalyticshub.com',
    staging: 'https://bespokemarketingsolutions.staging.myanalyticshub.com',
    dev: 'https://bespokemarketingsolutions.dev.myanalyticshub.com',
    local: 'https://bespokemarketingsolutions.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'bespokemarketingsolutions.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#2d2e7b', '#0a9396', '#94d2bd', '#ee9b00', '#ae2012', '#303030'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
