import mutations from './mutations';
import actions from './actions';

const state = {
  loading: false,
  error: null,
  agencies: null,
  successMessage: null,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
