export default {
  SET_ADVERTISER_INFO: 'SET_ADVERTISER_INFO',
  SET_CONTACTS: 'SET_CONTACTS',

  SET_ADVERTISER_INFO_ERROR: 'SET_ADVERTISER_INFO_ERROR',
  SET_CONTACTS_ERROR: 'SET_CONTACTS_ERROR',
  SET_UPDATE_ADVERTISER_ERROR: 'SET_UPDATE_ADVERTISER_ERROR',
  SET_UPDATE_CONTACTS_ERROR: 'SET_UPDATE_CONTACTS_ERROR',

  SET_ADVERTISER_INFO_CACHE: 'SET_ADVERTISER_INFO_CACHE',
  SET_IS_ADVERTISER_INFO_CACHED: 'SET_IS_ADVERTISER_INFO_CACHED',

  SET_IS_ADVERTISER_INFO_LOADING: 'SET_IS_ADVERTISER_INFO_LOADING',
  SET_IS_CONTACTS_LOADING: 'SET_IS_CONTACTS_LOADING',
  SET_IS_UPDATE_ADVERTISER_LOADING: 'SET_IS_UPDATE_ADVERTISER_LOADING',
  SET_IS_UPDATE_CONTACTS_LOADING: 'SET_IS_UPDATE_CONTACTS_LOADING',
};
