/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './wgcommgroup.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'wgcommgroup',
  companyId: 'WG_Communications_Group',
  companyName: 'WG Communications Group',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'Sinclair',
  domains: {
    production: 'https://wgcommgroup.myanalyticshub.com',
    staging: 'https://wgcommgroup.staging.myanalyticshub.com',
    dev: 'https://wgcommgroup.dev.myanalyticshub.com',
    local: 'https://wgcommgroup.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'wgcommgroup.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#e4003b', '#2f43b5', '#67cef9', '#cbec40', '#9fa2a4', '#313131'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
