/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './babylondentalcare.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'babylondentalcare',
  companyId: 'Babylon_Dental',
  companyName: ' Babylon Dental',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://babylondentalcare.myanalyticshub.com',
    staging: 'https://babylondentalcare.staging.myanalyticshub.com',
    dev: 'https://babylondentalcare.dev.myanalyticshub.com',
    local: 'https://babylondentalcare.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'babylondentalcare.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#3b43a5', '#429dac', '#fec519', '#7dd6ff', '#8e8e8e', '#444444'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
