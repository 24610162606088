/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './madisontaylormarketing.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'madisontaylormarketing',
  companyId: 'madisontaylormarketing',
  companyName: 'Madison Taylor Marketing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://madisontaylormarketing.myanalyticshub.com',
    staging: 'https://madisontaylormarketing.staging.myanalyticshub.com',
    dev: 'https://madisontaylormarketing.dev.myanalyticshub.com',
    local: 'https://madisontaylormarketing.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'madisontaylormarketing_color.png', emailLogo: 'madisontaylormarketing_color.png' },
  whiteToolbar: true,
  config: {
    chartColors: ['#ffa800', '#ef5301', '#076477', '#8fd3de', '#a7a7a7', '#303030'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
