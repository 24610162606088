import mutations from './mutations';
import actions from './actions';

const state = {
  loading: false,
  error: null,
  componentId: null,
  sectionId: null,
  editMode: false,
  currCustomizations: null,
  enableSave: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
