const currency = {
  convertToString(price) {
    if (typeof price === 'undefined') return false;
    return Number(price.replace(/[^0-9.-]+/g, ''));
  },
  // returns price in USD format example: $1,050.40
  convertToUSD(price) {
    if (typeof price === 'undefined') return false;
    return parseFloat(price).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  },
  // returns number with correct commas example: 1,050
  convertWithCommas(price) {
    if (typeof price === 'undefined') return false;
    return parseFloat(price).toLocaleString('en-US'); // always return 2 digits i.e. 14.20 instead of 14.2
  },
  // returns correct contract text from number, 1, 6, 12
  contractText(contract) {
    if (typeof contract === 'undefined') return '';
    const months = contract === '1' ? 'per month' : 'for 6 months';
    return contract === '12' ? 'for one year' : months;
  },
  // Example: $2,431.80 for 6 months - returns HTML
  bundlePrice(bundle, salesProposal) {
    const contract = this.contractText(salesProposal.contractLength);
    const monthlyDiscount = bundle.Monthly[0].ListAmount ? bundle.Monthly[0].Amount : null;
    const discount = bundle.DiscountedSalePrice ? bundle.DiscountedSalePrice : monthlyDiscount;
    if (discount) {
      return !salesProposal.prepay
        ? `${this.convertToUSD(discount)} <sup>/mo</sup>`
        : `${this.convertToUSD(bundle.ContractCost)} ${contract}`;
    }
    return !salesProposal.prepay
      ? `$${this.convertWithCommas(bundle.price)} <sup>/mo</sup>`
      : `$${this.convertWithCommas(bundle.ContractCost)} ${contract}`;
  },
  // Example: 6 month contract, Prepaid, supLabel is boolean - returns HTML
  contractPrepayLabel(salesProposal, supLabel) {
    let label;
    if (parseInt(salesProposal.contractLength, 10) === 1) label = 'Month-to-Month Contract';
    else label = `${salesProposal.contractLength} ${supLabel ? '<sup>/mo</sup>' : 'month'} contract`;
    return `${label}${salesProposal.prepay ? ', prepaid' : ''}`;
  },
  // Example: <strike>$579</strike> <b>$405.30</b> - returns HTML
  monthPrice(bundle) {
    const hasDiscount = bundle.Monthly[0].ListAmount;

    // Get base list price no discount
    const listPrice = hasDiscount
      ? this.convertWithCommas(bundle.Monthly[0].ListAmount)
      : this.convertWithCommas(bundle.Monthly[0].Amount);

    // Get discount price
    const monthlyDiscount = hasDiscount ? bundle.Monthly[0].Amount : null;
    const discount = bundle.DiscountedSalePrice ? bundle.DiscountedSalePrice : monthlyDiscount;

    return discount ? `<strike>$${listPrice}</strike> <b>${this.convertToUSD(discount)}</b> ` : `<b>$${listPrice}</b>`;
  },
  // Toggle for monthly message "After the 6 month period, your price will revert to $579 $405.30 per month, on a month-to-month basis."
  showPriceMessage(salesProposal) {
    return parseInt(salesProposal.contractLength, 10) !== 1 && salesProposal.prepay;
  },
};
export default currency;
