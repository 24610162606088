const dotenv = require('dotenv');
const path = require('path');

export default {
  init: (root: string, envFileName = '') => {
    if (!envFileName || envFileName === '') {
      // eslint-disable-next-line no-console
      console.log(
        'calling config.init without a product specified, it could be a sign some dependency is loaded before @point/classes configuration is initialized',
      );
    }
    // console.log('config init', root, envFileName);
    if (typeof window !== 'undefined') {
      const cfg: any = {};
      Object.keys(process.env).forEach((k: string) => {
        if (k.length > 8 || k.substr(0, 8) === 'VUE_APP_') {
          cfg[k.substr(8)] = process.env[k];
        }
      });
      return {
        ...cfg,
        browser: true,
      };
    }
    let filePath = path.join(root, '/', envFileName + '.env');
    let cfg = dotenv.config({ path: filePath });
    if (cfg.error) {
      if (envFileName !== '') {
        // eslint-disable-next-line no-console
        console.log('dotenv file not found, trying .env', filePath);
        filePath = path.join(root, '/', '.env');
        // try a default .env file
        cfg = dotenv.config({ path: filePath });
      }
      if (cfg.error) {
        // eslint-disable-next-line no-console
        console.error('dotenv error', cfg);
        return {};
      }
    }
    if (!cfg.parsed) {
      // eslint-disable-next-line no-console
      console.error('dotenv empty', cfg);
      return {};
    }

    // eslint-disable-next-line no-console
    console.log('using dotenv file:', filePath);

    return {
      server: true,
      ...cfg.parsed,
    };
  },
};
