/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './pandologic.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'pandologic',
  companyId: 'PandoLogic',
  companyName: 'PandoLogic',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://pandologic.myanalyticshub.com',
    staging: 'https://pandologic.staging.myanalyticshub.com',
    dev: 'https://pandologic.dev.myanalyticshub.com',
    local: 'https://pandologic.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'pandologic.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#31b0c9', '#96bf3f', '#dcd6f7', '#dd9787', '#914d76', '#f26419'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
