/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './searchengingeguys.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'searchengingeguys',
  companyId: 'The_Search_Engine_Guys',
  companyName: 'The Search Engine Guys',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://searchengingeguys.myanalyticshub.com',
    staging: 'https://searchengingeguys.staging.myanalyticshub.com',
    dev: 'https://searchengingeguys.dev.myanalyticshub.com',
    local: 'https://searchengingeguys.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'searchengingeguys.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#21680d', '#0098b0', '#22516e', '#fccd00', '#adadad', '#3b3b3b'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
