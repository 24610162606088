import { getCssVar } from '@c360/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const allThemes: any = {};

import compulseTheme from './compulse';
allThemes.compulse = compulseTheme;

import driveAutoTheme from './driveauto';
allThemes.driveAuto = driveAutoTheme;

import myAnalyticsHub from './myanalyticshub';
allThemes.myAnalyticsHub = myAnalyticsHub;

import lppTheme from './lpp';
allThemes.lpp = lppTheme;

import sbgTheme from './sbg';
allThemes.sbg = sbgTheme;

import sbgBackup from './sbgbackup';
allThemes.sbgBackup = sbgBackup;

import dealerAlchemistTheme from './dealeralchemist';
allThemes.dealerAlchemist = dealerAlchemistTheme;

import arMarketing from './ar';
allThemes.arMarketing = arMarketing;

import demo from './demo';
allThemes.demo = demo;

import lily from './lily';
allThemes.lily = lily;

import greg from './greg';
allThemes.greg = greg;

import clikData from './clikdata';
allThemes.clikData = clikData;

import theme1 from './theme1';
allThemes.theme1 = theme1;

import testtheme from './testtheme';
allThemes.testtheme = testtheme;

import leverage10 from './leverage10';
allThemes.leverage10 = leverage10;

import qball from './qball';
allThemes.qball = qball;

import cbslocal from './cbslocal';
allThemes.cbslocal = cbslocal;

import cumulus from './cumulus';
allThemes.cumulus = cumulus;

import cunninghambroadcasting from './cunninghambroadcasting';
allThemes.cunninghambroadcasting = cunninghambroadcasting;

import estrella from './estrella';
allThemes.estrella = estrella;

import euclid from './euclid';
allThemes.euclid = euclid;

import marqueesportsnetwork from './marquee';
allThemes.marqueesportsnetwork = marqueesportsnetwork;

import nulogicmarketing from './nulogic';
allThemes.nulogicmarketing = nulogicmarketing;

import orangetheory from './orangetheory';
allThemes.orangetheory = orangetheory;

import univision from './univision';
allThemes.univision = univision;

import yolofcu from './yolofcu';
allThemes.yolofcu = yolofcu;

import sacramento from './sacramento';
allThemes.sacramento = sacramento;

import silva from './silva';
allThemes.silva = silva;

import ricochetmediagroup from './ricochetmediagroup';
allThemes.ricochetmediagroup = ricochetmediagroup;

import minedice from './minedice';
allThemes.minedice = minedice;

import ohana from './ohana';
allThemes.ohana = ohana;

import themediabeast from './themediabeast';
allThemes.themediabeast = themediabeast;

import thexcitegroup from './thexcitegroup';
allThemes.thexcitegroup = thexcitegroup;

import dcadcpr from './dcadcpr';
allThemes.dcadcpr = dcadcpr;

import fms from './fms';
allThemes.fms = fms;

import marketing360 from './marketing360';
allThemes.marketing360 = marketing360;

import getthevantage from './getthevantage';
allThemes.getthevantage = getthevantage;

import tysonfoods from './tysonfoods';
allThemes.tysonfoods = tysonfoods;

import grahamnmedia from './graham_media';
allThemes.grahamnmedia = grahamnmedia;

import odney from './odney';
allThemes.odney = odney;

import columbiamarketinggroup from './columbiamarketinggroup';
allThemes.columbiamarketinggroup = columbiamarketinggroup;

import optimum from './optimum';
allThemes.optimum = optimum;

import wfmj from './wfmj';
allThemes.wfmj = wfmj;

import advancedlocal from './advancedlocal';
allThemes.advancedlocal = advancedlocal;

import todaymedia from './todaymedia';
allThemes.todaymedia = todaymedia;

import mediamcc from './mediamcc';
allThemes.mediamcc = mediamcc;

import zenon from './zenon';
allThemes.zenon = zenon;

import absolutelydominate from './absolutelydominate';
allThemes.absolutelydominate = absolutelydominate;

import lamarkmedia from './lamarkmedia';
allThemes.lamarkmedia = lamarkmedia;

import compulsemah from './compulsemah';
allThemes.compulsemah = compulsemah;

import boommedia from './boommedia';
allThemes.boommedia = boommedia;

import sasshoes from './sasshoes';
allThemes.sasshoes = sasshoes;

import burnettmediagroup from './burnettmediagroup';
allThemes.burnettmediagroup = burnettmediagroup;

import madisontaylormarketing from './madisontaylormarketing';
allThemes.madisontaylormarketing = madisontaylormarketing;

import adxdigitalmarketing from './adxdigitalmarketing';
allThemes.adxdigitalmarketing = adxdigitalmarketing;

import leadnavigators from './leadnavigators';
allThemes.leadnavigators = leadnavigators;

import gforceglobalmarketing from './gforceglobalmarketing';
allThemes.gforceglobalmarketing = gforceglobalmarketing;

import lotuscorp from './lotuscorp';
allThemes.lotuscorp = lotuscorp;

import nyinterconnect from './nyinterconnect';
allThemes.nyinterconnect = nyinterconnect;

import alphagraphics from './alphagraphics';
allThemes.alphagraphics = alphagraphics;

import holdsworthnicholas from './holdsworthnicholas';
allThemes.holdsworthnicholas = holdsworthnicholas;

import schewels from './schewels';
allThemes.schewels = schewels;

import bigtimeadvertising from './bigtimeadvertising';
allThemes.bigtimeadvertising = bigtimeadvertising;

import denovo from './denovo';
allThemes.denovo = denovo;

import streamlegalmedia from './streamlegalmedia';
allThemes.streamlegalmedia = streamlegalmedia;

import paradigmid from './paradigmid';
allThemes.paradigmid = paradigmid;

import synergimedia from './synergimedia';
allThemes.synergimedia = synergimedia;

import appkudos from './appkudos';
allThemes.appkudos = appkudos;

import sumnergroup from './sumnergroup';
allThemes.sumnergroup = sumnergroup;

import nexstar from './nexstar';
allThemes.nexstar = nexstar;

// Disabled because of DASH-3934
// import chrispymedia from './chrispymedia';
// allThemes.chrispymedia = chrispymedia;

import onemediaconnect from './onemediaconnect';
allThemes.onemediaconnect = onemediaconnect;

import craftedmediagroup from './craftedmediagroup';
allThemes.craftedmediagroup = craftedmediagroup;

import targetdigital from './targetdigital';
allThemes.targetdigital = targetdigital;

import starfishseo from './starfishseo';
allThemes.starfishseo = starfishseo;

import spotonmediasolutions from './spotonmediasolutions';
allThemes.spotonmediasolutions = spotonmediasolutions;

import k31media from './k31media';
allThemes.k31media = k31media;

import hummingbirdott from './hummingbirdott';
allThemes.hummingbirdott = hummingbirdott;

import reachthru from './reachthru';
allThemes.reachthru = reachthru;

import marshrabbit from './marshrabbit';
allThemes.marshrabbit = marshrabbit;

import c3mediagroup from './c3mediagroup';
allThemes.c3mediagroup = c3mediagroup;

import n2publishing from './n2publishing';
allThemes.n2publishing = n2publishing;

import kaplanads from './kaplanads';
allThemes.kaplanads = kaplanads;

import bespokemarketingsolutions from './bespokemarketingsolutions';
allThemes.bespokemarketingsolutions = bespokemarketingsolutions;

import beasleydigitalphilly from './beasleydigitalphilly';
allThemes.beasleydigitalphilly = beasleydigitalphilly;

import smithgroup from './smithgroup';
allThemes.smithgroup = smithgroup;

import johnsonmediagroup from './johnsonmediagroup';
allThemes.johnsonmediagroup = johnsonmediagroup;

import hyportdigital from './hyportdigital';
allThemes.hyportdigital = hyportdigital;

import coxmediagroup from './coxmediagroup';
allThemes.coxmediagroup = coxmediagroup;

import tagteam from './tagteam';
allThemes.tagteam = tagteam;

import wellroundedstudios from './wellroundedstudios';
allThemes.wellroundedstudios = wellroundedstudios;

import wgcommgroup from './wgcommgroup';
allThemes.wgcommgroup = wgcommgroup;

import creativespot from './creativespot';
allThemes.creativespot = creativespot;

import blazepartners from './blazepartners';
allThemes.blazepartners = blazepartners;

import communiquedesign from './communiquedesign';
allThemes.communiquedesign = communiquedesign;

import babyjgroup from './babyjgroup';
allThemes.babyjgroup = babyjgroup;

import thesolutionsgroup from './thesolutionsgroup';
allThemes.thesolutionsgroup = thesolutionsgroup;

import media360 from './media360';
allThemes.media360 = media360;

import bulldogcreativeserves from './bulldogcreativeserves';
allThemes.bulldogcreativeserves = bulldogcreativeserves;

import eldoradobroadcasters from './eldoradobroadcasters';
allThemes.eldoradobroadcasters = eldoradobroadcasters;

import infoscope from './infoscope';
allThemes.infoscope = infoscope;

import netaff from './netaff';
allThemes.netaff = netaff;

import homelocalservices from './homelocalservices';
allThemes.homelocalservices = homelocalservices;

import mooremedia from './mooremedia';
allThemes.mooremedia = mooremedia;

import ryanharper from './ryanharper';
allThemes.ryanharper = ryanharper;

import twooneninegroup from './twooneninegroup';
allThemes.twooneninegroup = twooneninegroup;

import spmarketingexperts from './spmarketingexperts';
allThemes.spmarketingexperts = spmarketingexperts;

import searchengingeguys from './searchengingeguys';
allThemes.searchengingeguys = searchengingeguys;

import circone from './circone';
allThemes.circone = circone;

import brkmarketing from './brkmarketing';
allThemes.brkmarketing = brkmarketing;

import jdmarkets from './jdmarkets';
allThemes.jdmarkets = jdmarkets;

import jmmediagroup from './jmmediagroup';
allThemes.jmmediagroup = jmmediagroup;

import anewmedia from './anewmedia';
allThemes.anewmedia = anewmedia;

import brickyardmarketing from './brickyardmarketing';
allThemes.brickyardmarketing = brickyardmarketing;

import cskadvertising from './cskadvertising';
allThemes.cskadvertising = cskadvertising;

import thesalvationgarden from './thesalvationgarden';
allThemes.thesalvationgarden = thesalvationgarden;

import katzmediagroup from './katzmediagroup';
allThemes.katzmediagroup = katzmediagroup;

import proventuresinc from './proventuresinc';
allThemes.proventuresinc = proventuresinc;

import bradybadvertising from './bradybadvertising';
allThemes.bradybadvertising = bradybadvertising;

import shynabledigital from './shynabledigital';
allThemes.shynabledigital = shynabledigital;

import fuzeloinc from './fuzeloinc';
allThemes.fuzeloinc = fuzeloinc;

import digthrive from './digthrive';
allThemes.digthrive = digthrive;

import localculturecreative from './localculturecreative';
allThemes.localculturecreative = localculturecreative;

import nenidigital from './nenidigital';
allThemes.nenidigital = nenidigital;

import empowerlocal from './empowerlocal';
allThemes.empowerlocal = empowerlocal;

import ethridge from './ethridge';
allThemes.ethridge = ethridge;

import daymedia from './daymedia';
allThemes.daymedia = daymedia;

import whpmfox23 from './whpmfox23';
allThemes.whpmfox23 = whpmfox23;

import silentnighttherapy from './silentnighttherapy';
allThemes.silentnighttherapy = silentnighttherapy;

import babylondentalcare from './babylondentalcare';
allThemes.babylondentalcare = babylondentalcare;

import auralabs from './auralabs';
allThemes.auralabs = auralabs;

import optabrands from './optabrands';
allThemes.optabrands = optabrands;

import travelcamp from './travelcamp';
allThemes.travelcamp = travelcamp;

import cirquedusoleil from './cirquedusoleil';
allThemes.cirquedusoleil = cirquedusoleil;

import lmdigital from './lmdigital';
allThemes.lmdigital = lmdigital;

import rcmomedia from './rcmomedia';
allThemes.rcmomedia = rcmomedia;

import mpwmarketing from './mpwmarketing';
allThemes.mpwmarketing = mpwmarketing;

import emcadvertising from './emcadvertising';
allThemes.emcadvertising = emcadvertising;

import healit from './healit';
allThemes.healit = healit;

import wellermedia from './wellermedia';
allThemes.wellermedia = wellermedia;

import lerneradvertising from './lerneradvertising';
allThemes.lerneradvertising = lerneradvertising;

import culligan from './culligan';
allThemes.culligan = culligan;

import performancedigital from './performancedigital';
allThemes.performancedigital = performancedigital;

import stonegatestudios from './stonegatestudios';
allThemes.stonegatestudios = stonegatestudios;

import galaxy110 from './galaxy110';
allThemes.galaxy110 = galaxy110;

import primemedia from './primemedia';
allThemes.primemedia = primemedia;

import strategygrowthmarketing from './strategygrowthmarketing';
allThemes.strategygrowthmarketing = strategygrowthmarketing;

import buntingroup from './buntingroup';
allThemes.buntingroup = buntingroup;

import realitymarketinggroup from './realitymarketinggroup';
allThemes.realitymarketinggroup = realitymarketinggroup;

import andresavoie from './andresavoie';
allThemes.andresavoie = andresavoie;

import rockfleet from './rockfleet';
allThemes.rockfleet = rockfleet;

import urbanone from './urbanone';
allThemes.urbanone = urbanone;

import princemediagroup from './princemediagroup';
allThemes.princemediagroup = princemediagroup;

import paradux from './paradux';
allThemes.paradux = paradux;

import requiredmarketing from './requiredmarketing';
allThemes.requiredmarketing = requiredmarketing;

import constantfrequency from './constantfrequency';
allThemes.constantfrequency = constantfrequency;

import baytobaynews from './baytobaynews';
allThemes.baytobaynews = baytobaynews;

import cliquestudios from './cliquestudios';
allThemes.cliquestudios = cliquestudios;

import terrafirmamarketing from './terrafirmamarketing';
allThemes.terrafirmamarketing = terrafirmamarketing;

import villing from './villing';
allThemes.villing = villing;

import alwaysperfect from './alwaysperfect';
allThemes.alwaysperfect = alwaysperfect;

import solsource from './solsource';
allThemes.solsource = solsource;

import globafly from './globafly';
allThemes.globafly = globafly;

import coffeeblack from './coffeeblack';
allThemes.coffeeblack = coffeeblack;

import cmg from './cmg';
allThemes.cmg = cmg;

import spokes from './spokes';
allThemes.spokes = spokes;

import dashmedia from './dashmedia';
allThemes.dashmedia = dashmedia;

import digitallymarket from './digitallymarket';
allThemes.digitallymarket = digitallymarket;

import skippingstones from './skippingstones';
allThemes.skippingstones = skippingstones;

import mediaagent from './mediaagent';
allThemes.mediaagent = mediaagent;

import ferociousdigital from './ferociousdigital';
allThemes.ferociousdigital = ferociousdigital;

import durum from './durum';
allThemes.durum = durum;

import pioneermedia from './pioneermedia';
allThemes.pioneermedia = pioneermedia;

import consultv from './consultv';
allThemes.consultv = consultv;

import mangamedia from './mangamedia';
allThemes.mangamedia = mangamedia;

import floyds99barbershop from './floyds_99_barbershop';
allThemes.floyds99barbershop = floyds99barbershop;

import transportdigitalmedia from './transportdigitalmedia';
allThemes.transportdigitalmedia = transportdigitalmedia;

import socialclimb from './socialclimb';
allThemes.socialclimb = socialclimb;

import brandendo from './brandendo';
allThemes.brandendo = brandendo;

import sneezeit from './sneezeit';
allThemes.sneezeit = sneezeit;

import kbwoods from './kbwoods';
allThemes.kbwoods = kbwoods;

import ohanamediagroup from './ohana_media_group';
allThemes.ohanamediagroup = ohanamediagroup;

import pandologic from './pandologic';
allThemes.pandologic = pandologic;

import hedyandhopp from './hedyandhopp';
allThemes.hedyandhopp = hedyandhopp;

// Save it for future
// import viamedia from './viamedia';
// allThemes.viamedia = viamedia;

import webgrowth from './webgrowth';
allThemes.webgrowth = webgrowth;

import upcounsel from './upcounsel';
allThemes.upcounsel = upcounsel;

import aisforadvertising from './aisforadvertising';
allThemes.aisforadvertising = aisforadvertising;

import myersmm from './myersmm';
allThemes.myersmm = myersmm;

import nyvtmedia from './nyvtmedia';
allThemes.nyvtmedia = nyvtmedia;

// Should always be last. Should only get used if no other theme matches
import defaultTheme from './default';
allThemes.default = defaultTheme;

import { ThemeConfig } from './variables';

// const theme = require(`./${name}`);

// This is your plugin object. It can be exported to be used anywhere.
const ThemePlugin = {
  // pass both href and hostname, in case we need some matching rule more complex than just the domain

  install(Vue, location: { href: string; hostname: string; search: string; origin: string }) {
    const params = new URLSearchParams(location.search);

    let clientOrigin = location.origin.toLowerCase();
    let clientDomain = location.hostname.toLowerCase();
    clientDomain = 'https://' + clientDomain.toLowerCase().replace(/\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.xip.io/, '');

    // There are multiple ways a user can get a url to the site where the domain doesn't match the desired theme.
    // One example is when requesting access to various auth platforms (google, facebook).
    // We only provide these systems with a small set of domains so that we don't have to validate one for every domain we use.

    const themeName = params.get('site'); // only gets set when the theme won't match the domain
    const originOverride = params.get('domainOverride');
    if (originOverride) {
      clientOrigin = originOverride;
    }

    let activeTheme; // will be used once we find a matching theme
    const allThemeNames = Object.keys(allThemes); // list of all the theme names

    if (
      themeName &&
      (location.href.toLowerCase().includes('grant-access') ||
        location.href.toLowerCase().includes('grant-google-access') ||
        location.href.toLowerCase().includes('/view/?gct'))
    ) {
      // lookup theme based on site param
      for (const name of allThemeNames) {
        const theme: ThemeConfig = allThemes[name];

        if (theme.name === themeName) {
          activeTheme = theme;
          break;
        }
      }
    } else {
      for (const name of allThemeNames) {
        // lookup theme based on url
        const theme: ThemeConfig = allThemes[name];
        const domains = Object.values(theme.domains);
        if (domains.includes(clientOrigin) || domains.includes(clientDomain)) {
          activeTheme = theme;
          break;
        }
      }
    }

    // temporary solution until whitelabeling support for C360 & complete migration
    if (clientOrigin.includes('analytics') && clientOrigin.includes('adportal.io')) {
      activeTheme = sbgTheme;
    }

    if (!activeTheme) {
      // eslint-disable-next-line no-console
      console.error({
        message: 'unable to find a matching theme',
        themeName,
        origin: clientOrigin,
        domain: clientDomain,
      });

      activeTheme = defaultTheme;
    }

    const setTheme = (val: ThemeConfig): void => {
      // TODO: figure out exports
      // file exports use the "byName" method to return the theme

      Vue.prototype.Theme = { ...val, set: setTheme };
    };

    Vue.prototype.Theme = { ...activeTheme, set: setTheme };

    Vue.prototype.Theme.light.primary = getCssVar('--c360-color-compulse');
    Vue.prototype.Theme.light.progressBarGradient = getCssVar('--c360-gradient-accent');
  },
};

export { allThemes };
export default ThemePlugin;
