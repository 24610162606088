/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './univision.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'univision',
  companyId: 'Univision',
  companyName: 'Univision',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://univision.myanalyticshub.com',
    staging: 'https://univision.staging.myanalyticshub.com',
    dev: 'https://univision.dev.myanalyticshub.com',
    local: 'https://univision.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { invert: false, file: 'univision_conexo.png', emailLogo: 'univision_conexo.png' },
  whiteToolbar: true,
  config: {
    chartColors: ['#0218bb', '#ce171f', '#42b87c', '#b524a8', '#717d80', '#9299c8'],
    pieCharts: { firstItem: 'legend' },

    header: { borderColor: '#FF0000FF' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
