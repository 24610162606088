import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { apollo } from '../../../index';
import { AccountLinkingParams, AccountLinkingResponse, UpdateAccountLinkingParams } from './../types';

export default {
  async getAccountLinking(props: AccountLinkingParams) {
    try {
      const response: ApolloQueryResult<AccountLinkingResponse> = await apollo().query({
        query: gql`
          query GetAccountLinking($params: AccountLinkingParams) {
            getAccountLinking(params: $params) {
              CallRail {
                BackfillComplete
                CompanyLinks {
                  CompanyIds
                  KeySource
                  TokenId
                }
                Enabled
              }
              FacebookAds {
                AccountIds
                BackfillComplete
                CampaignIds
                Enabled
                AdLinks {
                  CampaignIds
                  UserId
                  UserSource
                }
              }
              FacebookInsights {
                BackfillComplete
                Enabled
                PageLinks {
                  PageIds
                  UserId
                  UserSource
                }
              }
              GAM {
                Enabled
              }
              GoogleAdManager {
                AdvertiserIds
                AdvertiserLinks {
                  AccountIds
                  UserId
                  UserSource
                }
                BackfillComplete
                Enabled
              }
              GoogleAds {
                AdAccountLinks {
                  AccountIds
                  UserId
                  UserSource
                }
                BackfillComplete
                Enabled
              }
              GoogleAdsVideo {
                AdAccountLinks {
                  AccountIds
                  UserId
                  UserSource
                }
                BackfillComplete
                BackfillingAdAccountLinks {
                  AccountIds
                  UserId
                  UserSource
                }
                Enabled
              }
              GoogleAnalytics {
                AccountId
                BackfillComplete
                Enabled
                ViewId
                WebPropertyId
                GA4PropertyId
              }
              GoogleSearchConsole {
                BackfillComplete
                Enabled
                SiteLinks {
                  SiteUrl
                  UserId
                  UserSource
                }
              }
              SiteImpact {
                BackfillComplete
                CampaignLinks {
                  CampaignIds
                  CompanyIds
                  KeyId
                  KeySource
                  PageIds
                  PageLinks
                  TokenId
                }
                Enabled
              }
              id
              Version
            }
          }
        `,
        variables: {
          params: {
            ppid: props.id,
            client: props.client,
          },
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async updateAccountLinking(props: UpdateAccountLinkingParams) {
    try {
      const response: ApolloQueryResult<AccountLinkingResponse> = await apollo().mutate({
        mutation: gql`
          mutation UpdateAccountLinking($params: UpdateAccountLinkingParams) {
            updateAccountLinking(params: $params) {
              CallRail {
                BackfillComplete
                CompanyLinks {
                  CompanyIds
                  KeySource
                  TokenId
                }
                Enabled
              }
              FacebookAds {
                AccountIds
                BackfillComplete
                CampaignIds
                Enabled
                AdLinks {
                  CampaignIds
                  UserId
                  UserSource
                }
              }
              FacebookInsights {
                BackfillComplete
                Enabled
                PageLinks {
                  PageIds
                  UserId
                  UserSource
                }
              }
              GAM {
                Enabled
              }
              GoogleAdManager {
                AdvertiserIds
                AdvertiserLinks {
                  AccountIds
                  UserId
                  UserSource
                }
                BackfillComplete
                Enabled
              }
              GoogleAds {
                AdAccountLinks {
                  AccountIds
                  UserId
                  UserSource
                }
                BackfillComplete
                Enabled
              }
              GoogleAdsVideo {
                AdAccountLinks {
                  AccountIds
                  UserId
                  UserSource
                }
                BackfillComplete
                BackfillingAdAccountLinks {
                  AccountIds
                  UserId
                  UserSource
                }
                Enabled
              }
              GoogleAnalytics {
                AccountId
                BackfillComplete
                Enabled
                ViewId
                WebPropertyId
                GA4PropertyId
              }
              GoogleSearchConsole {
                BackfillComplete
                Enabled
                SiteLinks {
                  SiteUrl
                  UserId
                  UserSource
                }
              }
              SiteImpact {
                BackfillComplete
                CampaignLinks {
                  CampaignIds
                  CompanyIds
                  KeyId
                  KeySource
                  PageIds
                  PageLinks
                  TokenId
                }
                Enabled
              }
              id
              Version
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
            Version: props.Version,
            FacebookAds: props.FacebookAds,
            GoogleAds: props.GoogleAds,
            FacebookInsights: props.FacebookInsights,
            GoogleAdsVideo: props.GoogleAdsVideo,
            GoogleAnalytics: props.GoogleAnalytics,
            GoogleAdManager: props.GoogleAdManager,
            GoogleSearchConsole: props.GoogleSearchConsole,
            SiteImpact: props.SiteImpact,
            CallRail: props.CallRail,
            GAM: props.GAM,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
};
