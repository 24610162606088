export default {
  SET_SELECTED_CAMPAIGNS: 'SET_SELECTED_CAMPAIGNS',
  SET_CAMPAIGNS_SELECTION: 'SET_CAMPAIGNS_SELECTION',
  SET_CAMPAIGNTYPE_SELECTION: 'SET_CAMPAIGNTYPE_SELECTION',
  SET_CAMPAIGNS_HASFILTERED: 'SET_CAMPAIGNS_HASFILTERED',
  SET_CAMPAIGNS_HASTYPEFILTERED: 'SET_CAMPAIGNS_HASTYPEFILTERED',
  UPDATE_CAMPAIGNS: 'UPDATE_CAMPAIGNS',
  SET_FILTERS: 'SET_FILTERS',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_ALL_FILTERS: 'UPDATE_ALL_FILTERS',
  SET_LOADING: 'SET_LOADING',
  RELOAD_CAMPAIGNS: 'RELOAD_CAMPAIGNS',
  SET_TACTIC_PAGE__VIEW: 'SET_TACTIC_PAGE__VIEW',
};
