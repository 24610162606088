/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './sacramento.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'sacramento',
  companyId: 'sacramento',
  companyName: 'Sacramento Internet Marketing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://sacramentointernetmarketingagency.myanalyticshub.com',
    staging: 'https://sacramentointernetmarketingagency.staging.myanalyticshub.com',
    dev: 'https://sacramentointernetmarketingagency.dev.myanalyticshub.com',
    local: 'https://sacramentointernetmarketingagency.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'sacramento_white.png', emailLogo: 'sacramento.png' },
  config: {
    chartColors: ['#dcbd25', '#374f86', '#f7a845', '#75c8a0', '#e66b7d', '#73addf'],
    pieCharts: { firstItem: 'legend' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
