import CONSTANTS from '../constants';
import axios, { AxiosResponse } from 'axios';
import { logError } from '../../../index';

export default {
  async getSiteImpactCredential({ commit }, { id }): Promise<object> {
    try {
      commit(CONSTANTS.SET_SITEIMPACT_LOADING, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(`/api/getSiteImpactCredential/?id=${id}`);
      return req.data;
    } catch (error) {
      const errorStatus = JSON.parse(JSON.stringify(error)).status;
      if (errorStatus === 403) {
        commit(CONSTANTS.SET_SITEIMPACT_ERROR, "You don't have access");
      } else {
        commit(CONSTANTS.SET_SITEIMPACT_ERROR, error?.response?.data || error?.message);
      }
      throw logError(error, 'store.agencyadmin.getSiteImpactCredential');
    } finally {
      commit(CONSTANTS.SET_SITEIMPACT_LOADING, false);
    }
  },
  async addSiteImpactCredential({ commit }, payload): Promise<void> {
    try {
      commit(CONSTANTS.SET_SITEIMPACT_SAVING, true);
      commit(CONSTANTS.SET_SITEIMPACT_ERROR, null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/addSiteImpactCredential', payload);
      return req.data;
    } catch (error) {
      commit(CONSTANTS.SET_SITEIMPACT_ERROR, error?.response?.data || error?.message);
      throw logError(error, 'store.agencyadmin.addSiteImpactCredential');
    } finally {
      commit(CONSTANTS.SET_SITEIMPACT_SAVING, false);
    }
  },
  async editSiteImpactCredential({ commit }, payload): Promise<void> {
    try {
      commit(CONSTANTS.SET_SITEIMPACT_SAVING, true);
      commit(CONSTANTS.SET_SITEIMPACT_ERROR, null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.patch('/api/editSiteImpactCredential', payload);
      return req.data;
    } catch (error) {
      commit(CONSTANTS.SET_SITEIMPACT_ERROR, error?.response?.data || error?.message);
      throw logError(error, 'store.agencyadmin.editSiteImpactCredential');
    } finally {
      commit(CONSTANTS.SET_SITEIMPACT_SAVING, false);
    }
  },
  async removeSiteImpactCredential({ commit }, payload): Promise<void> {
    try {
      commit(CONSTANTS.SET_SITEIMPACT_LOADING, true);
      commit(CONSTANTS.SET_SITEIMPACT_ERROR, null);

      const req: AxiosResponse<any> = await axios.delete(
        `/api/removeSiteImpactCredential/${payload.id}/${payload.name}`,
      );
      return req.data;
    } catch (error) {
      commit(CONSTANTS.SET_SITEIMPACT_ERROR, error?.response?.data || error?.message);
      throw logError(error, 'store.agencyadmin.removeSiteImpactCredential');
    }
  },
};
