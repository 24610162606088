export default {
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_AGENICES: 'SET_AGENICES',
  SET_ADVERTISERS: 'SET_ADVERTISERS',
  SET_ADVERTISERS_BY_PRODUCTS: 'SET_ADVERTISERS_BY_PRODUCTS',
  SET_CAMPAIGNS: 'SET_CAMPAIGNS',
  SET_CAMPAIGNS_BY_PRODUCT: 'SET_CAMPAIGNS_BY_PRODUCT',
  SET_LAYOUTS: 'SET_LAYOUTS',
  SET_ERROR: 'SET_ERROR',
  SET_LOADING_PRODUCTS: 'SET_LOADING_PRODUCTS',
  SET_LOADING_ADVERTISERS: 'SET_LOADING_ADVERTISERS',
  SET_LOADING_CAMPAIGNS: 'SET_LOADING_CAMPAIGNS',
  SET_LOADING_LAYOUTS: 'SET_LOADING_LAYOUTS',
};
