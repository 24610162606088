import CONSTANTS from '../constants';
import { logError } from '../../../index';
import { ApolloQueryResult } from '@apollo/client/core';
import { Error, GetGoogleAdDataResponse, GoogleAdDataAccount } from './../types/google';
import { GetAccountLinkingResponse, UpdateGoogleAdsAccountsForAdvertiserResponse } from './../types';
import requests from './../requests';
import utils from '../../../../util';

const loadGoogleAdData = async ({ commit }, id: string): Promise<GoogleAdDataAccount[] | Error> => {
  if (!id) {
    commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, 'Missing client ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, null);
    commit(CONSTANTS.SET_GOOGLE_ADS_LOADING, true);
    const response: ApolloQueryResult<GetGoogleAdDataResponse> = await requests.getGoogleAdData({ id });
    if (response?.errors) {
      commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return utils.removeNullFromObjectOrArray(response.data?.getGoogleAdData?.accounts);
  } catch (exp) {
    commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.googleAdsData');
  } finally {
    commit(CONSTANTS.SET_GOOGLE_ADS_LOADING, false);
  }
};

const updateGoogleAdsAccountsForAdvertiser = async (
  { commit },
  { advertiserId, accountIds },
): Promise<GetAccountLinkingResponse | Error> => {
  commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, null); // clear any errors

  if (!advertiserId) {
    commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, 'Missing advertiser id');
    return;
  }
  if (!accountIds) {
    commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, 'Missing list of accounts');
    return;
  }
  try {
    commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, null);
    commit(CONSTANTS.SET_GOOGLE_ADS_LOADING, true);
    // join accounts id's into string.
    accountIds = accountIds.join(',');
    const response: ApolloQueryResult<UpdateGoogleAdsAccountsForAdvertiserResponse> =
      await requests.updateGoogleAdsAccountsForAdvertiser({ advertiserId, accountIds });

    if (response?.errors) {
      commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }

    return utils.removeNullFromObjectOrArray(response.data?.updateGoogleAdsAccountsForAdvertiser);
  } catch (exp) {
    commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.updateGoogleAdsAccountsForAdvertiser');
  } finally {
    commit(CONSTANTS.SET_GOOGLE_ADS_LOADING, false);
  }
};

export default {
  loadGoogleAdData,
  updateGoogleAdsAccountsForAdvertiser,
};
