import _transform from 'lodash.transform';
import _isEqual from 'lodash.isequal';
import _isObject from 'lodash.isobject';
import { format } from 'date-fns';

import { default as datesHelpers } from './datesHelpers';

function getBanners(banners) {
  return Object.keys(banners).reduce((object, item) => {
    const img = banners[item].url;
    object[banners[item].type] = banners[item];
    // fix url issues
    if (img.indexOf('10.112.0.204') > -1) {
      object[banners[item].type].url = img.replace('http://10.112.0.204', 'https://my.datasphere.com');
    }
    return object;
  }, {});
}

function difference(object, base) {
  return _transform(object, (result, value, key) => {
    if (!_isEqual(value, base[key])) {
      result[key] = _isObject(value) && _isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
}

function getUISalesProposal(allProposals, eventId, station, rep, trackId) {
  let proposal;
  let uiProposal: any = {};
  // load the sales proposal by eventid
  allProposals = Array.isArray(allProposals) ? allProposals : [];
  if (allProposals.length) {
    const match = allProposals.find(p => p.eventId === eventId && p.station === station);
    // find proposal by station that has a trackId
    const matchStation = allProposals.find(p => p.station === station && p.trackId);
    if (match) proposal = match;
    else if (matchStation) proposal = matchStation;
    else proposal = null;
  } else proposal = null;

  if (!rep && !proposal) return false; // this shouldn't happen, but if it did we wouldn't be able to create a proposal

  if (rep) {
    uiProposal = {
      eventId,
      station,
      repId: rep.Email,
      repPPid: rep.ID,
      repDSC: rep.DSC,
      actionGuarantee: true,
      contractLength: '12',
      selectedBundle: null,
      showStarterBundle: false,
      showIntermediateBundle: false,
      discountId: null,
      discountReason: null,
      trackId,
    };
  }
  Object.assign(uiProposal, proposal); // we create a base proposal. if there is an existing one, overwrite the base

  if (rep && rep.Email) uiProposal.repId = rep.Email; // update rep in case it's different
  if (rep && rep.ID) uiProposal.repPPid = rep.ID; // update rep in case it's different
  if (rep && rep.DSC) uiProposal.repDSC = rep.DSC; // update rep in case it's different
  uiProposal.actionGuarantee = true; // we are enabling these by default with no immediate plan to disable them, but maybe one day we will :)
  return uiProposal;
}

function setSalesProposal(allProposals, proposal, wrapper) {
  // get all proposals for this business
  let proposals = [];
  if (Array.isArray(allProposals)) {
    proposals = [...allProposals];
  }
  proposal.lastModified = format(new Date(), datesHelpers.formats.dsstore);
  const match = proposals.find(p => p.eventId === proposal.eventId);
  // see if there's a matching proposal for this eventid
  if (match) {
    proposals = proposals.map(p => {
      if (p.eventId === proposal.eventId) {
        p = proposal; // update the proposal
      }
      return p;
    });
  } else proposals.push(proposal); // add a new proposal
  if (wrapper) return { SalesProposal: proposals };
  return proposals;
}

function kilometersToMiles(kilometers) {
  return kilometers ? kilometers * 0.621371 : null;
}

export default {
  difference,
  getBanners,
  getUISalesProposal,
  setSalesProposal,
  kilometersToMiles,
};
