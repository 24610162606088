import mutations from './mutations';
import actions from './actions';

const state = {
  error: null,
  groupedTopTactics: null,
  topTactics: null,
  summaryData: null,
  dimensions: {
    mapHeight: null,
    summaryHeight: null,
    summaryTopPosition: null,
  },
  map: {
    hasData: null,
    ready: null,
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
