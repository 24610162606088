import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { apollo } from '../../../index';
import {
  FacebookDataParams,
  FacebookCredentialParams,
  GetFacebookDataResponse,
  GetFacebookCredentialConnectionsResponse,
} from './../types/facebook';

export default {
  async getFacebookData(props: FacebookDataParams) {
    try {
      const response: ApolloQueryResult<GetFacebookDataResponse> = await apollo().query({
        query: gql`
          query GetFacebookData($params: FacebookDataParams) {
            getFacebookData(params: $params) {
              Accounts {
                AccountID
                AccountName
                Campaigns {
                  CampaignID
                  CampaignName
                }
              }
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
            ppid: props.ppid,
          },
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getFacebookCredentialConnections(props: FacebookCredentialParams) {
    try {
      const response: ApolloQueryResult<GetFacebookCredentialConnectionsResponse> = await apollo().query({
        query: gql`
          query GetFacebookCredentialConnections($params: FacebookCredentialParams) {
            getFacebookCredentialConnections(params: $params) {
              AgencyOwned {
                Connected
                FirstName
                LastName
                Name
                UserId
                UserSource
                RerequestPermission
                RequiredPermissions
                LinkedTypes {
                  ConfigurationError
                  Enabled
                  Name
                  Type
                }
                Permissions {
                  Permission
                  Granted
                  Required
                }
              }
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
            isAgency: props.isAgency,
            allPossible: props.allPossible,
          },
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getFacebookPages(props: FacebookDataParams) {
    try {
      const response: ApolloQueryResult<GetFacebookDataResponse> = await apollo().query({
        query: gql`
          query GetFacebookPages($params: FacebookDataParams) {
            getFacebookPages(params: $params) {
              Agency {
                FirstName
                LastName
                Name
                Properties {
                  id
                  name
                }
                UserId
                UserSource
              }
              pstate
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
          },
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
};
