/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './craftedmediagroup.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'craftedmediagroup',
  companyId: 'craftedmediagroup',
  companyName: 'Crafted Media Group',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://craftedmediagroup.myanalyticshub.com',
    staging: 'https://craftedmediagroup.staging.myanalyticshub.com',
    dev: 'https://craftedmediagroup.dev.myanalyticshub.com',
    local: 'https://craftedmediagroup.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'craftedmediagroup.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#03a9f4', '#ff9800', '#8c239f', '#fd558e', '#ffdb07', '#134e93'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
