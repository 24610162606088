
import Vue from 'vue';
import utils from '../../../util';

export default {
  name: 'headerLogoC360',
  props: ['fontSizeType', 'themeOverride', 'isLogin', 'isExporting'],
  data(): {
    logo: {
      src: string;
      companyName: string;
    };
  } {
    return {
      logo: {
        src: '',
        companyName: '',
      },
    };
  },
  created(): void {
    this.getLogo();
  },
  methods: {
    async getLogo(): Promise<void> {
      // allow an override to grab logos that aren't part of the assigned theme
      const logo = await utils.getLogo(this.themeOverride ? this.themeOverride : this.Theme);
      if (logo) {
        this.logo.src = logo.img?.src;
        this.logo.companyName = logo.name;
      }
    },
  },
  computed: {
    imageLink(): string {
      return Vue.prototype.$c360.logo;
    },
  },
};
