/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './dealeralchemist.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'dealeralchemist',
  companyId: 'Dealer_Alchemist',
  companyName: 'Dealer Alchemist',
  rootDomain: 'sbganalytics.com',
  parentAgency: 'none',
  domains: {
    production: 'https://dealeralchemist.myanalyticshub.com',
    staging: 'https://staging.dealeralchemist.myanalyticshub.com',
    dev: 'https://dev.dealeralchemist.myanalyticshub.com',
    local: 'https://dealeralchemist.localhost:8080',
  },
  ...setVariableColors(variables),
  layouts: ['Dealer Alchemist'],
  config: {
    chartBorder: 'line',
    chartColors: ['#a8dadc', '#f78c00', '#d62828', '#00294a', '#ffc000', '#eae2b7'],
    pieCharts: { firstItem: 'legend' },

    maps: {
      themes: ['dealerAlchemist'],
    },
    shareDate: false,
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
