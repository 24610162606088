export default {
  SET_TOP_TACTICS: 'SET_TOP_TACTICS',
  SET_GROUPED_TOP_TACTICS: 'SET_GROUPED_TOP_TACTICS',
  SET_SUMMARY_DATA: 'SET_SUMMARY_DATA',
  SET_SUMMARY_HEIGHT: 'SET_SUMMARY_HEIGHT',
  SET_MAP_HEIGHT: 'SET_MAP_HEIGHT',
  SET_SUMMARY_TOP_POSITION: 'SET_SUMMARY_TOP_POSITION',
  SET_IS_MAP_READY: 'SET_IS_MAP_READY',
  SET_MAP_HAS_DATA: 'SET_MAP_HAS_DATA',
};
