/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './aisforadvertising.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'aisforadvertising',
  companyId: 'A_Is_For_Advertising',
  companyName: 'A is for Advertising',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://aisforadvertising.myanalyticshub.com',
    staging: 'https://aisforadvertising.staging.myanalyticshub.com',
    dev: 'https://aisforadvertising.dev.myanalyticshub.com',
    local: 'https://aisforadvertising.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'aisforadvertising.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#f7adce', '#7067cf', '#1f7a8c', '#7b287d', '#274690', '#66ced6'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
