export default {
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_AGENCIES: 'SET_AGENCIES',
  SET_FACEBOOK_ERROR: 'FACEBOOK_ERROR',
  SET_FACEBOOK_LOADING: 'FACEBOOK_LOADING',
  SET_NEW_FACEBOOK_ERROR: 'FACEBOOK_ERROR',
  SET_NEW_FACEBOOK_LOADING: 'FACEBOOK_LOADING',
  SET_SITEIMPACT_LOADING: 'SITEIMPACT_LOADING',
  SET_SITEIMPACT_SAVING: 'SITEIMPACT_SAVING',
  SET_SITEIMPACT_ERROR: 'SITEIMPACT_ERROR',
  SET_AGENCY: 'SET_AGENCY',
};
