import axios, { AxiosResponse } from 'axios';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  Error,
  GetFacebookDataResponse,
  FacebookDataResponse,
  GetFacebookCredentialConnectionsResponse,
  FacebookCredentialConnectionsResponse,
  GetFacebookPageResponse,
  FacebookPageResponse,
} from './../types/facebook';
import requests from './../requests';
import CONSTANTS from '../constants';
import { logError } from '../../../index';
import utils from '../../../../util';

const getFacebookData = async ({ commit }, payload): Promise<FacebookDataResponse | Error> => {
  if (!payload.id) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, 'Missing client id');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
    const response: ApolloQueryResult<GetFacebookDataResponse> = await requests.getFacebookData(payload);
    if (response?.errors) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return utils.removeNullFromObjectOrArray(response.data.getFacebookData);
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.getFacebookData');
  }
};

const getFacebookCredentialConnections = async (
  { commit },
  payload,
): Promise<FacebookCredentialConnectionsResponse | Error> => {
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
    const response: ApolloQueryResult<GetFacebookCredentialConnectionsResponse> =
      await requests.getFacebookCredentialConnections(payload);
    if (response?.errors) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return utils.removeAllTypenames(response.data.getFacebookCredentialConnections);
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.getFacebookCredentialConnections');
  }
};

const getFacebookPageData = async ({ commit }, id): Promise<FacebookPageResponse | Error> => {
  if (!id) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, 'Missing client id');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
    const response: ApolloQueryResult<GetFacebookPageResponse> = await requests.getFacebookPages({ id });
    if (response?.errors) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return utils.removeAllTypenames(response.data.getFacebookPages);
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.getFacebookCredentialConnections');
  }
};

const getFacebookAvailableCampaigns = async ({ commit }, payload): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR, null);
    commit(CONSTANTS.SET_FACEBOOK_LOADING, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.get(`/api/getFacebookAvailableCampaigns/${payload.advertiserId}`);
    if (req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR, req.data.error);
      return;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.getFacebookAvailableCampaigns');
    return;
  } finally {
    commit(CONSTANTS.SET_FACEBOOK_LOADING, false);
  }
};

const getFacebookConnector = async ({ commit }, payload): Promise<void> => {
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR, null);
    commit(CONSTANTS.SET_FACEBOOK_LOADING, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.get(`/api/getFacebookConnector/${payload.advertiserId}`);
    if (req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR, req.data.error);
      return;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.getFacebookConnector');
  } finally {
    commit(CONSTANTS.SET_FACEBOOK_LOADING, false);
  }
};

const saveFacebookConnector = async ({ commit }, payload): Promise<any> => {
  commit(CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR, null);
  try {
    const flatCampaignList = payload.connector.flatMap(fbAccountData =>
      fbAccountData.accounts.flatMap(accountData =>
        accountData.campaigns.map(campaignData => ({
          user_id: fbAccountData.facebook_account_id,
          campaign_id: campaignData.campaign_id,
        })),
      ),
    );
    const req: AxiosResponse<any> = await axios.post(`/api/saveFacebookConnector/${payload.advertiserId}`, {
      campaigns: flatCampaignList,
    });
    if (req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR, req.data.error);
      return;
    }
    return req?.data;
  } catch (exp) {
    const error = exp?.response?.data || exp?.message;
    commit(CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR, error);
  }
};

export default {
  getFacebookCredentialConnections,
  getFacebookData,
  getFacebookPageData,
  getFacebookAvailableCampaigns,
  getFacebookConnector,
  saveFacebookConnector,
};
