import axios, { AxiosResponse } from 'axios';
import { GetReportPayload } from './types';

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async searchReports(url: string): Promise<AxiosResponse<any>> {
    return await axios.get(url);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getReport(url: string, body: GetReportPayload): Promise<AxiosResponse<any>> {
    return await axios.post(url, body);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async createReport(url: string, body: object): Promise<AxiosResponse<any>> {
    return await axios.post(url, body);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async updateReport(url: string, body: object): Promise<AxiosResponse<any>> {
    return await axios.post(url, body);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async deleteReport(url: string, body: object): Promise<AxiosResponse<any>> {
    return await axios.post(url, body);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getProductList(url: string): Promise<AxiosResponse<any>> {
    return await axios.post(url);
  },
};
