import gql from 'graphql-tag';
import requests from './../requests';
import { logError, apollo } from '../../../index';
import CONSTANTS from './../constants';
import {
  Error,
  SiteImpactConnections,
  GetSiteImpactConnectionsResponse,
  CommonLinkingParams,
  SiteImpactCampaignsResponse,
  GetSiteImpactCampaignsResponse,
  SiteImpactParams,
  SiteImpact,
  SiteImpactConnectorResponse,
  AddSiteImpactConnectorResponse,
} from '../types/siteimpact';
import { ApolloQueryResult } from '@apollo/client/core';
import utils from '../../../../util';

export default {
  async addSiteImpactConnector(
    { commit },
    params: {
      id: string;
      campaignIds: string[];
    },
  ): Promise<AddSiteImpactConnectorResponse | Error> {
    if (!params.id || !Array.isArray(params.campaignIds)) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, 'Missing ID');
      return;
    }
    try {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
      commit(CONSTANTS.SET_LOADING, true);
      const response = await apollo().mutate({
        mutation: gql`
          mutation AddSiteImpactConnector($params: AddSiteImpactConnectorParams) {
            addSiteImpactConnector(params: $params) {
              connectors {
                created
                deleted
              }
              advertiserId
            }
          }
        `,
        variables: {
          params: params,
        },
        fetchPolicy: 'no-cache',
      });
      return response.data.addSiteImpactConnector;
    } catch (exp) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
      throw logError(exp, 'store.siteimpact.addSiteImpactConnector');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async getSiteImpact({ commit }, params: SiteImpactParams): Promise<SiteImpact | Error> {
    try {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
      commit(CONSTANTS.SET_LOADING, true);
      const response = await apollo().query({
        query: gql`
          query GetSiteImpact($params: SiteImpactParams) {
            getSiteImpact(params: $params) {
              data {
                campaigns {
                  campaignId
                  campaignName
                  fromLine
                  subjectLine
                }
                apiKey
                accountName
              }
            }
          }
        `,
        variables: {
          params: params,
        },
        fetchPolicy: 'no-cache',
      });
      return response.data.getSiteImpact.data;
    } catch (exp) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
      throw logError(exp, 'store.siteimpact.getConnections');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async getSiteImpactConnector({ commit }, params: { id: string }): Promise<SiteImpactConnectorResponse | Error> {
    try {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
      commit(CONSTANTS.SET_LOADING, true);
      const response = await apollo().query({
        query: gql`
          query GetSiteImpactConnector($params: SiteImpactConnectorParams) {
            getSiteImpactConnector(params: $params) {
              data {
                id
                name
                accountName
                enabled
                connection_date
              }
            }
          }
        `,
        variables: {
          params: params,
        },
        fetchPolicy: 'no-cache',
      });
      return response.data.getSiteImpactConnector.data;
    } catch (exp) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
      throw logError(exp, 'store.siteimpact.getConnections');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async getSiteImpactConnections({ commit }, payload: CommonLinkingParams): Promise<SiteImpactConnections | Error> {
    try {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
      commit(CONSTANTS.SET_LOADING, true);
      const response: ApolloQueryResult<GetSiteImpactConnectionsResponse> = await requests.getConnections(payload);
      if (response?.errors) {
        commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
        return { message: response.errors[0]?.message };
      }
      return utils.removeAllTypenames(response.data?.getSiteImpactConnections);
    } catch (exp) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
      throw logError(exp, 'store.siteimpact.getConnections');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async getSiteImpactCampaigns({ commit }, payload: CommonLinkingParams): Promise<SiteImpactCampaignsResponse | Error> {
    try {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
      commit(CONSTANTS.SET_LOADING, true);
      const response: ApolloQueryResult<GetSiteImpactCampaignsResponse> = await requests.getCampaigns(payload);
      if (response?.errors) {
        commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
        return { message: response.errors[0]?.message };
      }
      const cleanedResponse = utils.removeAllTypenames(response.data?.getSiteImpactCampaigns);
      return cleanedResponse?.Campaigns;
    } catch (exp) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
      throw logError(exp, 'store.siteimpact.getCampaigns');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
};
