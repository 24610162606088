/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './leverage10.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'leverage10',
  companyId: 'Leverage_10',
  companyName: 'Leverage10',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://leverage10.myanalyticshub.com',
    staging: 'https://leverage10.staging.myanalyticshub.com',
    dev: 'https://leverage10.dev.myanalyticshub.com',
    local: 'https://leverage10.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { invert: false, file: 'leverage10_white.png', emailLogo: 'leverage10_color.png' },
  config: {
    chartBorder: 'line',
    chartColors: ['#0099da', '#ee3124', '#2cb34a', '#f5a630', '#9e9e9e', '#2b2c2d'],
    pieCharts: {
      stacked: false,
      inlineIcon: true,
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
