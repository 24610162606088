import mutations from './mutations';
import actions from './actions';
import { StateType } from './types';

const state: StateType = {
  agencies: null,
  advertisers: null,
  advertisersByProduct: null,
  products: null,
  campaigns: null,
  campaignsByProduct: null,
  layouts: null,
  error: null,
  loadingAdvertisers: false,
  loadingCampaigns: false,
  loadingProducts: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
