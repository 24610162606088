let root = '/';

if (typeof window !== 'undefined') {
  root = '/'; // what do we want the browser to see here?
} else if (typeof process !== 'undefined') {
  const p: any = process as any;
  if (p && p.cwd) {
    root = p.cwd();
    const i = root.indexOf('packages');
    if (i !== -1) {
      root = root.substr(0, i);
    }
  }
}
const tmp = root;
export default tmp;
