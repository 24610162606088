/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './socialclimb.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'socialclimb',
  companyId: 'Social_Climb',
  companyName: 'SocialClimb',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'ConsulTV',
  domains: {
    production: 'https://socialclimb.myanalyticshub.com',
    staging: 'https://socialclimb.staging.myanalyticshub.com',
    dev: 'https://socialclimb.dev.myanalyticshub.com',
    local: 'https://socialclimb.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: {
    file: 'socialclimb.png',
    emailLogo: '',
  },
  whiteToolbar: true,
  config: {
    chartColors: ['#38618c', '#7d5ba6', '#ff8552', '#bf1363', '#26a96c', '#f6f740'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
