/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './clikdata.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig, getDMAGradient } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'clikdata',
  companyId: 'ClikData',
  companyName: 'CLIKdata',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://clikdata.myanalyticshub.com',
    staging: 'https://staging.clikdata.myanalyticshub.com',
    dev: 'https://dev.clikdata.myanalyticshub.com',
    local: 'https://clikdata.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'clikdata_white.png', large: true, emailLogo: 'clikdata_color.png' },
  config: {
    chartColors: ['#e52c25', '#3178c5', '#ffb486', '#8a8a9e', '#292929', '#a41813'],
    pieCharts: {
      stacked: false,
      inlineIcon: true,
    },

    maps: {
      themes: ['clikData'],
    },
  },
  polygons: {
    light: {
      dmaZip: { fillOpacity: 0.4, color: '#999999', fillColor: '#E52C25', weight: 1 },
      zip: { fillOpacity: 0.6, color: '#999999', fillColor: '#E52C25', weight: 0.5 },
      dmaGradient: getDMAGradient('#E52C25'),
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
