import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  [CONSTANTS.SET_DASHBOARD_STATUS](state: StateType, status: object | null): void {
    Vue.set(state, 'report', status);
  },
  [CONSTANTS.SET_DASHBOARD_STATUS_ERROR](state: StateType, error: object | null): void {
    Vue.set(state, 'error', error);
  },
  [CONSTANTS.SET_DASHBOARD_STATUS_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'loading', loading);
  },
  [CONSTANTS.SET_DASHBOARD_STATUS_DISMISS](state: StateType, dismiss: object | null): void {
    Vue.set(state, 'dismiss', dismiss);
  },
};
