/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './ohana.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'ohana',
  companyId: 'ohana',
  companyName: 'Ohana Digital Services',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://ohanadigitalservices.myanalyticshub.com',
    staging: 'https://ohanadigitalservices.staging.myanalyticshub.com',
    dev: 'https://ohanadigitalservices.dev.myanalyticshub.com',
    local: 'https://ohanadigitalservices.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'ohana_white.png', emailLogo: 'ohana.png' },
  config: {
    chartColors: ['#00a1e1', '#1e25ac', '#ed7a0d', '#ffe63f', '#45bc57', '#e76060'],
    pieCharts: { firstItem: 'legend' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
