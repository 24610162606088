import { ApolloQueryResult } from '@apollo/client/core';
import { logError } from '../../index';
import requests from './requests';
import CONSTANTS from './constants';
import {
  AdvertiserResponse,
  CampaignResponse,
  ProductsResponse,
  CampaignByProductResponse,
  AdvertisersByProductResponse,
  AgenciesResponse,
  Error,
} from './types';
import utils from '../../../util';
import axios, { AxiosResponse } from 'axios';
import { session_id } from '../../../main';

export default {
  reset({ commit }): void {
    commit(CONSTANTS.SET_CAMPAIGNS, null);
    commit(CONSTANTS.SET_PRODUCTS, null);
    commit(CONSTANTS.SET_ADVERTISERS_BY_PRODUCTS, null);
    commit(CONSTANTS.SET_CAMPAIGNS_BY_PRODUCT, null);
    commit(CONSTANTS.SET_ERROR, null);
  },
  async getProductsForAdvertiser({ commit }, advertiserId: string): Promise<ProductsResponse | Error> {
    try {
      commit(CONSTANTS.SET_LOADING_PRODUCTS, true);
      const response: ApolloQueryResult<ProductsResponse> = await requests.getProductsForAdvertiser(advertiserId);
      if (response?.errors) {
        commit(CONSTANTS.SET_ERROR, response.errors[0]?.message);
        return { message: response.errors[0]?.message };
      }
      const data = response.data.getProductsForAdvertiser.map(d => utils.removeAllTypenames(d));
      commit(CONSTANTS.SET_PRODUCTS, data);
    } catch (error) {
      commit(CONSTANTS.SET_ERROR, error.message);
      logError('getProductsForAdvertiser', error.message);
    } finally {
      commit(CONSTANTS.SET_LOADING_PRODUCTS, false);
    }
  },
  async getAdvertisersByProduct({ commit }, params): Promise<AdvertisersByProductResponse | Error> {
    try {
      commit(CONSTANTS.SET_LOADING_ADVERTISERS, true);
      const limit = params.limit && params.limit !== '' ? `?limit=${params.limit}` : '?limit=100';
      const name =
        params.advertiserName && params.advertiserName !== ''
          ? `&name=${encodeURIComponent(params.advertiserName)}`
          : '';
      const offset = params.offset && params.offset !== '' ? `&offset=${params.offset}` : '';
      const partners = params.agency && params.agency !== '' ? `&partners=${encodeURIComponent(params.agency)}` : '';
      const product =
        params.productName && params.productName !== '' ? `&product=${encodeURIComponent(params.productName)}` : '';
      const sortby = params.sortby && params.sortby !== '' ? `&sortby=${params.sortby}` : '';
      const sortdesc = params.sortdesc && params.sortdesc !== '' ? `&sortDesc=${params.sortdesc}` : '';
      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await axios.get(`/api/linearAdminClientsSearch${limit}${name}${product}${partners}${sortby}${sortdesc}${offset}`, { headers });
      if (response.data.error) {
        commit(CONSTANTS.SET_ERROR, response.data.error);
        return { message: response.data.error };
      }
      commit(CONSTANTS.SET_ADVERTISERS_BY_PRODUCTS, response.data.advertisers);
    } catch (error) {
      commit(CONSTANTS.SET_ERROR, error.message);
      logError('getAdvertisersByProduct', error.message);
    } finally {
      commit(CONSTANTS.SET_LOADING_ADVERTISERS, false);
    }
  },
  async getCampaigns({ commit }, params): Promise<CampaignResponse | Error> {
    commit(CONSTANTS.SET_ERROR, null);
    try {
      commit(CONSTANTS.SET_LOADING_CAMPAIGNS, true);
      // Define your query parameters as an object
      const queryParams = {
        limit: params.limit && params.limit !== '' ? params.limit : 100,
        campaignName: params.campaignName && params.campaignName !== '' ? params.campaignName : '',
        advertiserName: params.advertiserName && params.advertiserName !== '' ? params.advertiserName : '',
        offset: params.offset && params.offset !== '' ? params.offset : 0,
        partners: params.agencyName && params.agencyName !== '' ? params.agencyName : '',
        product: params.product && params.product !== '' ? params.product : '',
        sortby: params.sortby && params.sortby !== '' ? params.sortby : '',
        sortDesc: params.sortdesc && params.sortdesc !== '' ? params.sortdesc : true,
        advertiserId: params?.advertiserId || '',
      };
      // Use the `URLSearchParams` constructor to convert the object to query parameters
      const paramsString = new URLSearchParams(queryParams).toString();
      // Define the API endpoint URL
      const apiUrl = `/api/campaign/search?${paramsString}`;
      const response: AxiosResponse<any> = await axios.get(apiUrl);
      if (response.data.error) {
        commit(CONSTANTS.SET_ERROR, response.data.error);
        return { message: response.data.error };
      }
      commit(CONSTANTS.SET_CAMPAIGNS, response.data.campaigns);
    } catch (error) {
      commit(CONSTANTS.SET_ERROR, error.message);
      logError('getCampaigns', error.message);
    } finally {
      commit(CONSTANTS.SET_LOADING_CAMPAIGNS, false);
    }
  },
  async getAgencies({ commit }): Promise<AgenciesResponse | Error> {
    try {
      commit(CONSTANTS.SET_LOADING_ADVERTISERS, true);
      const response: ApolloQueryResult<AgenciesResponse> = await requests.getAgencies();
      if (response?.errors) {
        commit(CONSTANTS.SET_ERROR, response.errors[0]?.message);
        return { message: response.errors[0]?.message };
      }
      commit(CONSTANTS.SET_AGENICES, response.data.getAgencies);
      return response.data;
    } catch (error) {
      commit(CONSTANTS.SET_ERROR, error.message);
      logError('getAgencies', error.message);
    } finally {
      commit(CONSTANTS.SET_LOADING_ADVERTISERS, false);
    }
  },
};
