/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './ricochetmediagroup.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'ricochetmediagroup',
  companyId: 'ricochetmediagroup',
  companyName: 'Ricochet Media Group',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://ricochetmediagroup.myanalyticshub.com',
    staging: 'https://ricochetmediagroup.staging.myanalyticshub.com',
    dev: 'https://ricochetmediagroup.dev.myanalyticshub.com',
    local: 'https://ricochetmediagroup.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'ricochetmediagroup_white.png', emailLogo: 'ricochetmediagroup_color.png' },
  config: {
    chartColors: ['#ee4423', '#fb8c00', '#eed234', '#0285aa', '#20485a', '#939393'],
    pieCharts: { firstItem: 'legend' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
