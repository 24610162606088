import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  // Errors
  [CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_CLIENTS_ERROR](state: StateType, error): void {
    Vue.set(state, 'impressionsByClientsError', error);
  },
  [CONSTANTS.SET_PRIMARY_IMPRESSIONS_BY_TACTICS_ERROR](state: StateType, error): void {
    Vue.set(state, 'impressionsByTacticError', error);
  },
  [CONSTANTS.SET_PRIMARY_METRICS_ERROR](state: StateType, error): void {
    Vue.set(state, 'metricsError', error);
  },
  [CONSTANTS.SET_FILTER_ERROR](state, error) {
    Vue.set(state, 'filterError', error);
  },
  [CONSTANTS.SET_ENDING_ORDERS_ERROR](state, error) {
    Vue.set(state, 'endingOrdersError', error);
  },
  [CONSTANTS.SET_PACING_ERROR](state, error) {
    Vue.set(state, 'pacingError', error);
  },
  // Caches
  [CONSTANTS.SET_ACTIVE_ORDERS_CACHE](state, { daterange, data }) {
    Vue.set(state?.activeOrdersCache, daterange, data);
  },
  [CONSTANTS.SET_IMPRESSIONS_CACHE](state, { daterange, data }) {
    Vue.set(state?.impressionsCache, daterange, data);
  },
  [CONSTANTS.SET_ACTIVE_CLIENTS_CACHE](state, { daterange, data }) {
    Vue.set(state?.activeClientsCache, daterange, data);
  },
  [CONSTANTS.SET_ACTIVE_CAMPAIGNS_CACHE](state, { daterange, data }) {
    Vue.set(state?.activeCampaignsCache, daterange, data);
  },
  // Loading flags
  [CONSTANTS.SET_ACTIVE_ORDERS_PROMISE](state, promise) {
    Vue.set(state, 'activeOrdersPromise', promise);
  },
  [CONSTANTS.SET_IMPRESSIONS_PROMISE](state, promise) {
    Vue.set(state, 'impressionsPromise', promise);
  },
  [CONSTANTS.SET_ACTIVE_CLIENTS_PROMISE](state, promise) {
    Vue.set(state, 'activeClientsPromise', promise);
  },
  [CONSTANTS.SET_ACTIVE_CAMPAIGNS_PROMISE](state, promise) {
    Vue.set(state, 'activeCampaignsPromise', promise);
  },
};
