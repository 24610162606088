/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './streamlegalmedia.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'streamlegalmedia',
  companyId: 'Stream_Legal_Media',
  companyName: 'Stream Legal Media',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://streamlegalmedia.myanalyticshub.com',
    staging: 'https://streamlegalmedia.staging.myanalyticshub.com',
    dev: 'https://streamlegalmedia.dev.myanalyticshub.com',
    local: 'https://streamlegalmedia.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'streamlegalmedia.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#224f78', '#61b79a', '#daee8d', '#7f7f7f', '#f65e29', '#ffdb07'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
