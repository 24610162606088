/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './myersmm.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'myersmm',
  companyId: 'Myers_Marketing_Management',
  companyName: 'Myers Marketing Management',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://myersmm.myanalyticshub.com',
    staging: 'https://myersmm.staging.myanalyticshub.com',
    dev: 'https://myersmm.dev.myanalyticshub.com',
    local: 'https://myersmm.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'myersmm.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#0b225f', '#3e94f3', '#44bba4', '#e94f37', '#ff8a5b', '#abd2fa'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
