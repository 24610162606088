import gql from 'graphql-tag';

const SECTION_INFO = gql`
  fragment SectionInfo on Section {
    id
    enableCampaignSelection
    fileNameTemplate
    pdfExport
    pptExport
    pptExportDevOnly
    pptExportLocalOnly
    title
    xlsExport
    xlsExportDevOnly
    markup
    exportTab
    xlsExportLocalOnly
    advertisersList
    selectedAdvertisers
    selectedUser
    userLayout
  }
`;
const LAYOUT_INFO = gql`
  fragment LayoutInfo on TacticLayout {
    DataSourceCode
    LastModifiedDate
    LastModifiedBy
    PropertyId
    Version
    agencyName
    pstate
    tactic
    type
  }
`;

const commonProperties = `
  cid
  id
  dataChecks
  disabled
  dataSource
  breakpoints
  name
  title
`;

const commonOverrides = `
  id
  breakpoints
  title
`;

const campaignSummaryProps = `
  availableTopMetrics
  availableBottomMetrics
  topMetrics
  bottomMetrics
  hideZeros
  replaceTitle
  impressionsProgressBar
  siteTrafficProgressBar
  spendProgressBar
  switchMetricDisplayOrder
  customStyle
  showSpendIfEnabled
`;

const TABLE = gql`
  fragment CommonPropsTable on genericTable {
    ${commonProperties}
  }
`;
const PROG_TABLE = gql`
  fragment CommonPropsProgTable on progressBarTable {
    ${commonProperties}
  }
`;
const BAR = gql`
  fragment CommonPropsBar on genericBarChart {
    ${commonProperties}
  }
`;

const SMRY_CTRL = gql`
  fragment CommonPropsSummaryCtrl on summaryController {
    ${commonProperties}
  }
`;

const HM_CTRL = gql`
fragment CommonPropsHomeCtrl on homeController {
  ${commonProperties}
}
`;

const ORDER_CTRL = gql`
fragment CommonPropsOrderCtrl on orderController {
  ${commonProperties}
  configuration
}
`;

const DMAZIP = gql`
  fragment CommonPropsDMAZipMap on dmaZipMap {
    ${commonProperties}
  }
`;

const STATION = gql`
  fragment CommonPropsStationMap on stationMap {
    ${commonProperties}
  }
`;

const GEN_MAP = gql`
  fragment CommonPropsGenericMap on genericMap {
    ${commonProperties}
  }
`;

const GEOFENCE = gql`
  fragment CommonPropsGeofenceMap on geofenceMap {
    ${commonProperties}
  }
`;

const LINE = gql`
  fragment CommonPropsLine on genericLineChart {
    ${commonProperties}
  }
`;

const LINEBARNEW = gql`
  fragment CommonPropsLineBarNew on genericLineBarChartNew {
    ${commonProperties}
  }
`;

const PIE = gql`
  fragment CommonPropsPie on genericPie {
    ${commonProperties}
  }
`;

const LINEBAR = gql`
  fragment CommonPropsLineBar on lineAndBarChart {
    ${commonProperties}
  }
`;

const LOGO_LIST = gql`
  fragment CommonPropsLogoList on slingNetworkLogosList {
    ${commonProperties}
  }
`;

const CAMP_OVERVIEW = gql`
  fragment CommonPropsCampOverview on campaignOverview {
    ${commonProperties}
  }
`;

const TOP_METRICS = gql`
  fragment CommonPropsTopMetrics on genericTopMetrics {
    ${commonProperties}
  }
`;

const SIDE_SUMRY = gql`
  fragment CommonPropsSideSummary on sideSummary {
    ${commonProperties}
  }
`;

const TEXT_SUMRY = gql`
  fragment CommonPropsTextCampaignSummary on textCampaignSummary {
    ${commonProperties}
  }
`;

const CAMP_STATS = gql`
  fragment CommonPropsCampaignStats on campaignStats {
    ${commonProperties}
  }
`;

const SI_TABLE = gql`
  fragment CommonPropsSiteImpactTable on siteImpactTable {
    ${commonProperties}
  }
`;

const TARGET_LIST = gql`
  fragment CommonPropsTargetingList on targetingList {
    ${commonProperties}
  }
`;

const FALLBACK = gql`
  fragment CommonPropsFallBack on fallbackChart {
    ${commonProperties}
  }
`;

const TABLE_LIST = gql`
  fragment CommonPropsTableList on tableList {
    ${commonProperties}
  }
`;

const SECTION_HEADER = gql`
  fragment CommonPropsSectionHeader on sectionHeader {
    ${commonProperties}
  }
`;

export default {
  SECTION_INFO,
  LAYOUT_INFO,
  TABLE,
  PROG_TABLE,
  BAR,
  SMRY_CTRL,
  ORDER_CTRL,
  DMAZIP,
  HM_CTRL,
  STATION,
  GEN_MAP,
  GEOFENCE,
  LINE,
  LINEBARNEW,
  PIE,
  LINEBAR,
  LOGO_LIST,
  CAMP_OVERVIEW,
  TOP_METRICS,
  SIDE_SUMRY,
  TEXT_SUMRY,
  CAMP_STATS,
  SI_TABLE,
  TARGET_LIST,
  FALLBACK,
  commonOverrides,
  campaignSummaryProps,
  TABLE_LIST,
  SECTION_HEADER,
};
