const currency = require('./currency');
const REGEX = require('./regex');
const { packageNames } = require('./mappings');

function stationLocked(bizData) {
  return bizData.IsStationLocked && !bizData.IsStationLockedForCurrentStation;
}
function reserved(bizData) {
  // reserved to someone else
  return bizData.IsReserved && !bizData.IsReservedForCurrentRep;
}
function ignoreReservation(bizData) {
  return bizData.IsReservedForSameOrganization && bizData.RepCanIgnoreReservationWithBusiness;
}
function canView(bizData, rep) {
  // bizData is the CalculatedData
  if (typeof bizData === 'undefined') return false;
  if (rep && rep.PostSale && bizData.IsSold) return true; // some reps have a PostSale flag enabled
  if (ignoreReservation(bizData)) return true;
  if (!bizData.IsSold && !bizData.IsDnc && !reserved(bizData) && !stationLocked(bizData)) return true;
  return false;
}
function doNotCall(bizData) {
  // if dnc, station locked, or reserved
  return bizData.IsDnc || reserved(bizData) || stationLocked(bizData);
}
function getLabels(bizData, cloneData, dataSourceCode) {
  const labels = [];
  // already sold
  if (bizData.IsSold) labels.push('Sold');
  else if (bizData.IsDnc) {
    // do not call
    labels.push('DNC');
  } else if (bizData.IsStationLocked && !bizData.IsStationLockedForCurrentStation) labels.push('Station locked');
  else if (bizData.IsReserved) {
    if (bizData.IsReservedForCurrentRep) {
      // reserved to you
      labels.push('Reserved to you');
    } else {
      // reserved to someone else
      labels.push('Reserved');
    }
  } else if (dataSourceCode === 'ECBDSP') {
    // ECBDSP and available
    labels.push('Available');
  }
  if (ignoreReservation(bizData)) labels.push('Ignored status');
  // cloned record
  if (cloneData && cloneData.GroupId) labels.push('Cloned');

  return labels;
}
function matchingOrPrimaryPhone(biz, searched) {
  if (Array.isArray(biz.CalculatedData.Phones)) {
    const match = biz.CalculatedData.Phones.find(phone => phone === searched);
    return match || biz.CalculatedData.Phones[0];
  }
  return biz.CalculatedData.Phones[0] || null;
}
function getDemoURL(params) {
  // @params {
  // origin: http://localhost:8080
  // station: KING
  // event: ds
  // slideTrack: tds
  // dsc: ECBDSP
  // ppid: 1234566
  // }
  // need to produce
  // http://localhost:8080/#/KING-ds/tds/ECBDSP__89006079/Main/adformats
  return `${params.origin}/#/${params.station}-${params.event}/${params.slideTrack}/${params.dsc}__${params.ppid}`;
}
function getProposalURL(params) {
  return `${getDemoURL(params)}/signup`;
}

function formatPhone(phone) {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(REGEX.matchPhoneFromDigits);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

function allContacts(customer) {
  let contacts = [];

  // look in public contacts
  if (customer.Email && customer.Email.toLowerCase() !== 'unknown@example.com') {
    const contact = {
      email: customer.Email,
      first: customer.FirstName,
      last: customer.LastName,
      title: 'Public Contact',
      disabled: !customer.FirstName,
    };
    contacts.push(contact);
  }

  // look in salescontacts
  if (customer.SalesContacts && customer.SalesContacts.length) {
    // get all sales contacts
    const salesContacts = customer.SalesContacts.reduce((all, contact) => {
      if (contact.Emails && contact.Emails.length) {
        const emails = contact.Emails.map(email => ({
          email: email.Address,
          first: contact.FirstName,
          last: contact.LastName,
          title: email.Type,
          disabled: !contact.FirstName,
        }));
        all = all.concat(emails.filter(e => e.email.toLowerCase() !== 'unknown@example.com'));
      }
      return all;
    }, []);
    contacts = contacts.concat(salesContacts);
  }
  // console.log(...contacts); // this causes a babel error
  return contacts;
}

function bundlePrice(bundle) {
  if (bundle.price && !bundle.soldOut) return `$${currency.convertToString(bundle.price)}<sup>/mo</sup>`;
  if (bundle.chooseCommunity) return '<small>Select a community</small>';
  return '<span class="sold">Sold out</span>';
}

function PackagePrice(bundle, proposal) {
  // $4,776 for one year
  return `${currency.bundlePrice(bundle, proposal)}`;
}
function PriceDetails(proposal, bundle) {
  // After the 12-month period, your price will revert to $349 $398 per month, on a month-to-month basis.
  if (!proposal.prepay) return '';
  return `After the ${proposal.contractLength} month period, your price will revert to <span>${currency.monthPrice(
    bundle,
  )}</span> per month, on a <nobr>month&#8209;to&#8209;month</nobr> basis.`;
}
function PackagePriceSummary(bundle, proposal) {
  // Partner Bundle • $4,776 for one year • 12-month contract, prepaid
  return `${packageNames[bundle.packageName]} Bundle &bull; ${currency.bundlePrice(
    bundle,
    proposal,
  )} &bull; ${currency.contractPrepayLabel(proposal)}`;
}

function getProposalEmailParams(customer, rep, selectedContact, event, proposal) {
  try {
    if (!customer) throw new Error('getDemoEmailParams: missing customer');
    if (!rep) throw new Error('getDemoEmailParams: missing rep');
    if (!selectedContact) throw new Error('getDemoEmailParams: missing contact');
    if (!event) throw new Error('getDemoEmailParams: missing event');

    const params = {
      ctaURL: getProposalURL({
        origin: window.location.origin,
        station: event.PropertyId,
        event: event.id,
        slideTrack: event.slideTrack,
        dsc: customer.DataSourceCode,
        ppid: customer.PropertyId,
      }),
      CustomerId: customer.PropertyId,
      CustomerDSC: customer.DataSourceCode,
      CustomerEmail: selectedContact.email,
      RepId: rep.Email,
      prepay: proposal.prepay,
      StationName: event.stationBranding,
      StationAdsLabel: `Station Ads on ${event.PartnerDomain}`,
      contractLength: proposal.contractLength,
      SFLT: event.PropertyId,
      StationParent: event.ContractParent,
      domain: event.PartnerDomain,
      BusinessName: customer.BIZNM,
      contractText: currency.contractText(proposal.contractLength),
      PackagePrice: PackagePrice(customer.bundle, proposal),
      PriceDetails: PriceDetails(proposal, customer.bundle),
      PackagePriceSummary: PackagePriceSummary(customer.bundle, proposal),
    };

    // replace once we get babel working on this file
    // params = Object.assign(proposal, this.customer.bundle);

    for (const bundleProp in customer.bundle) {
      if (customer.bundle.hasOwnProperty(bundleProp)) {
        params[bundleProp] = customer.bundle[bundleProp];
      }
    }
    return params;
  } catch (err) {
    return err.message;
  }
}

function getDemoEmailParams(customer, rep, selectedContact, event) {
  try {
    if (!customer) throw new Error('getDemoEmailParams: missing customer');
    if (!rep) throw new Error('getDemoEmailParams: missing rep');
    if (!selectedContact) throw new Error('getDemoEmailParams: missing contact');
    if (!event) throw new Error('getDemoEmailParams: missing event');

    return {
      BusinessName: customer.BIZNM,
      ctaURL: getDemoURL({
        origin: window.location.origin,
        station: event.PropertyId,
        event: event.id,
        slideTrack: event.slideTrack,
        dsc: customer.DataSourceCode,
        ppid: customer.PropertyId,
      }),
      RepId: rep.Email,
      CustomerId: customer.PropertyId,
      CustomerDSC: customer.DataSourceCode,
      CustomerEmail: selectedContact.email,
      CustomerName: selectedContact.first,
      domain: event.PartnerDomain,
      SFLT: event.PropertyId,
      StationName: event.stationBranding,
      UID: customer.UID,
      StationParent: event.ContractParent,
    };
  } catch (err) {
    return err.message;
  }
}

export default {
  allContacts,
  bundlePrice,
  canView,
  doNotCall,
  formatPhone,
  getDemoEmailParams,
  getProposalEmailParams,
  getLabels,
  getDemoURL,
  getProposalURL,
  ignoreReservation,
  matchingOrPrimaryPhone,
  reserved,
  stationLocked,
};
