/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './hyportdigital.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'hyportdigital',
  companyId: 'Hyport_Digital',
  companyName: 'Hyport Digital',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://hyportdigital.myanalyticshub.com',
    staging: 'https://hyportdigital.staging.myanalyticshub.com',
    dev: 'https://hyportdigital.dev.myanalyticshub.com',
    local: 'https://hyportdigital.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'hyportdigital.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#538cca', '#374e81', '#06d6a0', '#ffd166', '#ef476f', '#b3d7ff'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
