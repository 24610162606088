import { ApolloQueryResult } from '@apollo/client/core';
import { Error, GoogleSearchConsoleAccount, GetGoogleSearchConsoleResponse } from './../types/google';
import requests from './../requests';
import CONSTANTS from '../constants';
import { logError } from '../../../index';

const getGoogleSearchData = async ({ commit }, id): Promise<GoogleSearchConsoleAccount[] | Error> => {
  if (!id) {
    commit(CONSTANTS.SET_GOOGLE_ADS_ERROR, 'Missing client id');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, null);
    commit(CONSTANTS.SET_LOADING, true);
    const response: ApolloQueryResult<GetGoogleSearchConsoleResponse> = await requests.getGoogleSearchConsole({ id });
    if (response?.errors) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
      commit(CONSTANTS.SET_LOADING, false);
      return { message: response.errors[0]?.message };
    }
    return response.data.getGoogleSearchConsole.accounts;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, exp.message);
    commit(CONSTANTS.SET_LOADING, false);
    throw logError(exp, 'store.linearadmin.getGoogleSearchData');
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

export default {
  getGoogleSearchData,
};
