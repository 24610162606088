/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './nyinterconnect.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'nyinterconnect',
  companyId: 'NY_Interconnect',
  companyName: 'NY Interconnect',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://nyinterconnect.myanalyticshub.com',
    staging: 'https://nyinterconnect.staging.myanalyticshub.com',
    dev: 'https://nyinterconnect.dev.myanalyticshub.com',
    local: 'https://nyinterconnect.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'nyinterconnect_white.png', emailLogo: 'nyinterconnect.png' },
  config: {
    chartColors: ['#00ba62', '#09657f', '#29b6f6', '#959595', '#f78b0d', '#fff213'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
