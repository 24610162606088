/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './cumulus.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'cumulus',
  companyId: 'Cumulus_Media',
  companyName: 'Cumulus Media',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://cumulusmedia.myanalyticshub.com',
    staging: 'https://cumulusmedia.staging.myanalyticshub.com',
    dev: 'https://cumulusmedia.dev.myanalyticshub.com',
    local: 'https://cumulusmedia.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'cumulus_white.png', emailLogo: 'cumulus_color.png' },
  config: {
    chartColors: ['#00598e', '#28aefd', '#f58810', '#f4c202', '#15875e', '#99b7cd'],
    pieCharts: { firstItem: 'legend' },

    maps: {
      themes: ['cumulus'],
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
