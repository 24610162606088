/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './lotuscorp.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'lotuscorp',
  companyId: 'Lotus_Targeted_Television',
  companyName: 'Lotus Communications Corp',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://lotustargetedtelevision.myanalyticshub.com',
    staging: 'https://lotustargetedtelevision.staging.myanalyticshub.com',
    dev: 'https://lotustargetedtelevision.dev.myanalyticshub.com',
    local: 'https://lotustargetedtelevision.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'lotuscorp.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#004aad', '#49b8cf', '#ff1616', '#ffa33c', '#ffe807', '#41d815'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
