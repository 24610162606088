/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './buntingroup.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';

const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'buntingroup',
  companyId: 'Buntin_Group',
  companyName: 'Buntin Group',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://buntingroup.myanalyticshub.com',
    staging: 'https://buntingroup.staging.myanalyticshub.com',
    dev: 'https://buntingroup.dev.myanalyticshub.com',
    local: 'https://buntingroup.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'buntingroup.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#fe2e00', '#365cac', '#f3c954', '#941a02', '#514e44', '#bb7832'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
