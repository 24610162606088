/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './localculturecreative.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'localculturecreative',
  companyId: 'Local_Culture_Creative',
  companyName: 'Local Culture Creative',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://localculturecreative.myanalyticshub.com',
    staging: 'https://localculturecreative.staging.myanalyticshub.com',
    dev: 'https://localculturecreative.dev.myanalyticshub.com',
    local: 'https://localculturecreative.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'localculturecreative_white.png', emailLogo: 'localculturecreative.png' },
  config: {
    chartColors: ['#7bd1e7', '#fd625e', '#fcd300', '#2b8ea5', '#9f9f9f', '#5b5b5b'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
