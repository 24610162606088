/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './mpwmarketing.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'mpwmarketing',
  companyId: 'MPW_Marketing',
  companyName: 'MPW Marketing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://mpwmarketing.myanalyticshub.com',
    staging: 'https://mpwmarketing.staging.myanalyticshub.com',
    dev: 'https://mpwmarketing.dev.myanalyticshub.com',
    local: 'https://mpwmarketing.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'mpwmarketing.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#09507d', '#669bbc', '#fb5050', '#f4d58d', '#7d8284', '#242424'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
