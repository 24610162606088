/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './mediamcc.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'mediamcc',
  companyId: 'Purple_Gator',
  companyName: 'Media MCC',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://mediamcc.myanalyticshub.com',
    staging: 'https://mediamcc.staging.myanalyticshub.com',
    dev: 'https://mediamcc.dev.myanalyticshub.com',
    local: 'https://mediamcc.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'mmcc_white.png', emailLogo: 'mmcc.png' },
  config: {
    chartColors: ['#af191b', '#f4b529', '#4673b0', '#003049', '#b7b7b7', '#43aa8b'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
