import mutations from './mutations';
import actions from './actions';

const state = {
  advertiser: null,
  searchResults: null,
  linearAdminClients: null,
  linearAdminError: null,
  loading: false,
  accountLinking: null,
  facebookError: null,
  facebookLoading: null,
  googleAnalyticsError: null,
  googleAnalyticsLoading: null,
  googleAdsError: null,
  googleAdsLoading: null,
  googleAdManagerError: null,
  googleAdManagerAccountLinking: null,
  googleAdManagerLoading: null,
  credentials: {},
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
