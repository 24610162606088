import axios from 'axios';
import { logError } from '../../index';
import CONSTANTS from './constants';
import {
  GetAdvertiserAvailableCampaignsParams,
  CallRailCampaign,
  CallRailAccount,
  CallRailAccountConnection,
  CallRailAccountPatch,
} from './types';

export default {
  async getAdvertiserAvailableCampaigns(
    { commit },
    advertiserId: GetAdvertiserAvailableCampaignsParams,
  ): Promise<CallRailAccount[]> {
    try {
      commit(CONSTANTS.SET_ERROR, null);
      const { data } = await axios.get(`/api/connectors/call-rail/campaigns/available/${advertiserId}`);
      return data;
    } catch (error) {
      commit(CONSTANTS.SET_ERROR, error?.message);
      throw logError(error, 'store.callrail.getAdvertiserAvailableCampaigns');
    }
  },

  async getAdvertiserCampaigns({ commit }, advertiserId: string): Promise<CallRailCampaign[]> {
    try {
      commit(CONSTANTS.SET_ERROR, null);
      const { data } = await axios.get(`/api/connectors/call-rail/connected/${advertiserId}`);
      return data;
    } catch (error) {
      commit(CONSTANTS.SET_ERROR, error?.message);
      throw logError(error, 'store.callrail.getAdvertiserCampaigns');
    }
  },

  async updateAdvertiserConnector({ commit }, payload) {
    const { advertiserId, campaignIds } = payload;
    try {
      commit(CONSTANTS.SET_ERROR, null);
      await axios.post(`/api/connectors/call-rail/advertiser/connect/${advertiserId}`, { campaignIds });
      return true;
    } catch (error) {
      commit(CONSTANTS.SET_ERROR, error?.response?.data || error?.message);
      return false;
    }
  },

  /**
   * Get CallRail accounts associated with the agency.
   * @param agency  Agency ID, e.g. Demo
   */
  async getCallRailAccounts({ commit }, agency: string): Promise<CallRailAccountConnection[]> {
    try {
      commit(CONSTANTS.SET_CREDENTIALS_ERROR, null);
      const { data } = await axios.get(`/api/credentials/call-rail/${agency}`);
      return data;
    } catch (error) {
      commit(CONSTANTS.SET_CREDENTIALS_ERROR, error?.message);
      throw logError(error, 'store.callrail.getCallRailAccounts');
    }
  },

  /**
   * Delete CallRail account connection with the agency.
   * @param accountName   Account name.
   * @param agency        Agency ID.
   */
  async deleteCallRailAccount({ commit }, payload): Promise<boolean> {
    const { accountName, agency } = payload;
    try {
      commit(CONSTANTS.SET_CREDENTIALS_ERROR, null);
      await axios.delete(`/api/credentials/call-rail/${agency}/${accountName}`);
      return true;
    } catch (error) {
      commit(CONSTANTS.SET_CREDENTIALS_ERROR, error?.message);
      throw logError(error, 'store.callrail.deleteCallRailAccount');
    }
  },

  /**
   * Create CallRail account connection with the agency.
   * @param accountName    Account name.
   * @param agency         Agency ID.
   */
  async createCallRailAccount({ commit }, payload): Promise<boolean> {
    const { accountName, accountId, agency } = payload;
    try {
      commit(CONSTANTS.SET_CREDENTIALS_ERROR, null);
      await axios.post(`/api/credentials/call-rail/${agency}`, {
        accountId,
        accountName,
      });
      return true;
    } catch (error) {
      commit(CONSTANTS.SET_CREDENTIALS_ERROR, error?.message);
      throw logError(error, 'store.callrail.createCallRailAccount');
    }
  },

  /**
   * Update CallRail account associated with the agency.
   * @param agency        Agency ID.
   * @param accountName   Account name.
   * @param patch         Patch object.
   */
  async updateCallRailAccount({ commit }, payload): Promise<boolean> {
    const { accountName, patch, agency }: { agency: string; accountName: string; patch: CallRailAccountPatch } =
      payload;

    try {
      commit(CONSTANTS.SET_CREDENTIALS_ERROR, null);
      await axios.patch(`/api/credentials/call-rail/${agency}/${accountName}`, { patch });
      return true;
    } catch (error) {
      commit(CONSTANTS.SET_CREDENTIALS_ERROR, error?.message);
      throw logError(error, 'store.callrail.updateCallRailAccount');
    }
  },
};
