import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  [CONSTANTS.SET_PRODUCTS](_state: StateType, products: object): void {
    Vue.set(_state, 'products', products);
  },
  [CONSTANTS.SET_ADVERTISERS](_state: StateType, advertisers: object): void {
    Vue.set(_state, 'advertisers', advertisers);
  },
  [CONSTANTS.SET_AGENICES](_state: StateType, agencies: object): void {
    Vue.set(_state, 'agencies', agencies);
  },
  [CONSTANTS.SET_ADVERTISERS_BY_PRODUCTS](_state: StateType, advertisers: object): void {
    Vue.set(_state, 'advertisersByProduct', advertisers);
  },
  [CONSTANTS.SET_CAMPAIGNS](_state: StateType, campaigns: object): void {
    Vue.set(_state, 'campaigns', campaigns);
  },
  [CONSTANTS.SET_CAMPAIGNS_BY_PRODUCT](_state: StateType, campaigns: object): void {
    Vue.set(_state, 'campaignsByProduct', campaigns);
  },
  [CONSTANTS.SET_LAYOUTS](_state: StateType, layouts: object): void {
    Vue.set(_state, 'layouts', layouts);
  },
  [CONSTANTS.SET_ERROR](_state: StateType, error: object): void {
    Vue.set(_state, 'error', error);
  },
  [CONSTANTS.SET_LOADING_PRODUCTS](_state: StateType, loading: boolean): void {
    Vue.set(_state, 'loadingProducts', loading);
  },
  [CONSTANTS.SET_LOADING_ADVERTISERS](_state: StateType, loading: boolean): void {
    Vue.set(_state, 'loadingAdvertisers', loading);
  },
  [CONSTANTS.SET_LOADING_CAMPAIGNS](_state: StateType, loading: boolean): void {
    Vue.set(_state, 'loadingCampaigns', loading);
  },
  [CONSTANTS.SET_LOADING_LAYOUTS](_state: StateType, loading: boolean): void {
    Vue.set(_state, 'loadingLayouts', loading);
  },
};
