import mutations from './mutations';
import actions from './actions';

const state = {
  impressionsByClientsError: null,
  impressionsByTacticError: null,
  endingOrdersError: null,
  pacingError: null,
  metricsError: null,
  filterError: null,

  activeOrdersCache: {},
  impressionsCache: {},
  activeClientsCache: {},
  activeCampaignsCache: {},

  activeOrdersPromise: false,
  impressionsPromise: false,
  activeClientsPromise: false,
  activeCampaignsPromise: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
