/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './wfmj.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'wfmj',
  companyId: 'WFMJ',
  companyName: 'WFMJ Digital',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://wfmj.myanalyticshub.com',
    staging: 'https://wfmj.staging.myanalyticshub.com',
    dev: 'https://wfmj.dev.myanalyticshub.com',
    local: 'https://wfmj.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'wfmjdigital.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#20abd0', '#7c4c9e', '#ffe92f', '#ee663c', '#ee2f97', '#1ed9c5'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
