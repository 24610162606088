/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './lily.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'lily',
  companyId: 'lily',
  companyName: 'Lily',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://lily.myanalyticshub.com',
    staging: 'https://lily.staging.myanalyticshub.com',
    dev: 'https://lily.dev.myanalyticshub.com',
    local: 'https://lily.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'lily.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#ec4980', '#29317c', '#01c6a2', '#fddb01', '#ffa809', '#979797'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
