/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './todaymedia.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'todaymedia',
  companyId: 'Today_Media',
  companyName: 'Today Media',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://todaymedia.myanalyticshub.com',
    staging: 'https://todaymedia.staging.myanalyticshub.com',
    dev: 'https://todaymedia.dev.myanalyticshub.com',
    local: 'https://todaymedia.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'todaymedia_white.png', emailLogo: 'todaymedia.png' },
  config: {
    chartColors: ['#0990d2', '#ffb703', '#fb6c00', '#023047', '#8ecae6', '#777777'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
