/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './n2publishing.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'n2publishing',
  companyId: 'N2_Publishing',
  companyName: 'N2 Publishing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://n2publishing.myanalyticshub.com',
    staging: 'https://n2publishing.staging.myanalyticshub.com',
    dev: 'https://n2publishing.dev.myanalyticshub.com',
    local: 'https://n2publishing.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'n2publishing_color.png', emailLogo: 'n2publishing_color.png' },
  whiteToolbar: true,
  config: {
    chartColors: ['#d81f26', '#ffa41d', '#e9d8a6', '#94d2bd', '#0085a2', '#004864'],

    header: { borderColor: '#FF0000FF' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
