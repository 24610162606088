import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { apollo } from '../../../index';
import {
  CommonLinkingParams,
  GetSiteImpactCampaignsResponse,
  GetSiteImpactConnectionsResponse,
} from '../types/siteimpact';

export default {
  async getConnections(props: CommonLinkingParams) {
    try {
      const response: ApolloQueryResult<GetSiteImpactConnectionsResponse> = await apollo().query({
        query: gql`
          query GetSiteImpactConnections($params: CommonLinkingParams) {
            getSiteImpactConnections(params: $params) {
              AgencyOwned {
                ApiKey
                Enabled
                Healthy
                Id
                IsAgencyOwned
                KeySource
                KeySourcePpid
                Name
              }
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
            isAgency: props.isAgency,
            allPossible: props.allPossible,
          },
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getCampaigns(props: CommonLinkingParams) {
    try {
      const response: ApolloQueryResult<GetSiteImpactCampaignsResponse> = await apollo().query({
        query: gql`
          query GetSiteImpactCampaigns($params: CommonLinkingParams) {
            getSiteImpactCampaigns(params: $params) {
              Campaigns {
                CampaignId
                CampaignName
                FromLine
                SubjectLine
              }
            }
          }
        `,
        variables: {
          params: {
            id: props.id,
            keyId: props.keyId,
            isAgency: props.isAgency,
            allPossible: props.allPossible,
          },
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
};
