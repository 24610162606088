import axios, { AxiosResponse } from 'axios';
import { logError } from '../../index';

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getShareDashboardLink({ commit }, payload): Promise<string> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getShareDashboardLink', payload);
      return req.data;
    } catch (exp) {
      return exp.message;
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  async getHealthChecks({ commit }, payload: any): Promise<string | Array<any>> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getHealthChecks', payload);
      if (req && Array.isArray(req.data)) return req.data;
      throw new Error(req.data.error);
    } catch (exp) {
      throw logError(exp, 'store.getHealthChecks');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  async runHealthCheck({ commit }, check: any): Promise<string | Array<any>> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/runHealthCheck', check);
      if (req && req.data) return req.data;
      throw new Error(req.data.error);
    } catch (exp) {
      throw logError(exp, 'store.runHealthCheck');
    }
  },
};
