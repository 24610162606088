/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './absolutelydominate.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'absolutelydominate',
  companyId: 'absolutelydominate',
  companyName: 'Absolutely Dominate',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://absolutelydominate.myanalyticshub.com',
    staging: 'https://absolutelydominate.staging.myanalyticshub.com',
    dev: 'https://absolutelydominate.dev.myanalyticshub.com',
    local: 'https://absolutelydominate.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'absolutelydominate.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#f25a29', '#ff9e0e', '#5291ea', '#3c414a', '#21bf74', '#8a8a8a'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
