/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios';
import CONSTANTS from './constants';
import { logError } from '../../index';
import { session_id } from '../../../main';
import { AdvertiserInfo, Contacts } from '@point/utility-classes';

const resetAdvertiserInfo = ({ commit }): void => {
  commit(CONSTANTS.SET_ADVERTISER_INFO, null);
  commit(CONSTANTS.SET_ADVERTISER_INFO_ERROR, null);
  commit(CONSTANTS.SET_ADVERTISER_INFO_CACHE, null);
  commit(CONSTANTS.SET_IS_ADVERTISER_INFO_CACHED, false);
  commit(CONSTANTS.SET_IS_ADVERTISER_INFO_LOADING, false);
};

const updateAdvertiserInfo = async ({ commit }, payload): Promise<object> => {
  const { propertyId, ...putData } = payload;
  try {
    commit(CONSTANTS.SET_UPDATE_ADVERTISER_ERROR, null);
    commit(CONSTANTS.SET_IS_UPDATE_ADVERTISER_LOADING, true);
    const response = await axios.put(`/api/advertiserInfoUpdate/${propertyId}`, putData);
    resetAdvertiserInfo({ commit });
    return response?.data;
  } catch (exp) {
    commit(CONSTANTS.SET_UPDATE_ADVERTISER_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.advertiser.updateAdvertiserInfo');
  } finally {
    commit(CONSTANTS.SET_IS_UPDATE_ADVERTISER_LOADING, false);
  }
};

const updateAdvertiserContacts = async ({ commit }, payload): Promise<object> => {
  try {
    commit(CONSTANTS.SET_UPDATE_CONTACTS_ERROR, null);
    commit(CONSTANTS.SET_IS_UPDATE_CONTACTS_LOADING, true);
    const req = await axios.post(`/api/advertiserContactsUpdate/${payload.propertyId}`, {
      contacts: payload.contacts,
      userId: payload.userId,
    });
    return req?.data;
  } catch (exp) {
    commit(CONSTANTS.SET_UPDATE_CONTACTS_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.advertiser.updateAdvertiserContacts');
  } finally {
    commit(CONSTANTS.SET_IS_UPDATE_CONTACTS_LOADING, false);
  }
};

const getContacts = async ({ commit }, id): Promise<Contacts> => {
  if (!id) {
    commit(CONSTANTS.SET_CONTACTS_ERROR, 'Missing advertiser ID');
    return;
  }

  try {
    commit(CONSTANTS.SET_CONTACTS, null);
    commit(CONSTANTS.SET_CONTACTS_ERROR, null);
    commit(CONSTANTS.SET_IS_CONTACTS_LOADING, true);
    const req: AxiosResponse<Contacts> = await axios.get(`/api/getContacts/${id}`);
    commit(CONSTANTS.SET_CONTACTS, req.data);
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_CONTACTS_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.advertiser.getAdvertiserInfo');
  } finally {
    commit(CONSTANTS.SET_IS_CONTACTS_LOADING, false);
  }
};

const getAdvertiserInfo = async ({ commit, state }, payload): Promise<{ data: AdvertiserInfo }> => {
  const { id, skipCache, includeStations, includeSubagencies } = payload;
  commit(CONSTANTS.SET_ADVERTISER_INFO_ERROR, null);

  if (!id) {
    commit(CONSTANTS.SET_ADVERTISER_INFO_ERROR, 'Missing advertiser ID');
    return;
  }

  let infoUrl = `/api/getAdvertiserInfo?id=${id}`;
  const headers = { session_id };

  if (includeStations) {
    infoUrl += `&includeStations=true`;
  }
  if (includeSubagencies) {
    infoUrl += `&includeSubagencies=true`;
  }

  if (
    !skipCache &&
    state.advertiserInfoCache === infoUrl &&
    state.isAdvertiserInfoCached &&
    state.advertiserInfo?.data &&
    !state.advertiserInfoError
  ) {
    return state.advertiserInfo;
  }
  try {
    commit(CONSTANTS.SET_IS_ADVERTISER_INFO_LOADING, true);
    const req: AxiosResponse<AdvertiserInfo> = await axios.get(infoUrl, { headers });
    const data = { data: req.data };
    commit(CONSTANTS.SET_ADVERTISER_INFO, data);
    commit(CONSTANTS.SET_ADVERTISER_INFO_CACHE, infoUrl);
    commit(CONSTANTS.SET_IS_ADVERTISER_INFO_CACHED, true);
    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_ADVERTISER_INFO_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.advertiser.getAdvertiserInfo');
  } finally {
    commit(CONSTANTS.SET_IS_ADVERTISER_INFO_LOADING, false);
  }
};

export default {
  getAdvertiserInfo,
  resetAdvertiserInfo,
  updateAdvertiserInfo,
  updateAdvertiserContacts,
  getContacts,
};
