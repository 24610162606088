import axios, { AxiosResponse } from 'axios';
import { SetCampaignFriendlyName } from './types';
import { session_id } from '../../../main';

export default {
  async setCampaignFriendlyName(url: string, body: SetCampaignFriendlyName) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await axios.post(url, body);
      return response;
    } catch (err) {
      return err;
    }
  },
  async getProductsForAdvertiser(url: string) {
    try {
      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await axios.get(url, { headers });
      return response;
    } catch (err) {
      return err;
    }
  },
};
