/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './zenon.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'zenon',
  companyId: 'zenon',
  companyName: 'Zenon Wholesale Digital Marketing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://zenon.myanalyticshub.com',
    staging: 'https://zenon.staging.myanalyticshub.com',
    dev: 'https://zenon.dev.myanalyticshub.com',
    local: 'https://zenon.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'zenon_white.png', emailLogo: 'zenon_color.png' },
  config: {
    chartColors: ['#6eb48c', '#ffb61b', '#2979ff', '#7a7a7a', '#6ec1e4', '#191919'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
