/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './estrella.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'estrella',
  companyId: 'Estrella_Media',
  companyName: 'Estrella Media',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://estrella.myanalyticshub.com',
    staging: 'https://estrella.staging.myanalyticshub.com',
    dev: 'https://estrella.dev.myanalyticshub.com',
    local: 'https://estrella.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { invert: false, file: 'estrella.png', emailLogo: 'estrella_color.png' },
  config: {
    chartColors: ['#246cb7', '#fda304', '#d51c1c', '#61b481', '#4e4785', '#36aaff'],
    pieCharts: { firstItem: 'legend' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
