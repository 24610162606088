/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './c3mediagroup.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'c3mediagroup',
  companyId: 'c3mediagroup',
  companyName: 'C3 Media Group',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://c3mediagroup.myanalyticshub.com',
    staging: 'https://c3mediagroup.staging.myanalyticshub.com',
    dev: 'https://c3mediagroup.dev.myanalyticshub.com',
    local: 'https://c3mediagroup.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'c3mediagroup.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#ba0c2f', '#05778e', '#94d2bd', '#ffba08', '#3e3e3e', '#8a8c8b'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
