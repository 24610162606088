const repReader: any = {
  PropertyId: true,
  EmpId: true,
  MAIL: true,
  pstate: true,
  Station: true,
  StationCSV: true,
  FirstName: true,
  LastName: true,
  Name: true,
  Title: true,
  PHONE: true,
  DemoAccess: true,
  DataSourceCode: true,
  test: () => ({ ...{ test: true } }),
};

export default {
  reader: repReader,
};
