import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import utils from '../util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (url: string, store: any): any => {
  // sets auth header.
  // const authLink = new ApolloLink((operation, forward) => {
  //   operation.setContext(({ headers }) => ({
  //     headers: {
  //       // authorization: '', // need to get jwt token;
  //       ...headers,
  //     },
  //   }));
  //   return forward(operation);
  // });

  // HTTP connection to the API
  const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: url,
  });

  // Set the Authorization header for each request based on the accessToken from the store
  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from the store if it exists
    const currentAccessToken = store.getters.user.accessToken;
    if (!currentAccessToken) {
      return headers;
    }
    let token = currentAccessToken;
    const isAccessTokenExpired = utils.isAccessTokenExpired(currentAccessToken);
    if (isAccessTokenExpired) {
      token = await utils.updateAccessToken();
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  // Cache implementation
  const cache = new InMemoryCache({
    possibleTypes: {
      Component: [
        'fallbackChart',
        'summaryController',
        'dmaZipMap',
        'stationMap',
        'geofenceMap',
        'genericMap',
        'genericBarChart',
        'genericTable',
        'progressBarTable',
        'genericLineChart',
        'genericPie',
        'lineAndBarChart',
        'campaignOverview',
        'genericTopMetrics',
        'sideSummary',
        'textCampaignSummary',
        'campaignStats',
        'siteImpactTable',
        'targetingList',
        'homeController',
        'orderController',
      ],
    },
  });

  // Create the apollo client
  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });
  return apolloClient;
};
