import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import themePlugin from './vuetify/themes/themePlugin';
import { getVuetifyColors } from './vuetify/themes/utils';

// set the Theme object at the Vue root based on the domain
Vue.use(themePlugin, window.location);

// Vue.use(Vuetify, {
// I don't really know why vcol and vrow are specified here.
//  it works without them, but I found this sample code somewhere and thought it might reduce file size by explicity listing these components
//   components: {
//     VCol,
//     VRow,
//     VList,
//   },
// });
Vue.use(Vuetify);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cfg: any = {
  theme: {
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'fa4',
  },
};

if (Vue.prototype.Theme && Vue.prototype.Theme.light) {
  cfg.theme.themes = {
    light: getVuetifyColors(Vue.prototype.Theme.light),
  };
}

const vuetify = new Vuetify(cfg);
export default vuetify;
