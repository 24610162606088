const images = {
  getScreenshotUrl(info) {
    // webshots only supports test or prod so use "test" for the "develop" environment
    const env = process.env.WEBSHOTS_ENV;
    const url = process.env.WEBSHOTS_URL;
    let imgUrl = `${url}?dsc=${info.dataSourceCode}&ppid=${info.propertyId}&v=${info.version}&env=${env}&tmpl=sd1&demohash=${info.demoHash}`;
    if (info.thumbWidth) imgUrl += `&thumbWidth=${info.thumbWidth}`;
    return imgUrl;
  },
  getMyDSPresetURL(url, preset) {
    return url.replace('mydatasphere/', `mydatasphere/styles/${preset}/public/`);
  },
  preload(src) {
    new Image().src = src;
  },
};

export default images;
