/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './driveauto.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'driveauto',
  companyId: 'Drive_Auto',
  companyName: 'DriveAuto',
  rootDomain: 'sbganalytics.com',
  parentAgency: 'Sinclair',
  domains: {
    production: 'https://driveauto.sbganalytics.com',
    staging: 'https://staging.driveauto.sbganalytics.com',
    dev: 'https://dev.driveauto.sbganalytics.com',
    local: 'https://driveauto.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'driveauto_white.png', emailLogo: 'driveauto.png' },
  config: {
    chartColors: ['#b5303d', '#da949a', '#1c75ba', '#59ba3a', '#7f579e', '#6d6e71'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
