import gql from 'graphql-tag';
import CONSTANTS from '../constants';
import { logError, apollo } from '../../../index';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  Error,
  GetGoogleAdManagerResultsResponse,
  GetGoogleAdManagerSearchResultsResponse,
  GoogleAdManagerResultsResponse,
  GoogleAdManagerParams,
  SaveGoogleAdManagerParams,
  PostSaveGoogleAdManagerResponse,
  GamResponse,
  GamConnectorResponse,
} from './../types/google';
import requests from './../requests';
import utils from '../../../../util';

const getGAM = async (
  { commit },
  params: { limit?: number; offset?: number; search: string },
): Promise<GamResponse> => {
  if (!params.search) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, 'Missing search');
    return;
  }
  try {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, null);
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, true);
    const response = await apollo().query({
      query: gql`
        query GetGAM($params: GAMParams) {
          getGAM(params: $params) {
            data {
              campaigns {
                id
                name
              }
              order_id
              order_name
            }
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data.getGAM;
  } catch (error) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, error.message);
    logError('getGAM', error);
  } finally {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, false);
  }
};

const getGamConnector = async ({ commit }, params: { id: string }): Promise<GamConnectorResponse> => {
  if (!params.id) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, 'Missing advertiser ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, null);
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, true);
    const response = await apollo().query({
      query: gql`
        query GetGamConnector($params: GamConnectorParams) {
          getGamConnector(params: $params) {
            data {
              campaigns {
                id
                name
                connection_date
              }
              order_id
              order_name
            }
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data.getGamConnector.data;
  } catch (error) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, error.message);
    logError('getGamConnector', error);
  } finally {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, false);
  }
};

const addGamConnector = async ({ commit }, params: { id: string; campaign_ids: string[] }): Promise<unknown> => {
  if (!params.id || !Array.isArray(params.campaign_ids)) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR, 'Missing advertiser ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR, null);
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, true);
    const response = await apollo().mutate({
      mutation: gql`
        mutation AddGamConnector($params: AddGamConnectorParams) {
          addGamConnector(params: $params) {
            connectors {
              created
              deleted
            }
            advertiserId
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data;
  } catch (error) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR, error.message);
    logError('addGamConnector', error);
  } finally {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, false);
  }
};

const searchGoogleAdManagerOrders = async (
  { commit },
  { advertiserId, search }: GoogleAdManagerParams,
): Promise<GoogleAdManagerResultsResponse | Error> => {
  if (!advertiserId) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, 'Missing advertiser ID');
    return;
  } else if (!search) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, 'Missing search');
  }
  try {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, null);
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, true);
    const response: ApolloQueryResult<GetGoogleAdManagerSearchResultsResponse> =
      await requests.getGoogleAdManagerResults({
        advertiserId,
        search,
      });
    if (response?.errors) {
      commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return utils.removeAllTypenames(response.data?.getGoogleAdManagerResults);
  } catch (exp) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.searchGoogleAdManagerOrders');
  } finally {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, false);
  }
};

const getGoogleAdManagerLinked = async (
  { commit },
  { advertiserId }: GoogleAdManagerParams,
): Promise<GoogleAdManagerResultsResponse | Error> => {
  if (!advertiserId) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, 'Missing advertiser ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, null);
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, true);
    const response: ApolloQueryResult<GetGoogleAdManagerResultsResponse> = await requests.getGoogleAdManagerLinked({
      advertiserId,
    });
    if (response?.errors) {
      commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(
      CONSTANTS.SET_GOOGLE_AD_MANAGER_ACCOUNT_LINKING,
      utils.removeAllTypenames(response.data?.getGoogleAdManagerLinked),
    );
  } catch (exp) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.getGoogleAdManagerLinked');
  } finally {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, false);
  }
};

const saveGoogleAdManagerLinked = async (
  { commit },
  { advertiserId, CampaignsList }: SaveGoogleAdManagerParams,
): Promise<void | Error> => {
  commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR, null); // clear any errors

  if (!advertiserId) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR, 'Missing advertiser id');
  }
  if (!CampaignsList) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR, 'Missing list of campaigns');
  }
  try {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, true);
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR, null);
    const response: ApolloQueryResult<PostSaveGoogleAdManagerResponse> = await requests.saveGoogleAdManager({
      advertiserId,
      CampaignsList,
    });
    if (response?.errors) {
      commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
  } catch (exp) {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.saveGoogleAdManagerLinked');
  } finally {
    commit(CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING, false);
  }
};

export default {
  searchGoogleAdManagerOrders,
  getGoogleAdManagerLinked,
  saveGoogleAdManagerLinked,
  getGAM,
  getGamConnector,
  addGamConnector,
};
