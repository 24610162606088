/* eslint-disable no-unused-vars */
export enum ContactsKeyType {
  Media_company = 'media_company',
  Decision_maker = 'decision_maker',
  Technical = 'technical',
  Marketing = 'marketing',
}

export type Contact = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  availability: string;
  is_copy_of_dm: string;
};

export type Contacts = {
  [key in ContactsKeyType]: Contact;
};
