/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './leadnavigators.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'leadnavigators',
  companyId: 'leadnavigators',
  companyName: 'Lead Navigators',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://leadnavigators.myanalyticshub.com',
    staging: 'https://leadnavigators.staging.myanalyticshub.com',
    dev: 'https://leadnavigators.dev.myanalyticshub.com',
    local: 'https://leadnavigators.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'leadnavigators.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#163c6f', '#ff5f1f', '#ffd936', '#2a9d8f', '#8ecae6', '#a7a7a7'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
