import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { apollo } from '../../index';
import { CampaignResponse, AgenciesResponse } from './types';

export default {
  async getCampaigns(advertiserId: string) {
    try {
      const response: ApolloQueryResult<CampaignResponse> = await apollo().query({
        query: gql`
          query GetCampaigns($advertiserId: ID) {
            getCampaigns(id: $advertiserId) {
              PropertyId
              Campaign {
                id
                name
                CampaignType
                FriendlyName
              }
              StartDate
              EndDate
              DateRange
            }
          }
        `,
        variables: {
          advertiserId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getAgencies() {
    try {
      const response: ApolloQueryResult<AgenciesResponse> = await apollo().query({
        query: gql`
          query GetAgencies {
            getAgencies {
              List {
                name
                originId
              }
            }
          }
        `,
        variables: {},
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
};
