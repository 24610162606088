import CONSTANTS from './constants';

export default {
  setOrderlistParams({ commit }, payload): void {
    commit(CONSTANTS.SET_ORDERLIST_PARAMS, payload);
  },
  setSingleOrderParams({ commit }, payload): void {
    commit(CONSTANTS.SET_SINGLE_ORDER_PARAMS, payload);
  },
  setHidingOrderModules({ commit }, payload): void {
    commit(CONSTANTS.SET_HIDING_ORDER_MODULES, payload);
  },
};
