/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './healit.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'healit',
  companyId: 'Heal_IT',
  companyName: 'Heal IT',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://healit.myanalyticshub.com',
    staging: 'https://healit.staging.myanalyticshub.com',
    dev: 'https://healit.dev.myanalyticshub.com',
    local: 'https://healit.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'healit.png', emailLogo: '' },
  config: {
    chartColors: ['#4d88fe', '#8dd1ff', '#a379ff', '#ffd96c', '#bfbfbf', '#6e6d6b'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
