/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './qball.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig, getDMAGradient } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'qball',
  companyId: 'Q_Ball',
  companyName: 'Q Ball Digital',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://qballdigital.myanalyticshub.com',
    staging: 'https://qballdigital.staging.myanalyticshub.com',
    dev: 'https://qballdigital.dev.myanalyticshub.com',
    local: 'https://qballdigital.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'qball_white.png', large: true, emailLogo: 'qball_color.png' },
  config: {
    chartColors: ['#ba0c2f', '#ff9800', '#ead407', '#666566', '#3f51b5', '#b0b7e0'],
  },
  polygons: {
    light: {
      dmaZip: { fillOpacity: 0.4, color: '#999999', fillColor: '#BA0C2F', weight: 1 },
      zip: { fillOpacity: 0.6, color: '#999999', fillColor: '#BA0C2F', weight: 0.5 },
      dmaGradient: getDMAGradient('#BA0C2F'),
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
