import Vue from 'vue';
import { LayoutSection, SectionComponent, Customization } from '../../../types/layout';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  [CONSTANTS.SET_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'loading', loading);
  },
  [CONSTANTS.SET_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'error', error);
  },
  [CONSTANTS.SET_COMPONENT](state: StateType, componentId: SectionComponent): void {
    Vue.set(state, 'componentId', componentId);
  },
  [CONSTANTS.SET_SECTION](state: StateType, sectionId: LayoutSection): void {
    Vue.set(state, 'sectionId', sectionId);
  },
  [CONSTANTS.SET_CURR_CUSTOMIZATIONS](state: StateType, currCustomizations: Array<Customization>): void {
    Vue.set(state, 'currCustomizations', currCustomizations);
  },
  [CONSTANTS.ENABLE_SAVE](state: StateType, enableSave: boolean): void {
    Vue.set(state, 'enableSave', enableSave);
  },
  [CONSTANTS.EDIT_MODE](state: StateType, editMode: boolean): void {
    Vue.set(state, 'editMode', editMode);
  },
};
