/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './wellermedia.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'wellermedia',
  companyId: 'Weller_Media',
  companyName: 'Weller Media',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://wellermedia.myanalyticshub.com',
    staging: 'https://wellermedia.staging.myanalyticshub.com',
    dev: 'https://wellermedia.dev.myanalyticshub.com',
    local: 'https://wellermedia.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'wellermedia.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#f5a438', '#105d79', '#28b4d6', '#a2ddf8', '#b8b8b8', '#393938'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
