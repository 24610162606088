/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './stonegatestudios.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';

const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'stonegatestudios',
  companyId: 'Stonegate_Studios',
  companyName: 'Stonegate Studios',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'TagTeamBP',
  domains: {
    production: 'https://stonegatestudios.myanalyticshub.com',
    staging: 'https://stonegatestudios.staging.myanalyticshub.com',
    dev: 'https://stonegatestudios.dev.myanalyticshub.com',
    local: 'https://stonegatestudios.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'stonegatestudios.png', emailLogo: '' },
  config: {
    chartColors: ['#5396e3', '#7489aa', '#be9377', '#86090a', '#b9cee5', '#36e784'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
