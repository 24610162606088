import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  [CONSTANTS.SET_LINEAR_ADMIN_CLIENTS](state: StateType, clients): void {
    Vue.set(state, 'linearAdminClients', clients);
  },
  [CONSTANTS.SET_LINEAR_ADMIN_ERROR](state: StateType, error): void {
    Vue.set(state, 'linearAdminError', error);
  },
  [CONSTANTS.SET_SEARCH_RESULTS](state: StateType, results): void {
    Vue.set(state, 'searchResults', results);
  },
  [CONSTANTS.SET_LOADING](state: StateType, loading): void {
    Vue.set(state, 'loading', loading);
  },
  [CONSTANTS.SET_CONTACTS_LOADING](state: StateType, loading): void {
    Vue.set(state, 'contactLoading', loading);
  },
  [CONSTANTS.SET_ADVERTISER](state: StateType, advertiser): void {
    Vue.set(state, 'advertiser', advertiser);
  },
  [CONSTANTS.SET_LINEAR_ADMIN_ADVERTISER_INFO](state: StateType, advertiser): void {
    Vue.set(state, 'advertiserInfo', advertiser);
  },
  [CONSTANTS.SET_CONTACTS](state: StateType, advertiser): void {
    Vue.set(state, 'contacts', advertiser);
  },
  [CONSTANTS.SET_ACCOUNT_LINKING](state: StateType, linking): void {
    Vue.set(state, 'accountLinking', linking);
  },
  [CONSTANTS.SET_LINEAR_ADMIN_FACEBOOK_ERROR](state: StateType, error): void {
    Vue.set(state, 'facebookError', error);
  },
  [CONSTANTS.SET_FACEBOOK_LOADING](state: StateType, loading): void {
    Vue.set(state, 'facebookLoading', loading);
  },
  [CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR](state: StateType, error): void {
    Vue.set(state, 'googleAnalyticsError', error);
  },
  [CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_TOKEN_ERROR](state: StateType, error): void {
    Vue.set(state, 'googleAnalyticsTokenError', error);
  },
  [CONSTANTS.SET_GOOGLE_ANALYTIC_ACCOUNT](state: StateType, advertiser): void {
    Vue.set(state, 'googleAnalyticsAccount', advertiser);
  },
  [CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ACCOUNT_ERROR](state: StateType, error): void {
    Vue.set(state, 'googleAnalyticsAccountError', error);
  },
  [CONSTANTS.SET_GOOGLE_ANALYTICS_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'googleAnalyticsLoading', loading);
  },
  [CONSTANTS.SET_GOOGLE_ADS_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'googleAdsError', error);
  },
  [CONSTANTS.SET_GOOGLE_ADS_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'googleAdsLoading', loading);
  },
  [CONSTANTS.SET_GOOGLE_AD_MANAGER_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'googleAdManagerError', error);
  },
  [CONSTANTS.SET_GOOGLE_AD_MANAGER_SAVE_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'googleAdManagerSaveError', error);
  },
  [CONSTANTS.SET_GOOGLE_AD_MANAGER_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'googleAdManagerLoading', loading);
  },
  [CONSTANTS.SET_GOOGLE_AD_MANAGER_ACCOUNT_LINKING](state: StateType, linking: object): void {
    Vue.set(state, 'googleAdManagerAccountLinking', linking);
  },
  [CONSTANTS.SET_CAMPAIGN_MOVE](state: StateType, moving): void {
    Vue.set(state, 'campaignMove', moving);
  },
  [CONSTANTS.SET_UPDATE_CAMPAIGN_DATA](state: StateType, moving): void {
    Vue.set(state, 'updateCampaignData', moving);
  },
  [CONSTANTS.SET_CAMPAIGN_HIDE](state: StateType, hiding): void {
    Vue.set(state, 'campaignHide', hiding);
  },
  [CONSTANTS.SET_CAMPAIGN_UNHIDE](state: StateType, unhiding): void {
    Vue.set(state, 'campaignUnhide', unhiding);
  },
  [CONSTANTS.SET_ASSOCIATE_ORDER_WITH_CAMPAIGN](state: StateType, value): void {
    Vue.set(state, 'associateOrderWithCampaign', value);
  },
};
