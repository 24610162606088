/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './adxdigitalmarketing.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'adxdigitalmarketing',
  companyId: 'ADX_Digital_Marketing',
  companyName: 'ADX Digital',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://adxdigitalmarketing.myanalyticshub.com',
    staging: 'https://adxdigitalmarketing.staging.myanalyticshub.com',
    dev: 'https://adxdigitalmarketing.dev.myanalyticshub.com',
    local: 'https://adxdigitalmarketing.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'adxdigital.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#303e7a', '#ec3533', '#ffdd5c', '#951a84', '#058ad5', '#86c543'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
