import { StateType } from './types';
import { Campaign } from '../../../types/filters';

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectableCampaignIDs(state: StateType, getters: any, rootState: any): string[] {
    if (
      rootState.customer.campaigns &&
      rootState.customer.campaigns.campaignlist &&
      Array.isArray(rootState.customer.campaigns.campaignlist)
    ) {
      const selectedTypes = state.selectedCampaignTypes;
      return rootState.customer.campaigns.campaignlist
        .map((campaign: Campaign) => (selectedTypes.includes(campaign.CampaignType) ? campaign.id : undefined))
        .filter(x => !!x);
    }
    return [];
  },
};
