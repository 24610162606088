import {
  differenceInMonths,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';

const dateHelpers = {
  getTodayWithoutHours() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.normalizeDate(today);
  },
  normalizeDate(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * -60000);
  },
  datefix(date) {
    return date.replace(/\.[0-9]{2,3}/, '');
  },
  getTimeAgo(date, includeTime) {
    date = this.datefix(date);
    const now = new Date();
    const currentDateUTC = new Date(`${date} UTC`);
    const currentDate = String(currentDateUTC) === 'Invalid Date' ? new Date(date) : currentDateUTC;
    const minutesAgo = this.getDifferenceInMinutes(now, currentDate);
    const hoursAgo = this.getDifferenceInHours(now, currentDate);
    const daysAgo = this.getDifferenceInCalendarDays(now, currentDate);
    const monthsAgo = this.getDifferenceInMonths(now, currentDate);
    const clock = includeTime ? `, ${this.getClockTime(currentDate)}` : '';

    if (minutesAgo < 1) return 'Less than 1 min ago';
    if (minutesAgo <= 59) return `${minutesAgo} min${this.plural(minutesAgo)} ago`;
    if (minutesAgo > 59 && hoursAgo < 12) return `${hoursAgo} hour${this.plural(hoursAgo)} ago`;
    if (daysAgo === 1 || (hoursAgo > 12 && hoursAgo < 24)) return `Yesterday${clock}`;
    if (daysAgo <= 30 && daysAgo > 0 && !includeTime) return `${daysAgo} days ago`;
    if (monthsAgo <= 12 && monthsAgo > 1 && !includeTime) return `${monthsAgo} months ago`;

    return `${this.getMonthDate(currentDate)}${clock}`;
  },
  getRemainingTime(date, includeTime) {
    date = this.datefix(date);
    const now = new Date();
    const currentDate = new Date(`${date} UTC`);
    const minutesAgo = this.getDifferenceInMinutes(currentDate, now);
    const hoursAgo = this.getDifferenceInHours(currentDate, now);
    const daysAgo = this.getDifferenceInCalendarDays(currentDate, now);
    const monthsAgo = this.getDifferenceInMonths(currentDate, now);
    const clock = includeTime ? `, ${this.getClockTime(currentDate)}` : '';

    if (minutesAgo < 1) return 'Less than 1 min';
    if (minutesAgo <= 59) return `${minutesAgo} min${this.plural(minutesAgo)}`;
    if (minutesAgo > 59 && hoursAgo < 12) return `${hoursAgo} hour${this.plural(hoursAgo)}`;
    if (daysAgo <= 30 && daysAgo > 0 && !includeTime) return `${daysAgo} days`;
    if (monthsAgo <= 12 && monthsAgo > 1 && !includeTime) return `${monthsAgo} months`;

    return `${this.getMonthDate(currentDate)}${clock}`;
  },
  plural(value) {
    return value > 1 ? 's' : '';
  },
  getMonthDate(date) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  },
  getClockTime(date) {
    let hour = date.getHours();
    let minute = date.getMinutes();
    let ampm = 'AM';
    if (hour > 11) ampm = 'PM';
    if (hour > 12) hour -= 12;
    // handle zero hour
    if (hour === 0) hour = 12;
    if (minute < 10) minute = `0${minute}`;
    return `${hour}:${minute}${ampm}`;
  },
  getNumberOfDaysBetween(first, second) {
    return differenceInDays(first, second);
    // const millisecondsAsDays = 1000 * 60 * 60 * 24;
    // return Math.ceil((first - second) / millisecondsAsDays);
  },
  getDifferenceInCalendarDays(first, second) {
    return differenceInCalendarDays(first, second);
  },
  getDifferenceInHours(first, second) {
    return differenceInHours(first, second);
  },
  getDifferenceInMinutes(first, second) {
    return differenceInMinutes(first, second);
  },
  getDifferenceInMonths(first, second) {
    return differenceInMonths(first, second);
  },
  countDown(endDate: string) {
    let days;
    let hours;
    endDate = this.datefix(endDate);
    const endTime = new Date(endDate).getTime();
    if (Number.isNaN(endTime)) {
      return false;
    }
    const startDate = new Date();
    const startTime = startDate.getTime();

    let timeRemaining = (endTime - startTime) / 1000;

    if (timeRemaining >= 0) {
      days = timeRemaining / 86400;
      timeRemaining %= 86400;
      hours = timeRemaining / 3600;
      if (days > 0) return `${days}d`;
      return `${hours}h`;
    }
    return false;
  },
  formats: {
    dsstore: 'MM/DD/YYYY HH:mm:ss.SSS A',
    mmddyyyy: 'MM/DD/YYYY',
    timeStamp: 'HH:mm:ss.SSS',
  },
};

export default dateHelpers;
