/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './dcadcpr.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'dcadcpr',
  companyId: 'dcadcpr',
  companyName: 'DCA DCPR',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://dca-dcpr.myanalyticshub.com',
    staging: 'https://dca-dcpr.staging.myanalyticshub.com',
    dev: 'https://dca-dcpr.dev.myanalyticshub.com',
    local: 'https://dca-dcpr.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'dcapr.png' },
  config: {
    chartColors: ['#25aae1', '#f1592a', '#cdd61f', '#439a44', '#91949b', '#385ac1'],

    maps: {
      themes: ['dcadcpr'],
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
