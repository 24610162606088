import { Variables, ThemeConfig } from './variables';
import Color from 'color';
import { VuetifyThemeColors } from './variables';

function getVuetifyColors(colors: VuetifyThemeColors): VuetifyThemeColors {
  // remove any colors Vuetify doesn't know about
  return {
    primary: colors.primary,
    error: colors.error,
    success: colors.success,
    warning: colors.warning,
  };
}

function setVariableColors(variables: Variables): object {
  return {
    font: {
      family: variables['font-family'],
    },
    navDrawer: {
      padding: variables['nav-drawer-padding'],
    },
    light: {
      primary: variables['light-primary'],
      error: variables['light-error'],
      success: variables['light-success'],
      warning: variables['light-warning'],
      background: variables['light-background'],
      toolbarBackground: variables['light-toolbar-background'],
      toolbarText: variables['light-toolbar-text'],
      footerBackground: variables['light-footer-background'],
      footerTextColor: variables['light-footer-text-color'],
      listActiveBackground: variables['light-drawer-active-background'],
      componentBackground: variables['light-component-background'],
      progressBarGradient: variables['light-progress-bar-gradient'],
      progressBarBg: variables['light-progress-bar-bg'],
    },
  };
}

function cleanupConfig(theme: ThemeConfig, config: ThemeConfig): ThemeConfig {
  // overwrite arrays that were deep merged because deepmerge appends instead of overwrites
  if (config.polygons?.light?.dmaGradient) theme.polygons.light.dmaGradient = config.polygons.light.dmaGradient;

  if (config.config?.pieCharts?.colors) theme.config.pieCharts.colors = config.config.pieCharts.colors;
  if (config.config?.chartColors) theme.config.chartColors = config.config.chartColors;

  return theme;
}

function getDMAGradient(baseColor: string): string[] {
  if (!baseColor) {
    return ['#B1C5CE', '#03A9F4', '#01579B'];
  }

  // create a palette based on primary color
  const color: Color = Color(baseColor);
  const gradient: string[] = [
    // color.desaturate(0.7).lighten(0.9).hex(),
    color.desaturate(0.2).lighten(0.3).hex(),
    color.hex(),
    color.darken(0.3).hex(),
  ];
  return gradient;
}

export { setVariableColors, getDMAGradient, getVuetifyColors, cleanupConfig };
