// {{local}}:8080/api/getProductsForAdvertiser/88888888

import { AxiosResponse } from 'axios';
import CONSTANTS from '../constants';
import { logError } from '../../../index';
import requests from '../requests';

const getProductsForAdvertiser = async ({ commit }, advertiserId): Promise<object> => {
  commit(CONSTANTS.SET_ERROR, null); // clear any errors

  if (!advertiserId) {
    commit(CONSTANTS.SET_ERROR, 'Missing advertiser id');
    return;
  }

  try {
    commit(CONSTANTS.SET_ERROR, null);
    commit(CONSTANTS.SET_PRODUCTS_LOADING, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: AxiosResponse<any> = await requests.getProductsForAdvertiser(
      `/api/getProductsForAdvertiser/${advertiserId}`,
    );
    if (response.data?.error) {
      commit(CONSTANTS.SET_ERROR, response.data.error);
      return response.data;
    }
    if (!response?.data?.length) {
      commit(CONSTANTS.SET_ERROR, 'No currently active products were found');
      response.data.error = 'No currently active products were found';
      return response.data;
    }
    commit(CONSTANTS.SET_PRODUCTS, response.data);
    return response.data;
  } catch (exp) {
    commit(CONSTANTS.SET_ERROR, exp.message);
    throw logError(exp, 'store.customer.getProductsForAdvertiser');
  } finally {
    commit(CONSTANTS.SET_PRODUCTS_LOADING, false);
  }
};

const setValidatedProductSections = ({ commit }, sections): void => {
  commit(CONSTANTS.SET_VALIDATED_PRODUCT_SECTIONS, sections);
};

const resetProductSections = ({ commit }): void => {
  commit(CONSTANTS.SET_VALIDATED_PRODUCT_SECTIONS, null);
  commit(CONSTANTS.SET_PRODUCTS, null);
};

export default {
  getProductsForAdvertiser,
  setValidatedProductSections,
  resetProductSections,
};
