/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './onemediaconnect.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'onemediaconnect',
  companyId: 'onemediaconnect',
  companyName: 'One Media Connect',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'Sinclair',
  domains: {
    production: 'https://onemediaconnect.myanalyticshub.com',
    staging: 'https://onemediaconnect.staging.myanalyticshub.com',
    dev: 'https://onemediaconnect.dev.myanalyticshub.com',
    local: 'https://onemediaconnect.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'onemediaconnect.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#3e69dc', '#f9555b', '#f9c74f', '#29b6f6', '#1eae84', '#8a8a8a'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
