const regexes = {
  decimal: new RegExp(/^(\d*\.)?\d+$/, 'ig'), // found here: https://www.regextester.com/95625
  email: new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'i',
  ),
  zip: new RegExp(/(^\s*$|^\d{5}([-]\d{4})?$)/, 'i'),
  noSpaces: new RegExp(/\s/g, 'i'),
  digits: new RegExp(/\d+/, 'i'),
  digits2: new RegExp(/-?\d+/, 'i'), // Handles negative numbers
  phone: new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, 'i'),
  matchPhoneFromDigits: new RegExp(/^(\d{3})(\d{3})(\d{4})$/),
  name: new RegExp(/^[a-zA-Z '&.-]*$/, 'i'),
  nameWithDigits: new RegExp(/^[a-zA-Z0-9 '.^\-&/()#.,]*$/, 'i'),
  lettersNumbers: new RegExp(/^[a-zA-Z0-9_\-.]*$/, 'i'),
  lettersNumbersSeparators: new RegExp(/^[a-zA-Z0-9_\-.:,]*$/, 'i'),
  communities: new RegExp(/^[a-zA-Z0-9_^/.&'\s,\-|]*$/, 'i'),
  date: new RegExp(/^[a-zA-Z0-9 _.\-:/]*$/, 'i'),
  freeText: new RegExp(/^[a-zA-Z0-9 _&,.\-:;&$*@{}!?=#/|<>+']*$/, 'i'),
  lettersNoSpaces: new RegExp(/^[a-z]*$/g, 'i'),
  hash: new RegExp(/^[A-Z0-9]*$/, 'i'),
  stripDomain: new RegExp(/(https?:\/\/)|(www.)|(\/$)/, 'i'), // handles test.com, http://test.com, https://test.com and http://test.com/
  baseDomain: new RegExp(/([a-z0-9]+.com)/, 'i'), // matches test.com
  fullDomain: new RegExp(/^$|^(https?:\/\/)?(www.)?[a-zA-Z0-9-]+\.[a-z/]+(\/[a-zA-Z0-9#]+\/?)*$/, 'i'), // hanldes www.test.com
  quotes: new RegExp(/’|"|”|“|‘/, 'ig'),
  notes: new RegExp(/[^a-zA-Z0-9\d\-.:!^/*_:'\\\s]/g),
  SERVER_NAME_IN_ERROR_RESPONSE: new RegExp(/[a-z:0-99]+,\s/, 'ig'),
};

export default regexes;
