/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './compulse.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig, getDMAGradient } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'compulse',
  companyId: '7462',
  companyName: 'Compulse',
  rootDomain: 'sbganalytics.com',
  parentAgency: 'Sinclair',
  domains: {
    production: 'https://compulse.sbganalytics.com',
    staging: 'https://staging.compulse.sbganalytics.com',
    dev: 'https://dev.compulse.sbganalytics.com',
    local: 'https://compulse.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { invert: false, file: 'compulse_analytics.png' },
  favicon: 'compulse',
  whiteToolbar: true,
  config: {
    chartColors: ['#ff5252', '#2979ff', '#febc2c', '#17b361', '#ff939e', '#ab47bc'],
    pieCharts: {
      stacked: false,
      firstItem: 'legend',
      inlineIcon: true,
    },

    maps: {
      themes: ['compulse'],
    },
  },
  polygons: {
    light: {
      dmaZip: { fillOpacity: 0.4, color: '#999999FF', fillColor: '#FF6453', weight: 1 },
      zip: { fillOpacity: 0.6, color: '#999999FF', fillColor: '#FF6453', weight: 0.5 },
      dmaGradient: getDMAGradient('#FF6453'),
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
