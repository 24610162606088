/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './whpmfox23.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'whpmfox23',
  companyId: 'WHPM_Fox_23',
  companyName: 'WHPM Fox 23',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'NuLogic',
  domains: {
    production: 'https://whpmfox23.myanalyticshub.com',
    staging: 'https://whpmfox23.staging.myanalyticshub.com',
    dev: 'https://whpmfox23.dev.myanalyticshub.com',
    local: 'https://whpmfox23.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'whpmfox23.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#ffb703', '#064768', '#219ebc', '#a2d4eb', '#c0c0c0', '#272727'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
