/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './johnsonmediagroup.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'johnsonmediagroup',
  companyId: 'Johnson_Media_Group',
  companyName: 'Johnson Media Group',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://johnsonmediagroup.myanalyticshub.com',
    staging: 'https://johnsonmediagroup.staging.myanalyticshub.com',
    dev: 'https://johnsonmediagroup.dev.myanalyticshub.com',
    local: 'https://johnsonmediagroup.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'johnsonmediagroup.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#007bff', '#ffa200', '#71be68', '#f4ea40', '#999999', '#003b79'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
