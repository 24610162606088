/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './graham_media.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'graham_media',
  companyId: 'Graham_Media',
  companyName: 'Graham Media',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://grahamnmedia.myanalyticshub.com',
    staging: 'https://grahamnmedia.staging.myanalyticshub.com',
    dev: 'https://grahamnmedia.dev.myanalyticshub.com',
    local: 'https://grahamnmedia.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'grahamnmedia.png', emailLogo: 'grahamnmedia_color.png' },
  config: {
    chartColors: ['#f50505', '#3a9bdf', '#ffe307', '#455560', '#77d255', '#0e38a7'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
