/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './dashmedia.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'dashmedia',
  companyId: 'Dash_Media',
  companyName: 'Dash Media',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'LPP',
  domains: {
    production: 'https://dashmedia.myanalyticshub.com',
    staging: 'https://dashmedia.staging.myanalyticshub.com',
    dev: 'https://dashmedia.dev.myanalyticshub.com',
    local: 'https://dashmedia.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'dashmedia.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#d04a41', '#3f51b5', '#fb8c00', '#2b94fb', '#0c7c59', '#ffc107'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
