/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './cskadvertising.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'cskadvertising',
  companyId: 'CSK_Advertising',
  companyName: 'CSK Advertising',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://cskadvertising.myanalyticshub.com',
    staging: 'https://cskadvertising.staging.myanalyticshub.com',
    dev: 'https://cskadvertising.dev.myanalyticshub.com',
    local: 'https://cskadvertising.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'cskadvertising.png', emailLogo: '' },
  whiteToolbar: true,
  config: {
    chartColors: ['#23619c', '#f77949', '#ffdb07', '#29b6f6', '#979797', '#4e4e4e'],
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
