/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './silva.scss';
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'silva',
  companyId: 'silva',
  companyName: 'Silva Marketing',
  rootDomain: 'myanalyticshub.com',
  parentAgency: 'none',
  domains: {
    production: 'https://silvamarketingcorp.myanalyticshub.com',
    staging: 'https://silvamarketingcorp.staging.myanalyticshub.com',
    dev: 'https://silvamarketingcorp.dev.myanalyticshub.com',
    local: 'https://silvamarketingcorp.localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { invert: false, file: 'silvamarketingcorp.png', emailLogo: 'silvamarketingcorp_color.png' },
  config: {
    chartColors: ['#61c08d', '#6c758c', '#fb8c00', '#ec407a', '#29b6f6', '#ffc107'],
    pieCharts: { firstItem: 'legend' },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
