export default {
  SET_PRIMARY_METRICS_ERROR: 'SET_PRIMARY_ERROR',
  SET_PRIMARY_IMPRESSIONS_BY_CLIENTS_ERROR: 'SET_PRIMARY_IMPRESSIONS_BY_CLIENTS_ERROR',
  SET_PRIMARY_IMPRESSIONS_BY_TACTICS_ERROR: 'SET_PRIMARY_IMPRESSIONS_BY_TACTICS_ERROR',
  SET_FILTER_ERROR: 'SET_FILTER_ERROR',
  SET_ENDING_ORDERS_ERROR: 'SET_ENDING_ORDERS_ERROR',
  SET_PACING_ERROR: 'SET_PACING_ERROR',

  SET_ACTIVE_ORDERS_CACHE: 'SET_ACTIVE_ORDERS_CACHE',
  SET_ACTIVE_ORDERS_PROMISE: 'SET_ACTIVE_ORDERS_PROMISE',
  SET_IMPRESSIONS_CACHE: 'SET_IMPRESSIONS_CACHE',
  SET_IMPRESSIONS_PROMISE: 'SET_IMPRESSIONS_PROMISE',
  SET_ACTIVE_CLIENTS_CACHE: 'SET_ACTIVE_CLIENTS_CACHE',
  SET_ACTIVE_CLIENTS_PROMISE: 'SET_ACTIVE_CLIENTS_PROMISE',
  SET_ACTIVE_CAMPAIGNS_CACHE: 'SET_ACTIVE_CAMPAIGNS_CACHE',
  SET_ACTIVE_CAMPAIGNS_PROMISE: 'SET_ACTIVE_CAMPAIGNS_PROMISE',
};

export const ERROR = 501;

export const errorManyAdverts = `Due to the volume of data that you have access to, please select specific Advertisers or a specific User in the "Filter By" dropdown.`;
