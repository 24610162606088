import axios from 'axios';
import CONSTANTS from './constants';
import { AccountType, ConnectedAd, GoogleUser } from './types';
import { logError } from '../../index';

/**
  * Get all available ads grouped by Google users and customers.
*/
const getAvailableAds = async ({ commit }, advertiserId: string): Promise<GoogleUser[]> => {
  try {
    commit(CONSTANTS.SET_GOOGLE_VIDEO_ERROR, null);
    const { data } = await axios.get(`/api/connectors/google/campaigns/available/${advertiserId}`);
    return data;
  } catch (error) {
    commit(CONSTANTS.SET_GOOGLE_VIDEO_ERROR, error?.message);
    throw logError(error, 'store.googleAds.getAvailableAds');
  }
};

/**
 * Get all ads connected to the advertiser.
 *
 * @param advertiserId  Advertiser ID.
 * @param type          Connector type.
 */
const getConnectedAds = async ({ commit }, payload): Promise<number[]> => {
  const { advertiserId, type } = payload;

  try {
    commit(CONSTANTS.SET_GOOGLE_VIDEO_ERROR, null);
    const { data } = await axios.get(`/api/connectors/google/account/${type}/${advertiserId}`);
    return data;
  } catch (error) {
    commit(CONSTANTS.SET_GOOGLE_VIDEO_ERROR, error?.message);
    throw logError(error, 'store.googleAds.getConnectedAds');
  }
};

/**
* Update ads list connected to the advertiser.
*
* @param advertiserId  Advertiser ID.
* @param type          Connector type.
* @param connectedAds  List of adds connected to the Advertiser.
*/
const updateConnectedAds = async ({ commit }, payload): Promise<number[]> => {
  const { advertiserId, type, connectedAds }: { advertiserId: string, type: AccountType, connectedAds: ConnectedAd[] } = payload;
  try {
    commit(CONSTANTS.SET_GOOGLE_VIDEO_ERROR, null);
    const { data } = await axios.post(`/api/connectors/google/account/${type}/${advertiserId}`, {
      accounts: connectedAds,
    });
    return data;
  } catch (error) {
    commit(CONSTANTS.SET_GOOGLE_VIDEO_ERROR, error?.response?.data || error?.message);
    throw logError(error, 'store.googleAds.updateConnectedAds');
  }
};

export default {
  getAvailableAds,
  getConnectedAds,
  updateConnectedAds,
};
